import React, { Component } from 'react';
import { Switch, Route, useRouteMatch,BrowserRouter } from 'react-router-dom';
import List from './list';
import Cartas from './cartas';
import Create from './create';
import Relatorio from './relatorio';

export default class Location extends Component {
  render() {
    return (
    
        <Users />
 
    );
  }
}

function Users() {
  let match = useRouteMatch();

  return (
    <div id="layoutSidenav_content">
      <main>
 
        <Switch>
          <Route exact path={`${match.path}/list`} component={List} />
          <Route path={`${match.path}/cartas/:id`} component={Cartas} />
          <Route path={`${match.path}/relatorio`} component={Relatorio} />
          <Route path={`${match.path}/create`} component={Create} />
        </Switch>
   
      </main>
    </div>
  );
}
