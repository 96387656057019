import React, { Component } from 'react';
import { GetAreasDetails } from '../../../../services';
import Moment from 'moment';
import { withRouter } from "react-router";
import PropTypes from "prop-types";


import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormFeedback,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CInputGroup,
    CInputGroupText,
    CRow,
    CAlert,
    CSpinner
  } from '@coreui/react'

  import { getStyle, hexToRgba } from '@coreui/utils'
  import CIcon from '@coreui/icons-react'
  import {
    cilPencil,cilTrash,
  } from '@coreui/icons'


export default class Edit extends Component {

  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

    constructor(props) {
        super(props);
        let userdata = this.props.location.state;
        
        if (typeof(userdata) !== 'undefined') {
        this.state = {          
          id: userdata.id ,descricao:userdata.descricao,isLoaded:false,tipo:userdata.tipo
        }        
       } else {
        this.state = {id: 0 ,descricao: '',tipo:'',isLoaded:false}
       }

       
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleOpen() {
        this.setState({ open: !this.state.open, loading: true })
    }

    handleClose() {
        this.setState({ open: !this.state.open })
    }

   
    handleSubmit = async event => { 
        event.preventDefault();
        this.setState({ isLoaded: true })
        let data = { id: this.state.id, descricao: this.state.descricao,tipo:this.tipo}
        let list = await GetAreasDetails.getAreasUpdate(data);
        if(list){                      
          this.props.history.push({ pathname: `/admin/areas/list`})      
        }
        this.setState({ isLoaded: false })
    }

    
    render() {
      let isLoaded = this.state.isLoaded
        return (          
                  <CRow>
                  <CCol xs={12}>
                  <CCard className="mb-4">
                    <CCardHeader>
                          <strong>Alterar Áreas</strong> 
                    </CCardHeader>
                  <CCardBody>
                        
                  <CForm
                    className="row g-3 needs-validation"
                    Validate
                    validated={this.validated}
                    onSubmit={this.handleSubmit}
                  >
                    <CCol md={4}>
                      <CFormInput
                        type="text"        
                        feedbackValid="Looks good!"
                        id="validationCustom01"
                        label="Descrição"
                        required        
                        value={this.state.descricao}   
                        name="descricao"      
                        onChange={(e) => this.handleChange(e)} 
                      />
                    
                    <CCol md={2}>
                    <CFormSelect label="Tipo" size="sm" className="mb-3" defaultValue={this.tipo} aria-label="Small select example" name="tipo" onChange={(e) => this.handleChange(e)}>      
                      <option value="Distrito">Distrito</option>
                      <option value="Área">Área</option>      
                      <option value="Setor">Setor</option>      
                    </CFormSelect>
                  </CCol>

                    </CCol>   
                    <div className="d-grid gap-2 d-md-flex">
                      <CButton color="success" type="submit" variant="outline">
                        Confirmar
                      </CButton>
                       
                      <CButton color="success" variant="outline" onClick={(e) => this.props.history.push({ pathname: `/admin/areas/list`})}>
                        Cancelar
                      </CButton>
                      </div>
                  </CForm>

                  </CCardBody>
                  </CCard> 
                  </CCol>

                  {
                    isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
                  }
                  </CRow>
             
        )
    }
}