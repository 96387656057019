import React, { Component } from 'react';
import axios from 'axios';
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
  CCardGroup,
  CCardTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormSelect,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';
import {
 cilPrint
} from '@coreui/icons';

import { GetCartaDetails } from '../../../../services';
import PropTypes from "prop-types";

export default class Create extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

  constructor(props) {
    super(props);
    let userdata = this.props.location.state;
    this.state = {            
      getList: [],
      isLoaded: false,
      id: userdata.id,
      nome: userdata.nome,
      imprimiu: '',
    };
  }
  
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });      
  }
  
  async componentDidMount() {      
    this.getCartas(this.state.id);
  }

  async getCartas(id) {      
    let list = await GetCartaDetails.getCartaListID(id);
    if (list) {   
      this.setState({ getList: list.data });
    }
  }
  
  handleSubmit = async (item,status) => {           
    
    this.setState({ isLoaded: true })
                                                       
        let data = {id:item.id,imprimiu: status}
        // make API call
        let user = await GetCartaDetails.getCartaUpdate(data);
        if (user) {
            this.setState({ isLoaded: false })           
        } else {
            alert("Erro inesperado. Contate o suporte", 'Erro');
        }

    
    this.setState({ isLoaded: false })          
  }

  generateDocument = async (remetente, nome, texto, url_file,relacao) => {
    try {
        const templateFile = await axios.get('/files/Vida.docx', { responseType: 'blob' });
        const fileBlob = templateFile.data;
        const formData = new FormData();
        formData.append('template', fileBlob, 'Vida.docx');
        formData.append('remetente', remetente);
        formData.append('relacao', relacao);
        formData.append('destinatario', nome);
        formData.append('texto', texto);
        formData.append('url', url_file);
        
        const response = await fetch('https://13363.masterdaweb.net:9003/generate-doc', {
            method: 'POST',
            body: formData,
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const blob = await response.blob();

        
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Vida_${remetente}_${nome}.pdf`); // File name for the download
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error generating document:', error);
    }
  };

  // carta = (row,remetente, texto, nome, url,relacao) => {   
  //   this.props.history.push({ pathname: `/admin/vida/relatorio/${row}`, state: { row, remetente:remetente,nome:nome, texto:texto, url_file:url,relacao:relacao }});
 
  // }
   
  render() {      
    let { getList } = this.state;
    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-top">
        <CContainer style={{ marginTop: '1%' }}>
          <CRow className="justify-content-center">
            <CCol md={8} lg={12} xl={8}>
              <CCardGroup>          
                <CCard className="p-4">
                  <CCardTitle color="success" className="text-center"><h3>Cartas para {this.state.nome}</h3></CCardTitle>
                  <CCardBody className="p-4">
                    <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{ fontFamily: "Arial" }}>
                      <CTableHead color="dark">
                        <CTableRow color="dark">
                          <CTableHeaderCell className="text-center">Imprimir Carta</CTableHeaderCell>                                   
                          <CTableHeaderCell>Remetente</CTableHeaderCell> 
                          <CTableHeaderCell className="text-center">Impressa</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {getList.map((item, index) => (
                          <CTableRow key={index} color="success" style={{ color: "green" }}>
                            <CTableDataCell className="text-center" onClick={() => this.generateDocument(item.remetente, this.state.nome,item.texto,  item.url,item.relacao)}>
                              <CIcon icon={cilPrint} title='Imprimir Carta'/>
                            </CTableDataCell>
                            <CTableDataCell>{item.remetente}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CFormSelect 
                                name={`imprimiu-${item.id}`} 
                                value={item.imprimiu} 
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  this.setState((prevState) => ({
                                    getList: prevState.getList.map((el) => el.id === item.id ? { ...el, imprimiu: newValue } : el)
                                  }), () => this.handleSubmit(item,newValue));                              
                                }}
                                style={{ width: '80px', margin: '0 auto' }}  // Adjust width and center align
                              >
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                              </CFormSelect>
                            </CTableDataCell>
                          </CTableRow>
                        ))}                    
                      </CTableBody>               
                    </CTable>
                  </CCardBody>              
                </CCard>
              </CCardGroup>             
            </CCol>          
          </CRow>
        </CContainer>
      </div>
    );
  }
}



