import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const AddAgendaList = async (data,config) => {
    try {
        let result = await api.post(Apis.AddAgendaList,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getAllAgendaList = async () => {
    try {
        let result = await api.get(Apis.getAllAgendaList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getVisita_Setor = async () => {
    try {
        let result = await api.get(Apis.getVisita_Setor);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getVisita_Area = async () => {
    try {
        let result = await api.get(Apis.getVisita_Area);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



const getVisita_Distrito = async () => {
    try {
        let result = await api.get(Apis.getVisita_Distrito);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



const getDeleteAgenda = async (id) => {
    try {      
        let result = await api.delete(Apis.getAgendaDelete,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAgendaUpdate = async (data) => {
    try {
        let result = await api.post(Apis.getAgendaUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    AddAgendaList,
    getAllAgendaList,
    getDeleteAgenda,
    getVisita_Setor,
    getVisita_Area,
    getVisita_Distrito,
    getAgendaUpdate,    
};