import React,{ Component  }  from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CCardGroup,
  CSpinner ,
  CCardImage ,
  CCardTitle
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser,cilAt,cilScreenSmartphone} from '@coreui/icons'
import AutoSelect from "../../common/autoselect";

import { GetUserLogin , GetCargoDetails } from '../../services';
import swal from 'sweetalert';

import { NotificationManager } from 'react-notifications';
import axios from 'axios';

import avatar8 from '../../../assets/images/avatars/User.png'

const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
      data.map((item, key) => {
          arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
          return null;
      });
  }
  return arrayItem;
};


export default class Create extends Component {

  constructor(props) {
      super(props);
      this.state = {            
        getList:[],isLoaded: false, id: null, email: null,phone:null, firstName: null,id_cargo:0,password: null, 
        confirmPassword: null, status: 0,verify: 0, role: null,isLoaded:false,url_file:''
          
      }
  }

  async getCargo() {
    let list = await GetCargoDetails.getCargoList();
    this.setState({ getList: list.data })
  }

  handleSelectChange = (name, selected) => {
    if (name === "id_cargo") {
        this.setState({
            list: {
                ...this.state.list,
                [name]: selected.value,
            },
            selectLocation: selected
            
        });
        this.setState({ changed: true,id_cargo:selected.value });
    }

 };

  handleChange(e) {
      this.setState({ [e.target.name]: e.target.value })      
  }
  
  async componentDidMount() {
    this.getCargo();
    
  }

  handleChangeimg = (e) => { 
    e.preventDefault()
    const url = 'https://ibabackend-prjalvo.vercel.app/api/upload';
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);   
 
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {   
      this.setState({ url_file: response.data.message })
    }) 
    .catch((error) => {
      alert("Error uploading file:", error);
      // Lógica adicional em caso de erro de upload
    });

 }



  handleSubmit = async event => {
      event.preventDefault();     
      swal({
          title: "Deseja registrar-se?",         
          icon: "info",
          buttons: true,
          //confirmBtnBsStyle:"success",
          dangerMode: false,          
      })
          .then(async (success) => {
              if (success) { 
                  this.setState({ isLoaded: true })                  
                  
                  const { password, confirmPassword } = this.state;
                  // perform all neccassary validations
                  if (password !== confirmPassword) {
                      alert("Passwords don't match");
                  } else {
                      let {  firstName, email,phone,id_cargo, status,verify, role,password,url_file } = this.state;                      
                      let data = { firstName: firstName,  email: email, phone: phone,id_cargo: id_cargo, status: status, verify:verify,role:role,password:password,url_file:url_file}
                      // make API call
                      let user = await GetUserLogin.getUserRegister(data);
                      if (user) {
                          this.setState({ firstName:"", email:"",phone:"",id_cargo:0,status:0,verify:0, role:0,password:"" })

                             swal({
                                title: "Registro efetuado com sucesso?",         
                                icon: "info",
                                //buttons: true,
                                confirmBtnBsStyle:"success",
                                dangerMode: false,          
                            })
                            
                            window.location.reload();                                                                             
                      } else {
                          NotificationManager.error("Check Input field!", 'Input');
                      }
          
                  }


                  this.setState({ isLoaded: false })
              }
          });
  }



  
  render() {

      const {  firstName, email,phone, id_cargo,password,confirmPassword, status,verify, role,selectLocation,url_file } = this.state ;
      let isLoaded = this.state.isLoaded
      let self = this.state.getList
      let getList = this.state.getList
   return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={12} lg={12} xl={10}>
          <CCardGroup>
          <CCard className="text-white bg-success py-4">
                <CCardBody className="text-center">
                  <div>
                    <h2>Aplicativo de Gestão Rede Verde  IBA</h2>
                    <p></p>                                       
                     <h2>Rede Multiplicando Amor</h2>                    
                  </div>             
                  <div>
                  <p>
                    <h5>Crie Sua Conta para acessar o Aplicativo de Gestão da Rede Verde..</h5>
                    </p>   
                    <p>
                    <h5>Depois agurade a validação da administração..</h5>
                    </p>        

                  </div>
                </CCardBody>
              </CCard>
            <CCard className="p-4">
              <CCardBody className="p-4">
                <CForm
                  className="row g-3 needs-validation"
                  Validate
                  validated={this.validated}
                  onSubmit={this.handleSubmit}                
                >
                  <h1>Registre-se</h1>                                            
                  
                  <CCol md={6 } className="d-flex align-items-center justify-content-center">
                  <CCard> 
                  <label htmlFor="imageUpload">
                  <CCardImage src={url_file ? url_file : avatar8} orientation="top" width="75%" />
                  </label>
                    <input
                      id="imageUpload"
                      type="file"
                      multiple
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={this.handleChangeimg}
                    />
                  
                  <CCardBody>
                    <CCardTitle>Carreque sua foto</CCardTitle>
                  </CCardBody> 
                  </CCard> 
                  </CCol>
                  
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput name="firstName" placeholder="Nome Usuário" autoComplete="username" required onChange={(e) => this.handleChange(e)}/>
                  </CInputGroup>


                  <CInputGroup className="mb-3">
                    <CInputGroupText><CIcon icon={cilAt} /></CInputGroupText>
                    <CFormInput name="email"  placeholder="Email" autoComplete="email"  required onChange={(e) => this.handleChange(e)}/>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText><CIcon icon={cilScreenSmartphone} /></CInputGroupText>                
                    <CFormInput name="phone" placeholder="Telefone" autoComplete="phone" required onChange={(e) => this.handleChange(e)} />                    
                    </CInputGroup>  
                    <CInputGroup className="mb-3">               
                   <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>             
                    <CCol xs={10}  >
                  <AutoSelect 
                      className="basic-single"
                      value={selectLocation}
                      onChange={this.handleSelectChange}
                      isSearchable={true}        
                      name="id_cargo"
                      label="Cargo"
                      options={Arrays(getList, "descricao", "id")} 
                    />   
                  </CCol>
                  </CInputGroup>  
                  
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>                
                    <CFormInput
                      type="password"
                      name="password"
                      placeholder="Senha"
                      autoComplete="new-password"
                      required
                      onChange={(e) => this.handleChange(e)}
                    />                    
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      name="confirmPassword" 
                      placeholder="Repita a Senha"
                      autoComplete="new-password"
                      required
                      onChange={(e) => this.handleChange(e)}
                    />
                  </CInputGroup>
                    
                  <div className="d-grid">
                    <CButton color="success" type="submit">Criar Conta</CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
            </CCardGroup>  
          </CCol>
           {
             isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
           }
        </CRow>
      </CContainer>
    </div>
)
}

}


