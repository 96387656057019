import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';
import { setCookie,getCookie, eraseCookie } from '../../../function';


const getBatismoRegister = async (data) => {
    try {
        let result = await api.post(Apis.GetBatismoRegister,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllBatismoList = async () => {
    try {
        let result = await api.get(Apis.GetAllBatismoList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllBatismoListbyID = async (data) => {
    try {
        let result = await api.post(Apis.GetAllBatismoListById,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getBatismoUpdate = async (data) => {
    try {
        let result = await api.post(Apis.GetBatismoUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getDeleteBatismoList = async (id) => {
    try {
        let result = await api.post(Apis.GetDeleteBatismoList,{
            id: id
        });
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    getAllBatismoList,
    getAllBatismoListbyID,
    getBatismoRegister,
    getBatismoUpdate,
    getDeleteBatismoList    
};