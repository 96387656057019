import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';
import { setCookie,getCookie, eraseCookie } from '../../../function';


const getEncontristaRegister = async (data) => {
    try {
        let result = await api.post(Apis.GetEncontristaRegister,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllEncontristaCount = async () => {
    try {
        let result = await api.get(Apis.GetAllEncontristaCount);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllCartasSum = async () => {
    try {
        let result = await api.get(Apis.GetCartasSum);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllEncontristaList = async () => {
    try {
        let result = await api.get(Apis.GetAllEncontristaList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllEncontristaListbyID = async (data) => {
    try {
        let result = await api.post(Apis.GetAllEncontristaListById,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getEncontristaUpdate = async (data) => {
    try {
        let result = await api.post(Apis.GetEncontristaUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getDeleteEncontristaList = async (id) => {
    try {
        let result = await api.post(Apis.GetDeleteEncontristaList,{
            id: id
        });
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    getAllEncontristaList,
    getAllEncontristaListbyID,
    getEncontristaRegister,
    getEncontristaUpdate,
    getDeleteEncontristaList,
    getAllEncontristaCount,
    getAllCartasSum    
};