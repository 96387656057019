import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const AddGrupoList = async (data,config) => {
    try {
        let result = await api.post(Apis.AddGrupoList,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getAllGrupoList = async () => {
    try {
        let result = await api.get(Apis.getAllGrupoList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllLider = async () => {
    try {
        let result = await api.get(Apis.getAllLider);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getUpdateGrupo = async (data,config) => {
    try {
        let result = await api.post(Apis.getUpdateGrupo,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getDeleteGrupo = async (id) => {
    try {
        let result = await api.delete(Apis.GetDeleteGrupo,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getGrupoById = async (id) => {
    try {
        let result = await api.get(Apis.GetGrupoById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    AddGrupoList,
    getAllGrupoList,
    getAllLider,
    getUpdateGrupo,
    getDeleteGrupo,  
    getGrupoById,
    
};