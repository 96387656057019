import React, { Component } from 'react';
import axios from 'axios';
import { GetEncontristaDetails, GetCartaDetails } from '../../../../services';
import swal from 'sweetalert';
import Moment from 'moment';
import PropTypes from "prop-types";
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CSpinner,
    CFormInput
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilPencil, cilTrash, cilPrint, cilUser } from '@coreui/icons';

let total_encontrista = 0;

export default class List extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            getList: [],
            filteredList: [],
            searchQuery: ''
        };
    }

    async componentDidMount() {
        this.getEncontrista();
    }

    async getCartas(id) {      
        let list = await GetCartaDetails.getCartaListID(id);
        if (list) {   
            this.setState({ getList: list.data });
        }
    }

    async getEncontrista() {      
        let list = await GetEncontristaDetails.getAllEncontristaCount();
        if (list) {
            total_encontrista = list.data.length;
            this.setState({ getList: list.data, filteredList: list.data });
        }
    }

    handleDeleteById(id, nome) {
        swal({
            title: `Excluir Encontrista ${nome} ?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (success) => {
            if (success) {
                this.setState({ isLoaded: true });
                let value = await GetEncontristaDetails.getDeleteEncontristaList(id);
                if (value) {
                    this.getEncontrista();
                }
                this.setState({ isLoaded: false });
            }
        });
    }

    generateDocument = async (remetente, nome, texto, url_file,relacao) => {
        try {
            const templateFile = await axios.get('/files/Vida.docx', { responseType: 'blob' });
            const fileBlob = templateFile.data;
            const formData = new FormData();
            formData.append('template', fileBlob, 'Vida.docx');
            formData.append('remetente', remetente);
            formData.append('relacao', relacao);
            formData.append('destinatario', nome);
            formData.append('texto', texto);
            formData.append('url', url_file);
            
            const response = await fetch('https://13363.masterdaweb.net:9003/generate-doc', {
                method: 'POST',
                body: formData,
              });
        
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
        
              const blob = await response.blob();

            // const response = await axios.post('http://185.228.72.82:9002/generate-doc', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     },
            //     responseType: 'blob'
            // });

            // if (response.status !== 200) {
            //     throw new Error('Network response was not ok');
            // }
            // //console.log(response)    
            // // Download PDF file
            // //const blob = new Blob([response.data]);
            // const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Vida_${remetente}_${nome}.pdf`); // File name for the download
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
    
        } catch (error) {
            console.error('Error generating document:', error);
        }
    };

    handleTodas(id, nome) {
        swal({
            title: `Deseja realmente imprimir todas as cartas de ${nome} ?`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
        })
        .then(async (success) => {
            if (success) {
                this.setState({ isLoaded: true });
                let list = await GetCartaDetails.getCartaListID(id);                
                if (list) {   
                    let filteredList = list.data.filter(item => item.imprimiu == 'Não');
                    for (const item of filteredList) {
                        const { remetente, texto, url,relacao } = item; // Assuming item has these properties
                        await this.generateDocument(remetente, nome, texto, url,relacao);
                    }
                }

                 let data = { id: id, imprimiu: 'Sim' };     
                 let user = await GetCartaDetails.getCartaUpdateAll(data);
                 if (user) {
                     this.setState({ isLoaded: false });
                 } else {
                     alert("Erro inesperado. Contate o suporte", 'Erro');
                 }
                 this.setState({ isLoaded: false });
            }
        });
    }

    handleEditRow(row, id, nome) {
        this.props.history.push({ pathname: `/admin/vida/cartas/${row}`, state: row, nome: nome, id: id });
    }

    handleSearch = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        this.setState({ searchQuery }, () => {
            this.filterList();
        });
    }

    filterList() {
        const { getList, searchQuery } = this.state;
        const filteredList = getList.filter(item =>
            item.nome.toLowerCase().includes(searchQuery)
        );
        this.setState({ filteredList });
    }

    render() {
        const { filteredList, searchQuery, isLoaded } = this.state;

        return (
            <CRow>
                <CCard className="mb-4">
                    <CCardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            Visualização dos Encontristas - Total: {total_encontrista}
                        </div>
                        <div style={{ alignItems: "center" }}>
                            <CFormInput
                                type="text"
                                placeholder="Buscar Encontrista"
                                value={searchQuery}
                                onChange={this.handleSearch}
                                style={{ width: '200px' }}
                            />
                        </div>
                    </CCardHeader>
                    <div style={{ cursor: isLoaded ? 'wait' : 'default' }}>
                        <CCardBody>
                            <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{ fontFamily: "Arial" }}>
                                <CTableHead color="dark">
                                    <CTableRow color="dark">
                                        <CTableHeaderCell className="text-center">Carta</CTableHeaderCell>
                                        <CTableHeaderCell className="text-center">Individual</CTableHeaderCell>
                                        <CTableHeaderCell className="text-center">Deletar</CTableHeaderCell>
                                        <CTableHeaderCell>Ordem </CTableHeaderCell>
                                        <CTableHeaderCell>Encontrista </CTableHeaderCell>
                                        <CTableHeaderCell>Lider</CTableHeaderCell>
                                        <CTableHeaderCell>Data Inscrição</CTableHeaderCell>
                                        <CTableHeaderCell>Cartas</CTableHeaderCell>
                                        <CTableHeaderCell>Impressas</CTableHeaderCell>
                                        <CTableHeaderCell>Pendentes</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    {filteredList.map((item, index) => (
                                        <CTableRow key={index} color="success" style={{ color: "green" }}>
                                            <CTableDataCell className="text-center" onClick={() => this.handleTodas(item.id, item.nome)}>
                                                <CIcon icon={cilPrint} title='Todas' />
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                <CIcon icon={cilUser} title='Individual' onClick={() => this.handleEditRow(item,item.id, item.nome)} />
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">
                                                <CIcon icon={cilTrash} title='Deletar' onClick={() => this.handleDeleteById(item.id, item.nome)} />
                                            </CTableDataCell>
                                            <CTableDataCell className="text-center">{index + 1}</CTableDataCell>
                                            <CTableDataCell>{item.nome}</CTableDataCell>
                                            <CTableDataCell>{item.nome_lider}</CTableDataCell>
                                            <CTableDataCell>{item.data_inscricao}</CTableDataCell>
                                            <CTableDataCell>{item.cartasCount}</CTableDataCell>
                                            <CTableDataCell>{item.impressasCount}</CTableDataCell>
                                            <CTableDataCell>{item.naoImpressasCount}</CTableDataCell>
                                        </CTableRow>
                                    ))}
                                </CTableBody>
                            </CTable>
                        </CCardBody>
                    </div>
                </CCard>
            </CRow>
        );
    }
}


