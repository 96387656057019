import React, { Component } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CRow,
  CCardGroup,
  CFormLabel,
  CFormTextarea,
  CSpinner,
  CCardTitle,
  CFormSelect
} from '@coreui/react';
import PropTypes from "prop-types";
import AutoSelect from "../../common/autoselect";
import { GetCartaDetails, GetEncontristaDetails } from '../../services';
import swal from 'sweetalert';
import axios from 'axios';

import logo from '../../../../src/assets/images/Logo.jpeg';

const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
    data.map((item) => {
      arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
      return null;
    });
  }
  return arrayItem;
};

export default class Create extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      getList: [],
      isLoaded: false,
      texto: '',
      imprimiu: 0,
      id_participante: 0,
      content: '',
      selectedFile: '',
      nome: '',
      isButtonDisabled: false,
      isButtonDisabled2: false,
      isSpinnerVisible: false,
      isSpinnerVisible2: false,
      url_file: '',
      relacao: '', 
    };
  }

  handleChange2 = async (e) => {    
    e.preventDefault();
    this.setState({ isButtonDisabled: true});
    const url = 'https://ibabackend-prjalvos-projects.vercel.app/api/upload';
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
   
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
       this.setState({ url_file: response.data.message, selectedFile: URL.createObjectURL(e.target.files[0]), isButtonDisabled: false })
      
    })
      .catch((error) => {
        alert("Error uploading file:", error);
      });      
  }

  async getEncontrista() {
    let list = await GetEncontristaDetails.getAllEncontristaList();
    this.setState({ getList: list.data });
    console.log(list);
  }

  async componentDidMount() {
    this.getEncontrista();
    // Extrair o nome da URL e preencher no estado
    const params = new URLSearchParams(this.props.location.search);
    const nome = params.get('nome'); // Extraindo o valor do parâmetro "nome"
  
      if (nome) {
        this.setState({ nome });
      }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleContentChange = (content) => {
    this.setState({ texto: content });
  };

  handleSelectChange = (name, selected) => {
    this.setState({
      list: {
        ...this.state.list,
        [name]: selected.value,
      },
      id_participante: selected.value
    });
    this.setState({ changed: true });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { texto, id_participante, nome, url_file,relacao } = this.state;

    if (!nome.trim()) {
      swal("Aviso!", 'Por favor, digite o seu nome!', "warning");
      return;
    }

    if (!id_participante) {
      swal("Aviso!", 'Por favor, escolha um encontrista!', "warning");
      return;
    }

    if (!relacao.trim()) {
      swal("Aviso!", 'Por favor, informe sua relação com o encontrista!', "warning");
      return;
    }

    if (!texto.trim()) {
      swal("Aviso!", 'Por favor, digite o texto da carta!', "warning");
      return;
    }

    swal({
      title: "Deseja enviar esta carta?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then(async (success) => {
      if (success) {
        this.setState({ isButtonDisabled: true});
        let data = { texto, id_participante, nome,url_file,relacao };
        let user = await GetCartaDetails.getCarta(data);
        if (user) {
          swal({
            title: "Carta enviada com sucesso. Obrigado!!!",
            icon: "success",
            confirmBtnBsStyle: "success",
            dangerMode: false,
          }).then(async (success) => {
            if (success) {
              window.location.reload();
            }
          })
        } else {
          this.setState({ isButtonDisabled: false });
          swal("Error!", 'Erro ao enviar a carta! Entre em contato', "error");
        }

        this.setState({ isButtonDisabled: false});
      }
    });
  }

  render() {
    const { texto, id_participante,relacao, nome, getList, isButtonDisabled, isSpinnerVisible,isButtonDisabled2, isSpinnerVisible2, selectedFile } = this.state;
    const relacoes = ["Pai", "Mãe","Esposo(a)","Irmão", "Irmã", "Avó", "Avô", "Tio", "Tia", "Primo(a)", "Sobrinho(a)", "Amigo(a)", "Célula"];
    return (
      <CContainer>
    <div style={{ flexDirection: 'row', marginTop: '2%', marginBottom: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CCard className="py-1 text-purple" style={{ backgroundColor: '#00914c', width: '100%' }}>
        <CCardBody style={{ color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ flex: '0 0 auto', marginRight: '10px' }}>
            <img src={logo} alt="Vida Vitoriosa Kids" style={{ width: '150px', height: '100px' }} />
          </div>
          <div style={{ textAlign: 'center', flex: 1 }}>
            <h3>Vida Vitoriosa Rede Verde.</h3>
            <h5>De 26 a 28 Julho de 2024.</h5>
            <h5>Envie sua carta.</h5>
          </div>
          {/* <div style={{ flex: '0 0 auto', marginLeft: '10px' }}>
            <img src={logo} alt="Vida Vitoriosa Kids" style={{ width: '150px', height: '100px' }} />
          </div> */}
        </CCardBody>
      </CCard>
    </div>
   
        <CCard className="text-black py-3" style={{ backgroundColor: '#00914c' }}>
          <CCardBody>
          <div style={{ cursor: isButtonDisabled ? 'wait' : 'default' }}>  
            <CRow className="align-items-center">
              <CCol md={4}>
                <CFormLabel><strong>Digite seu nome.</strong></CFormLabel>
                <CFormInput
                  type="text"
                  feedbackValid="Looks good!"
                  id="validationCustom01"
                  required
                  value={nome}
                  name="nome"
                  onChange={(e) => this.handleChange(e)}
                />
              </CCol>
              <CCol md={5}>
                <CFormLabel><strong>Escolha o encontrista que deseja enviar uma carta.</strong></CFormLabel>
                <AutoSelect
                  className="basic-single"
                  onChange={this.handleSelectChange}
                  isSearchable={true}
                  name="id"
                  label="Escolha um Encontrista"
                  options={Arrays(getList, "nome", "id")}
                />
              </CCol>
              <CCol md={3}>
              <CFormLabel><strong>Relação Encontrista.</strong></CFormLabel>
            <CFormSelect
              name="relacao"
              value={relacao}
              onChange={this.handleChange}
              required
            >
              <option value="">Selecione uma relação</option>
              {relacoes.map((relacao, index) => (
                <option key={index} value={relacao}>{relacao}</option>
              ))}
            </CFormSelect>
            </CCol>
            </CRow>
            <div style={{ flexDirection: 'row', marginTop: '2%', marginBottom: '2%', alignItems: "center", borderBlockColor: '#007AFF' }}>
              <CFormLabel><strong>Digite abaixo sua carta...</strong></CFormLabel>
              <CFormTextarea
                type="text"
                feedbackValid="Looks good!"
                required
                rows={5}
                name="texto"
                placeholder="Insira seu texto aqui..."
                style={{ backgroundColor: '#FFFFFF' }}
                onChange={(e) => this.handleChange(e)}
              />
            </div>

            <CCard style={{ backgroundColor: 'white', flexDirection: 'row', marginTop: '2%', marginBottom: '2%', alignItems: "center", borderBlockColor: '#007AFF' }}>
              <CCardBody>
                <CCardTitle>Enviar uma Foto</CCardTitle>
                <CFormInput
                  type="file"
                  size="sm"
                  id="formFileLg"
                  onChange={this.handleChange2}
                  accept="image/*"
                  disabled={isButtonDisabled}                  
                />               
              </CCardBody>
            </CCard>

            <CCard style={{ backgroundColor: 'white' }}>
              <CCardBody className="text-center">
                <CButton style={{ backgroundColor: '#00914c' }} onClick={this.handleSubmit} color='success' disabled={isButtonDisabled}>
                  {isSpinnerVisible && <CSpinner color="primary" as="span" size="sm" aria-hidden="true" />}
                  Enviar Carta</CButton>
              </CCardBody>
            </CCard>
            <CCardGroup></CCardGroup>
            </div>
          </CCardBody>
        </CCard>
        
      </CContainer>
    );
  }
}
