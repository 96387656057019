import React, { Component } from 'react'


import { GetGrupoDetails } from '../../../../services';
import swal from 'sweetalert';
import Moment from 'moment';

import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { NotificationManager } from 'react-notifications';

import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CSpinner
  } from '@coreui/react'


  import CIcon from '@coreui/icons-react'
  import {
    cilPencil,cilTrash,
  } from '@coreui/icons'

export default class List extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
  };

    constructor(props) {
        super(props);
        this.state = {
            getList: [], getList2: [], isLoaded: false, selectedGrupo: ''
        }
    }
   
   async componentDidMount() {
      this.getGrupo();
    }

    async getGrupo() {
      let list = await GetGrupoDetails.getAllGrupoList();   
      console.log(list)   
      if (list) {
          this.setState({ getList: list.grupos,getList2: list.grupos, isLoaded: false })

      }
  }


    async handlDeleteById(id,nome) {
      swal({
        title: "Deseja deletar o grupo " + nome + ' ?',        
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then(async (success) => {
            if (success) {
                let value = await GetGrupoDetails.getDeleteGrupo(id);
                if (value) {
                    NotificationManager.success(value.msg, 'Status');
                    setTimeout(
                        async function () {
                            window.location.reload();
                        },
                        1000
                    );
                }
            }
        });
    }

    
  handlEditRow(row,grupo,ferramenta,lider,supervisor,setor,area,distrito,faixa_etaria,info,status) {
      this.props.history.push({ pathname: `/admin/grupos/edit/${row}`, state: row,grupo: grupo,ferramenta: ferramenta,lider: lider,supervisor: supervisor,setor: setor,area: area,distrito: distrito,faixa_etaria: faixa_etaria,info: info,status: status })
  }
  handleAddNewUser(){
      this.props.history.push({ pathname: `/admin/grupos/create`})

  }


    render() {
        let self = this.state.getList
        let isLoaded = this.state.isLoaded
        return (
            
          <CRow>          
          <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Visualização de Células</strong> 
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{fontFamily: "Arial"}}>
                <CTableHead color="dark" >
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Descrição</CTableHeaderCell>                
                    <CTableHeaderCell >Perfil</CTableHeaderCell>
                    <CTableHeaderCell >Ferramenta</CTableHeaderCell>
                    <CTableHeaderCell >Líder</CTableHeaderCell>                 
                    <CTableHeaderCell >Supervisor</CTableHeaderCell>                    
                    <CTableHeaderCell >Supervisor Setor</CTableHeaderCell>
                    <CTableHeaderCell >Supervisor Área</CTableHeaderCell>
                    <CTableHeaderCell >Supervisor Distrito</CTableHeaderCell>                                                   
                    <CTableHeaderCell className="text-center">Alterar</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Deletar</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {self.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success" style={{color: "green"}}>                     
                      <CTableDataCell>{item.descricao}</CTableDataCell>                      
                      <CTableDataCell>{item.faixaetarium ? item.faixaetarium.descricao : '..'}</CTableDataCell> 
                      <CTableDataCell>{item.tp_ferramenta ? item.tp_ferramenta.descricao : '..'}</CTableDataCell> 
                      <CTableDataCell>{item.user_lider ? item.user_lider.firstName  : '..'}</CTableDataCell>                    
                      <CTableDataCell>{item.user_supervisor ? item.user_supervisor.firstName  : '..'}</CTableDataCell>
                      <CTableDataCell>{item.user_setor ? item.user_setor.firstName  : '..'}</CTableDataCell> 
                      <CTableDataCell>{item.user_area ? item.user_area.firstName  : '..'}</CTableDataCell> 
                      <CTableDataCell>{item.user_distrito ? item.user_distrito.firstName  : '..'}</CTableDataCell>                      
                      <CTableDataCell className="text-center" onClick={(e) => this.handlEditRow(item,item.faixa_etaria,item.tp_ferramenta.descricao,item.user_lider.firstName,item.user_supervisor.firstName,item.user_setor.firstName,item.user_area.firstName,item.user_distrito.firstName,item.user_rede.firstName,item.faixaetarium.descricao,item.info,item.status)} >                        
                      <CIcon  icon={cilPencil} title='Alterar'/>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">                        
                      <CIcon icon={cilTrash} title='Deletar' onClick={(e) => this.handlDeleteById(item.id,item.descricao)}/> 
                      </CTableDataCell>
                    </CTableRow>
                  ))}

                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>
              
              <CButton style={{ flexDirection: 'row', marginTop: 10 }} color="success" width={80} onClick={(e) => this.props.history.push({ pathname: `/admin/grupos/create`})}> Nova Célula</CButton>                          
              
              </CCardBody>
            </CCard> 
            {
                isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
            }
            
            </CCol >
            
            </CRow>

        )
    }
}
