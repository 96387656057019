import React from 'react'
import {  
  CCol,
  CContainer,
  CRow,
} from '@coreui/react'


const Page404 = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-top">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="clearfix">              
              <h4 className="pt-3">Acesso não autorizado.</h4>
              <p className="text-medium-emphasis float-start">
              Você não tem permissão para acessar esta página. Entre em contato com o administrador.
              </p>
            </div>         
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Page404
