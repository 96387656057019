import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getCarta = async (data) => {
    try {   
       
        let result = await api.post(Apis.GetAllCartaCreate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getCartaList = async () => {
    try {
        let result = await api.get(Apis.GetCartaList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCartaListID = async (id) => {
    try {
        let result = await api.get(Apis.GetCartaListId,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getCartaDeleteById = async (id) => {
    try {
        let result = await api.delete(Apis.GetCartaDeleteById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCartaUpdate = async (data) => {
    try {
        let result = await api.post(Apis.GetCartaUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCartaUpdateAll = async (data) => {
    try {
        let result = await api.post(Apis.GetCartaUpdateAll,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export default {
    getCarta,
    getCartaList,
    getCartaDeleteById,
    getCartaUpdate,   
    getCartaUpdateAll,
    getCartaListID, 
};