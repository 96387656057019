import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getAreas = async (descricao,tipo) => {
    try {
        // data.date = moment().format();
        // let data = {descricao: descricao,sup:sup,periodo:periodo}
        let data = {descricao: descricao,tipo: tipo}
        let result = await api.post(Apis.GetAllAreasCreate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getAreasList = async () => {
    try {
        let result = await api.get(Apis.GetAllAreasList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAreasListID = async () => {
    try {
        let result = await api.get(Apis.GetAreasListId);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getAreasDeleteById = async (id) => {
    try {
        let result = await api.delete(Apis.GetAreasDeleteById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAreasUpdate = async (data) => {
    try {
        let result = await api.post(Apis.GetAreasUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    getAreas,
    getAreasList,
    getAreasDeleteById,
    getAreasUpdate,   
    getAreasListID, 
};