
import React, { useState } from 'react';
import axios from 'axios';

const DocumentGenerator = ({ location }) => {
  const { remetente,nome,texto, url_file,relacao } = location.state;

  const generateDocument = async (remetente, nome, texto, url_file,relacao) => {
    try {
        const templateFile = await axios.get('/files/Vida.docx', { responseType: 'blob' });
        const fileBlob = templateFile.data;
        const formData = new FormData();
        formData.append('template', fileBlob, 'Vida.docx');
        formData.append('remetente', remetente);
        formData.append('relacao', relacao);
        formData.append('destinatario', nome);
        formData.append('texto', texto);
        formData.append('url', url_file);
        
        const response = await fetch('https://185.228.72.82:9003/generate-doc', {
            method: 'POST',
            body: formData,
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const blob = await response.blob();

        
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Vida_${remetente}_${nome}.pdf`); // File name for the download
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error generating document:', error);
    }
};



  const handleClick = () => {
    generateDocument(remetente,nome,texto, url_file,relacao);
  };

  return (
    <div className="p-2">
      <button onClick={handleClick}>Baixar Documento</button>
    </div>
  );
};

export default DocumentGenerator;



