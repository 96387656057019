import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';

import PropTypes from "prop-types";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function SimpleBottomNavigation({ handleChange }) {
  const [value, setValue] = React.useState(0);
  
  const handleSentValue = (val) => {    
    handleChange(val);
  };

  return (
   
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);  
          handleSentValue(newValue);        
        }}
      >
        <BottomNavigationAction label="Classe Integração" icon={<ReduceCapacityOutlinedIcon />} />
        <BottomNavigationAction label="Visão" icon={<Diversity1OutlinedIcon />} />
        <BottomNavigationAction label="Estudos Bíblicos" icon={<CastForEducationOutlinedIcon />} />        
        <BottomNavigationAction label="Avivalismo" icon={<FavoriteIcon />} />        
        <BottomNavigationAction label="Voltar" icon={<ArrowBackIcon />} />    
      </BottomNavigation>

      
   
  );
}


SimpleBottomNavigation.propTypes = {
  handleChange: PropTypes.any,    
};