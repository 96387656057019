import React, { Component } from 'react';
import { Switch, Route,useRouteMatch } from 'react-router-dom'
import Create from './create';


export default class Location extends Component {
    render() {
        return (
        <Users />
        )
    }
}

function Users()
{

  let match = useRouteMatch();
  return (
    <div id="layoutSidenav_content">
        <main>
            <Switch>                
                <Route path={[`${match.path}/create`]} component={Create} />                            
            </Switch>
        </main>
    </div>
);  
} 