import React, { useState, Component  } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CSpinner
} from '@coreui/react'
import swal from 'sweetalert';
import Moment from 'moment';
import Loader from '../../../../loader';
import AutoSelect from "../../../../common/autoselect";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { GetGrupoMembrosDetails,GetMinisterioDetails } from '../../../../services';
import { getCookie } from '../../../../../function';


let id_lider = getCookie("id_lider");



const Arrays = (data, fieldName, fieldValue) => {
    
  let arrayItem = [];
  if (data && Array.isArray(data)) {
      data.map((item, key) => {
          arrayItem.push({ label: item[fieldName] , value: item[fieldValue] });
          return null;
      });
  }
  return arrayItem;
};



export default class Create extends Component {

  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

  constructor(props) {
      super(props);
      let userdata = this.props.location.state;
      this.state = {            
        getList3:[],nome:userdata.nome,telefone:userdata.telefone,email:userdata.email,status:userdata.status,id:userdata.id,info:userdata.info,
        data_nascimento:userdata.data_nascimento,isLoaded: false,id_ministerio:'',descricao:null 
          
      }
  }

 

  SelectChangeMinisterio = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        id_ministerio: selected,
    });
    this.setState({ changed: true });
  };  



 async getMinisterio() {
    let list = await GetMinisterioDetails.getMinisterioList();
    this.setState({ getList3: list.data })
  }

  handleChange(e) {
      this.setState({ [e.target.name]: e.target.value })
  }
  

  async componentDidMount() {
    this.getMinisterio();            

  }


  handleSubmit = async event => {
    event.preventDefault();  
    
    swal({
        title: "Adicionar Novo Membro ?",
        buttons: true,
        dangerMode: true,
    })
    .then(async (success) => {
      if (success) {     
        this.setState({ isLoaded: true })
        let {  id,nome, status,telefone,email,info,data_nascimento,id_ministerio } = this.state;
    
        let data = { id:id,nome:nome, status:status,telefone:telefone,email:email,info:info,data_nascimento:Moment(data_nascimento).format('YYYY-MM-DD'),id_ministerio:id_ministerio}
        // make API call
        let user = await GetGrupoMembrosDetails.getUpdateGrupoMembros(data);
        if (user) {
            this.setState({ isLoaded: false })
            this.props.history.goBack();            
        } else {
            alert('erro')
        }
    }
  });
}

 
  render() {

    let { getList3,isLoaded, descricao,nome, status,telefone,email,info,data_nascimento,data_conversao,id_ministerio} = this.state;
    
      return (


  <CRow>
  <CCol xs={12}>
  <CCard className="mb-4">
    <CCardHeader color="sucess">
          <strong>Alterar Membros Célula</strong> 
    </CCardHeader>
  <CCardBody>
         
  <CForm
    className="row g-3 needs-validation"
    Validate
    validated={this.validated}
    onSubmit={this.handleSubmit}
  >
    <CCol md={4}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Nome"
        required        
        value={nome}   
        name="nome"      
        onChange={(e) => this.handleChange(e)} 
      />
    </CCol> 

    <CCol md={4}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Telefone"
        required        
        value={telefone}   
        name="telefone"      
        onChange={(e) => this.handleChange(e)} 
      />
    </CCol>   

    <CCol md={4}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Email"
        required        
        value={email}   
        name="email"      
        onChange={(e) => this.handleChange(e)} 
      />
    </CCol>   

    <CCol md={4}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Nascimento"
        required        
        value={Moment(data_nascimento).format('YYYY-MM-DD')} 
        name="data_nascimento"      
        onChange={(e) => this.handleChange(e)} 
      />
    </CCol>   

    <CCol md={4}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Conversão"
        required        
        value={Moment(data_conversao).format('YYYY-MM-DD')}          
        name="data_conversao"      
        onChange={(e) => this.handleChange(e)} 
      />
    </CCol>   

    <CCol md={4}>
    <CFormLabel>Ministério</CFormLabel> 
    <AutoSelect
      className="basic-single"
      //value={id_ministerio}      
      onChange={this.SelectChangeMinisterio}                                                                                       
      isSearchable={true}
      name="id_ministerio"
      label={this.props.location.descricao}
      options={Arrays(getList3, "descricao", "id")}
      />
    </CCol>  

    <CCol md={2}>
    <CFormSelect label="Status" size="sm" className="mb-3" aria-label="Small select example" defaultValue={status} name="status" onChange={(e) => this.handleChange(e)}>      
      <option value="1" defaultChecked>Ativo</option>
      <option value="0">Inativo</option>
    </CFormSelect>
    </CCol>

    <CCol md={4}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Outras Informações"
        required        
        value={info}  
        name="info"      
        onChange={(e) => this.handleChange(e)} 
      />
    </CCol>   
    

    <CCol xs={12}>
      <CButton color="success" type="submit">
        Confirmar
      </CButton>
    </CCol>
  </CForm>

  </CCardBody>
  </CCard> 
  </CCol>
  {
                            isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
            }
  </CRow>
)
}

}
