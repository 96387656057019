import React , { Component }  from 'react'

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'

import {
  Typography, Button
} from "@material-ui/core";

import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
} from '@coreui/icons'

import { GetOrderDetails, GetDashboardDetails } from '../../services';
import { getCookie } from '../../../function';


import WidgetsDropdown from '../widgets/WidgetsDropdown'
import { Link } from 'react-router-dom';


let id_lider = getCookie("id_lider");
let role = getCookie("role");
export default class Home extends Component {
  constructor(props) {
      super(props);
      this.state = {
          getList: [],getList2: [], isloaded: false, status: null, statusList: null,groupList: null,membrosList:null,
          formulariosList:null,offset: 0,total_membros:0,total_celulas:0,
          total_batismo:0,total_membresia:0,total_lider_celula:0,total_disc_superv:0,total_kids_incl:0,
          total_intr_b_nt:0,total_ant_test:0,total_batalha_esp:0,total_tetelestai:0,
          total_vida:0,total_tsd:0,total_discipulado:0
         
      }
  }
  
  async getFormulariosList() {
    this.setState({ isloaded: true })
    let list = await GetDashboardDetails.getAllFormularios();
    console.log("Formularios")
    console.log(list.data)
    if (list) {         
  
        list = list.data.filter((item) => 
        item.grupo.id_lider ==id_lider || 
        item.grupo.id_colider ==id_lider || 
        item.grupo.id_supervisor == id_lider ||
        item.grupo.id_setor == id_lider ||
        item.grupo.id_area == id_lider ||
        item.grupo.id_distrito == id_lider ||                                
        item.grupo.id_rede == id_lider || role === 'admin'
        )
  
        //this.setState({total_batismo:list.reduce((total, item) => total + item.batismo,0)}) ,
        this.setState({ total_batismo: list.reduce((total, item) => total + item.batismo, 0) });
  
        
        this.setState({ total_membresia: list.reduce((total, item) => total + item.membresia, 0) }) ;          
        this.setState({ total_lider_celula: list.reduce((total, item) => total + item.lider_celula, 0) });
        this.setState({ total_disc_superv: list.reduce((total, item) => total + item.disc_superv, 0) }) ;
        this.setState({ total_kids_incl: list.reduce((total, item) => total + item.kids_incl, 0) }) ;
        this.setState({ total_intr_b_nt: list.reduce((total, item) => total + item.intr_b_nt, 0) }) ;
        this.setState({ total_ant_test: list.reduce((total, item) => total + item.ant_test, 0) });
        this.setState({ total_batalha_esp: list.reduce((total, item) => total + item.batalha_esp, 0) });
        this.setState({ total_tetelestai: list.reduce((total, item) => total + item.tetelestai, 0) });
        this.setState({ total_vida: list.reduce((total, item) => total + item.vida, 0) });
        this.setState({ total_tsd: list.reduce((total, item) => total + item.tsd, 0) });
        this.setState({ total_discipulado: list.reduce((total, item) => total + item.discipulado, 0) });
        this.setState({ formulariosList: list.data, isloaded: false });
    } else {
        this.setState({ isloaded: true })
    }        
  }
  async getGrupoList() {
    this.setState({ isloaded: true })
    let list = await GetDashboardDetails.getAllGrupo();
    console.log("list") 
    console.log(list)
    list = list.data.filter((item) => 
    item.id_lider ==id_lider || 
    item.id_colider ==id_lider || 
    item.id_supervisor == id_lider ||
    item.id_setor == id_lider ||
    item.id_area == id_lider ||
    item.id_distrito == id_lider ||
    item.grupo.id_rede == id_lider    || role === 'admin'                            
    )
    if (list) {
        this.setState({ total_celulas: list.length, isloaded: false })
    } else {
        this.setState({ isloaded: true })
    }
  
  }
  
  async getMembrosList() {
    this.setState({ isloaded: true })
    let list = await GetDashboardDetails.getAllMembros();
    
    list = list.data.filter((item) => 
        item.grupo.id_lider ==id_lider || 
        item.grupo.id_colider ==id_lider || 
        item.grupo.id_supervisor == id_lider ||
        item.grupo.id_setor == id_lider ||
        item.grupo.id_area == id_lider ||
        item.grupo.id_distrito == id_lider ||
        item.grupo.id_rede == id_lider    || role === 'admin'                           
        )
           
    if (list) {
        this.setState({total_membros:list.length})
        //this.setState({ membrosList: list.data, isloaded: false })
    } else {
        this.setState({ isloaded: true })
    }        
  }
  
  componentDidMount() {
    this.getGrupoList();
    this.getFormulariosList()
    this.getMembrosList()
  
  }

  render() {
    const { getList, isloaded, status, statusList,total_celulas,membrosList,total_membros,
      total_batismo,total_membresia,total_lider_celula,total_disc_superv,total_kids_incl,
      total_intr_b_nt,total_ant_test,total_batalha_esp,total_tetelestai,
      total_vida,total_tsd,total_discipulado } = this.state;

  return (
    <>
         <WidgetsDropdown />

      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader><strong> Classe Integração </strong></CCardHeader>
            <CCardBody>
              

              <br />

              <CTable align="middle" className="mb-0 border" hover responsive small>
                <CTableHead color="light">
                  <CTableRow color="success">                    
                    <CTableHeaderCell>Não Batizados</CTableHeaderCell>
                    <CTableHeaderCell>Percentual Não Batizados</CTableHeaderCell>
                    <CTableHeaderCell>Sem Membresia</CTableHeaderCell>
                    <CTableHeaderCell >Percentual Sem Membresia</CTableHeaderCell>
                    <CTableHeaderCell>Não Fizeram o Vida</CTableHeaderCell>
                    <CTableHeaderCell>Percentual Não Fizeram o Vida</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody> 
                    <CTableRow v-for="item in tableItems" color="success">                      
                      <CTableDataCell>
                      <Link  to={{
                                                                                pathname: `/admin/rel_formulario/list/Batismo`,                                                                            
                                                                            }}>
                        <div>{total_membros - total_batismo}</div>     
                        </Link>                   
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_batismo)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_batismo)/total_membros) * 100)} />
                      </CTableDataCell>   

                      <CTableDataCell>
                      <Link  to={{pathname: `/admin/rel_formulario/list/Membresia`}}>
                         <div>{total_membros -total_membresia}</div>     
                       </Link>                    
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_membresia)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_membresia)/total_membros) * 100)} />
                      </CTableDataCell>   
                      <CTableDataCell>
                        <Link  to={{pathname: `/admin/rel_formulario/list/Vida`}}>
                         <div>{total_membros - total_vida}</div>                       
                        </Link>  
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_vida)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_vida)/total_membros) * 100)} />
                      </CTableDataCell>   


                    </CTableRow>                  
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader><strong> Membros sem os cursos da Visão </strong></CCardHeader>
            <CCardBody>
              

              <br />

              <CTable align="middle" className="mb-0 border" hover responsive small>
                <CTableHead color="light">
                  <CTableRow color="success">                    
                    <CTableHeaderCell>Lider de Célula</CTableHeaderCell>
                    <CTableHeaderCell>Percentual Sem Lider de Célula</CTableHeaderCell>
                    <CTableHeaderCell>Discipulado e Supervisão</CTableHeaderCell>
                    <CTableHeaderCell >Percentual Discipulado e Supervisão</CTableHeaderCell>
                    <CTableHeaderCell>Líder Kids e Inclusão</CTableHeaderCell>
                    <CTableHeaderCell>Percentual Líder Kids e Inclusão</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody> 
                    <CTableRow v-for="item in tableItems" color="success">                      
                      <CTableDataCell>
                      <Link  to={{
                                                                                pathname: `/admin/rel_formulario/list/Lider`,                                                                            
                                                                            }}>
                        <div>{total_membros - total_lider_celula}</div>     
                        </Link>                   
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_lider_celula)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_lider_celula)/total_membros) * 100)} />
                      </CTableDataCell>   

                      <CTableDataCell>
                      <Link  to={{pathname: `/admin/rel_formulario/list/Superv`}}>
                         <div>{total_membros -total_disc_superv}</div>     
                       </Link>                    
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_disc_superv)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_disc_superv)/total_membros) * 100)} />
                      </CTableDataCell>   
                      <CTableDataCell>
                        <Link  to={{pathname: `/admin/rel_formulario/list/Kids`}}>
                         <div>{total_membros - total_kids_incl}</div>                       
                        </Link>  
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_kids_incl)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_kids_incl)/total_membros) * 100)} />
                      </CTableDataCell>   


                    </CTableRow>                  
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>


      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader><strong> Membros sem os Estudos Bíblicos </strong></CCardHeader>
            <CCardBody>
              

              <br />

              <CTable align="middle" className="mb-0 border" hover responsive small>
                <CTableHead color="light">
                  <CTableRow color="success">                    
                    <CTableHeaderCell>Intro. Bíblica e Novo Testamento</CTableHeaderCell>
                    <CTableHeaderCell>Percentual Intro. Bíblica e Novo Testamento</CTableHeaderCell>
                    <CTableHeaderCell>Antigo Testamento</CTableHeaderCell>
                    <CTableHeaderCell >Percentual Antigo Testamento</CTableHeaderCell>                    
                  </CTableRow>
                </CTableHead>
                <CTableBody> 
                    <CTableRow v-for="item in tableItems" color="success">                      
                      <CTableDataCell>
                      <Link  to={{
                                                                                pathname: `/admin/rel_formulario/list/Biblico`,                                                                            
                                                                            }}>
                        <div>{total_membros - total_intr_b_nt}</div>     
                        </Link>                   
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_intr_b_nt)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_intr_b_nt)/total_membros) * 100)} />
                      </CTableDataCell>   

                      <CTableDataCell>
                      <Link  to={{pathname: `/admin/rel_formulario/list/Antigo`}}>
                         <div>{total_membros -total_ant_test}</div>     
                       </Link>                    
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_ant_test)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_ant_test)/total_membros) * 100)} />
                      </CTableDataCell>                         

                    </CTableRow>                  
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>


      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader><strong> Membros sem os Cursos de Avivalismo </strong></CCardHeader>
            <CCardBody>
              

              <br />

              <CTable align="middle" className="mb-0 border" hover responsive small>
                <CTableHead color="light">
                  <CTableRow color="success">                    
                    <CTableHeaderCell>Batalha Espiritual</CTableHeaderCell>
                    <CTableHeaderCell>Percentual Batalha Espiritual</CTableHeaderCell>
                    <CTableHeaderCell>Tetelestai</CTableHeaderCell>
                    <CTableHeaderCell >Percentual Tetelestai</CTableHeaderCell>                    
                  </CTableRow>
                </CTableHead>
                <CTableBody> 
                    <CTableRow v-for="item in tableItems" color="success">                      
                      <CTableDataCell>
                      <Link  to={{
                                                                                pathname: `/admin/rel_formulario/list/Batalha`,                                                                            
                                                                            }}>
                        <div>{total_membros - total_batalha_esp}</div>     
                        </Link>                   
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_batalha_esp)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_batalha_esp)/total_membros) * 100)} />
                      </CTableDataCell>   

                      <CTableDataCell>
                      <Link  to={{pathname: `/admin/rel_formulario/list/Tetelestai`}}>
                         <div>{total_membros -total_tetelestai}</div>     
                       </Link>                    
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{(total_membros - total_tetelestai)/total_membros * 100}%</strong>
                          </div>                         
                        </div>
                      <CProgress thin color='success' value={Math.round(((total_membros - total_tetelestai)/total_membros) * 100)} />
                      </CTableDataCell>                         

                    </CTableRow>                  
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>



    </>
  )
}
}
