import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilCalendarCheck,
  cilFire,
  cilSpeedometer,
  cilStar,
  cilNewspaper,
  cilMagnifyingGlass,
  cilPlus,
  cilCog,
  cilGroup,
  cilBook,
  cilEqualizer,
  cilVoiceOverRecord,
  cilUserFollow,
  cilCloudUpload,
  cilUser,
  cilSitemap,
  cilHouse
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import { getCookie } from './function';

let role = getCookie("role");

const _nav = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/dashboard',        
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'light',      
    },
  },
  {
    component: CNavTitle,
    name: 'Cadastros',    
  },
  {
    component: CNavGroup,
    name: 'Lideranças',
    icon: <CIcon icon={cilVoiceOverRecord} customClassName="nav-icon" />,
    hidden: role !== 'admin',
    items: [
      {
        component: CNavItem,
        name: 'Ver',
        to: '/admin/user/list',
        icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Criar',
        to: '/admin/user/create',
        icon: <CIcon icon={cilPlus} customClassName="nav-icon" />,
      },     
    ],
  },  
  {
    component: CNavTitle,
    name: 'Batismo',
  },
  {
    component: CNavGroup,
    name: 'Batismo',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'ver',
        to: '/admin/batismo/list',
        icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
      },     
      {
        component: CNavItem,
        name: 'Relatorio',
        to: '/admin/batismo/relatorio',
        icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
      },     
    ],
  },

  {
    component: CNavTitle,
    name: 'Vida Vitoriosa',
  },
  {
    component: CNavGroup,
    name: 'Vida',
    icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
    //hidden: role !== 'admin',
    items: [
      {
        component: CNavItem,
        name: 'Ver',
        to: '/admin/vida/list',
        icon: <CIcon icon={cilMagnifyingGlass} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Carga CSV',
        to: '/admin/vida/create',
        icon: <CIcon icon={cilCloudUpload} customClassName="nav-icon" />,
      },     
      
    ],
  },


]

export default _nav
