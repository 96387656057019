import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getOrderByStatus = async (status) => {
    try {
        let data = { status: status}
        let result = await api.post(Apis.GetOrderByStatus,data);
        if (result.errors) {
            NotificationManager.error(result.errors);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllStatusOrder = async () => {
    try {
        let result = await api.get(Apis.GetAllStatusOrder);
        if (result.errors) {
            NotificationManager.error(result.errors);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllCargo = async () => {
    try {
        let result = await api.get(Apis.GetAllCargo);
        if (result.errors) {
            NotificationManager.error(result.errors);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllGrupo = async () => {
    try {
        let result = await api.get(Apis.GetAllGrupo);
        if (result.errors) {
            NotificationManager.error(result.errors);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllMembros = async (id=null) => {
    try {
        let result = await api.get(Apis.GetAllMembros,{
            id: id
        });
        if (result.errors) {
            NotificationManager.error(result.errors);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllFormularios = async () => {
    try {
        let result = await api.get(Apis.GetAllFormularios);
        if (result.errors) {
            NotificationManager.error(result.errors);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export default {
    getOrderByStatus,
    getAllStatusOrder,
    getAllCargo,
    getAllGrupo,
    getAllMembros,
    getAllFormularios
};