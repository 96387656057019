const API_URL =
    document.domain === 'localhost'
    ? "https://ibabackend-prjalvos-projects.vercel.app"
    : "https://ibabackend-prjalvos-projects.vercel.app";

const Apis = {
  //Authentication api
  GetUserLogin: `${API_URL}/api/auth/rootLogin`,
  GetUserRegsiter: `${API_URL}/api/auth/register`,
  GetAllUserList: `${API_URL}/api/auth/user/getAllUserList`,
  GetUserUpdate: `${API_URL}/api/auth/user/update`,
  GetDeleteUserList: `${API_URL}/api/auth/user/delete`,

   //Carta api
   GetAllCartaCreate: `${API_URL}/api/carta_vida/create`,
   GetCartaDeleteById: `${API_URL}/api/carta_vida/delete`,
   GetCartaUpdate: `${API_URL}/api/carta_vida/update`,
   GetCartaUpdateAll: `${API_URL}/api/carta_vida/updateall`,
   GetCartaListId: `${API_URL}/api/carta_vida/getCartaById?id=`,
   GetCartaList: `${API_URL}/api/carta_vida/list`,

  //batismo api
  GetEncontristaRegister: `${API_URL}/api/encontrista/register`,
  GetAllEncontristaList: `${API_URL}/api/encontrista/getAllencontristaList`,
  GetAllEncontristaListById: `${API_URL}/api/encontrista/getAllencontristaListById`,
  GetEncontristaUpdate: `${API_URL}/api/encontrista/update`,
  GetDeleteEncontristaList: `${API_URL}/api/encontrista/delete`,
  GetAllEncontristaCount: `${API_URL}/api/encontrista/getAllencontristaCount`,
  GetCartasSum: `${API_URL}/api/encontrista/CartasSum`,

   //batismo api
   GetBatismoRegister: `${API_URL}/api/batismo/register`,
   GetAllBatismoList: `${API_URL}/api/batismo/getAllBatismoList`,
   GetAllBatismoListById: `${API_URL}/api/batismo/getAllBatismoListById`,
   GetBatismoUpdate: `${API_URL}/api/batismo/update`,
   GetDeleteBatismoList: `${API_URL}/api/batismo/delete`,
   

  //Dashboard
  GetOrderByStatus: `${API_URL}/api/order/status`,
  //GetAllStatusOrder: `${API_URL}/api/order/count`,
  GetAllCargo: `${API_URL}/api/order/count`,
  GetAllGrupo: `${API_URL}/api/order/count/getAllGrupoCount`,
  GetAllMembros: `${API_URL}/api/order/count/getAllMembroCount`,
  GetAllFormularios: `${API_URL}/api/order/count/getAllFormularioCount`,



  //Vendor api
  CreateSupplierList: `${API_URL}/api/supplier/create`,
  CreateSupplierProduct: `${API_URL}/api/supplier/product-add`,
  GetAllSellerList: `${API_URL}/api/supplier/list`,
  GetUpdateSellerList: `${API_URL}/api/supplier/update`,
  GetDeleteSellerList: `${API_URL}/api/supplier/delete`,

  //Cargo api
  GetAllCargoCreate: `${API_URL}/api/cargo/create`,
  GetAllCargoList: `${API_URL}/api/cargo/list`,
  GetCargoDeleteById: `${API_URL}/api/cargo/delete`,
  GetCargoUpdate: `${API_URL}/api/cargo/update`,
  GetCargoListId: `${API_URL}/api/cargo/getCargoById?id=`,


 


   //Faixa Etaria api
   GetAllfaixaetariaCreate: `${API_URL}/api/faixaetaria/create`,
   GetAllfaixaetariaList: `${API_URL}/api/faixaetaria/list`,
   GetfaixaetariaDeleteById: `${API_URL}/api/faixaetaria/delete`,
   GetfaixaetariaUpdate: `${API_URL}/api/faixaetaria/update`,
   GetfaixaetariaListId: `${API_URL}/api/faixaetaria/getfaixaetariaById?id=`,


  //TP Grupo api
  GetAllTp_Grupo_Create: `${API_URL}/api/tp_grupo/create`,
  GetAllTp_Grupo_List: `${API_URL}/api/tp_grupo/list`,
  GetTp_Grupo_DeleteById: `${API_URL}/api/tp_grupo/delete`,
  GetTp_Grupo_Update: `${API_URL}/api/tp_grupo/update`,

  //TP Ferramenta api
  GetAllTp_Ferramenta_Create: `${API_URL}/api/tp_ferramenta/create`,
  GetAllTp_Ferramenta_List: `${API_URL}/api/tp_ferramenta/list`,
  GetTp_Ferramenta_DeleteById: `${API_URL}/api/tp_ferramenta/delete`,
  GetTp_Ferramenta_Update: `${API_URL}/api/tp_ferramenta/update`,

  //Grupo api
  AddGrupoList: `${API_URL}/api/grupo/add`,
  getAllGrupoList: `${API_URL}/api/grupo/getAllGrupoList`,
  getAllLider: `${API_URL}/api/grupo/getAllLider`,
  getUpdateGrupo: `${API_URL}/api/grupo/update`,  
  GetDeleteGrupo: `${API_URL}/api/grupo/delete`,
  GetGrupoById: `${API_URL}/api/grupo/getGrupoById`,

  //Agenda api
  AddAgendaList: `${API_URL}/api/agenda/add`,
  getAllAgendaList: `${API_URL}/api/agenda/getAllAgendaList`,
  getAgendaDelete: `${API_URL}/api/agenda/delete`,
  getAgendaUpdate: `${API_URL}/api/agenda/update`,
  getVisita_Setor: `${API_URL}/api/agenda/getVisita_Setor`,
  getVisita_Area: `${API_URL}/api/agenda/getVisita_Area`,
  getVisita_Distrito: `${API_URL}/api/agenda/getVisita_Distrito`,


  //Ministerio api
  AddMinisterioCreate: `${API_URL}/api/ministerio/create`,
  GetAllMinisterioList: `${API_URL}/api/ministerio/list`,
  GetMinisterioUpdate: `${API_URL}/api/ministerio/update`,  
  GetMinisterioDeleteById: `${API_URL}/api/ministerio/delete`,
  GetMinisterioById: `${API_URL}/api/ministerio/getProductById`,

    //Grupo api
    AddFormularioList: `${API_URL}/api/formulario/add`,
    getAllFornularioList: `${API_URL}/api/grupo/getAllFormularioList`,
    getAllLider: `${API_URL}/api/grupo/getAllLider`,
    getUpdateGrupo: `${API_URL}/api/grupo/update`,  
    GetDeleteGrupo: `${API_URL}/api/grupo/delete`,
    GetFormularioById: `${API_URL}/api/formulario/getFormularioById`,
    GetComprometimentosById: `${API_URL}/api/comprometimentos/getAllComprometimentosListID`,
    GetUpadateComprometimentos: `${API_URL}/api/comprometimentos/update`,


    //Grupo Mebro api
    AddGrupoMembrosList: `${API_URL}/api/grupo_membros/add`,
    getAllGrupoMembrosList: `${API_URL}/api/grupo_membros/getAllGrupoMembrosList`,
    getAllGrupoMembrosListID: `${API_URL}/api/grupo_membros/getAllGrupoMembrosListID`,

    getAllGrupoMembrosFormula: `${API_URL}/api/grupo_membros/getAllGrupoMembrosFormula`,


    getAllLiderMembros: `${API_URL}/api/grupo_membros/getAllLider`,
    getUpdateGrupoMembros: `${API_URL}/api/grupo_membros/update`,  
    GetDeleteGrupoMembros: `${API_URL}/api/grupo_membros/delete`,
    GetGrupoMembrosById: `${API_URL}/api/grupo_membros/getProductById`,
     
    //Areas api
    GetAllAreasCreate: `${API_URL}/api/areas/create`,
    GetAllAreasList: `${API_URL}/api/areas/list`,
    GetAreasDeleteById: `${API_URL}/api/areas/delete`,
    GetAreasUpdate: `${API_URL}/api/areas/update`,
    GetAreasListId: `${API_URL}/api/areas/getareasById?id=`,


};
export { API_URL, Apis };
