import React, { Component } from 'react'


import { GetBatismoDetails } from '../../../../services';
import swal from 'sweetalert';
import Moment from 'moment';

import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { NotificationManager } from 'react-notifications';

import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CSpinner
  } from '@coreui/react'

  import { getStyle } from '@coreui/utils'

  import { CChartBar, CChartLine,CChart } from '@coreui/react-chartjs'
  import CIcon from '@coreui/icons-react'
  import {
    cilPencil,cilTrash,
  } from '@coreui/icons'

  import { getCookie } from '../../../../../function';
  let nome = getCookie("nome");
  let role = getCookie("role");

const supervisorTotals1 = {};

export default class List extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
  };

    constructor(props) {
        super(props);
        this.state = {
            getList: [],isLoaded:false,total:0,curso:0,inscricao:0,reuniao:0,supervisorTotals:{},
            selectedDate: "2024-10-05",
        }
    }
   
   async componentDidMount() {
      this.getBatismo('2024-10-05');
    }    

    async componentWillUnmount() {
      supervisorTotals1 = {}
    }

    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value })  
      this.getBatismo(e.target.value); 
    }


    async getBatismo(data) {
        this.setState({ isLoaded: true })
        let list = await GetBatismoDetails.getAllBatismoList();
        let list1 =[]
  
        if (list) {       

          // this.setState({ total: list.reduce((total, item) => total + 1, 0) });
        list1 =  list.data.filter(item => item.supervisores  === nome || role === 'admin') 
      
        if (data) {        
          list1 = list1.filter((item) => item.data_batismo === data);
        }
        this.setState({ total: list1.length });
        this.setState({ curso: list1.filter(item => item.curso === 'Sim').length });
        this.setState({ inscricao: list1.filter(item => item.inscricao === 'Sim').length });
        this.setState({ reuniao: list1.filter(item => item.reuniao === 'Sim').length });

        //const supervisorTotals1 = {};

        if (list1.length === 0) {
          // Se a lista estiver vazia, zere as variáveis supervisorTotals1
          Object.keys(supervisorTotals1).forEach((supervisor) => {
            supervisorTotals1[supervisor] = {
              cursoCount: 0,
              inscricaoCount: 0,
              reuniaoCount: 0,
              supcount: 0,
            };
          });
        } 

        list1.forEach((item) => {
          const supervisor = item.supervisores;      
          supervisorTotals1[supervisor] = {
            cursoCount: 0,
            inscricaoCount: 0,
            reuniaoCount: 0,
            supcount: 0,
          };
        })  

        list1.forEach((item) => {
          const supervisor = item.supervisores;
          supervisorTotals1[supervisor] = supervisorTotals1[supervisor] || {
            cursoCount: 0,
            inscricaoCount: 0,
            reuniaoCount: 0,
            supcount:0,
          };
        
          supervisorTotals1[supervisor].supcount++;

          if (item.curso === 'Sim') {
            supervisorTotals1[supervisor].cursoCount++;
          }
        
          if (item.inscricao === 'Sim') {
            supervisorTotals1[supervisor].inscricaoCount++;
          }
        
          if (item.reuniao === 'Sim') {
            supervisorTotals1[supervisor].reuniaoCount++;
          }
        });

        }
        else{
          this.props.history.push({ pathname: `/auth/login`})           
        }

        this.setState({ supervisorTotals: supervisorTotals1 })
        this.setState({ isLoaded: false })
    }

        
    async handlDeleteById(id,cargo) {
      swal({
          title: "Excluir batizando " + cargo + " ?",     
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
          .then(async (success) => {
              if (success) {
                  this.setState({ isLoaded: true })
                  let value = await GetBatismoDetails.getDeleteBatismoList(id);
                  if (value) {                    
                    this.getBatismo();
                  }
                  this.setState({ isLoaded: false })
              }
          });
   }   


   handlEditRow(row,nome,email,telefone,est_c,idade,celula,lider,supervisores,ne,turma,curso, inscricao, reuniao) {      
    this.props.history.push({ pathname: `/admin/batismo/edit/${row}`,state: row,nome:nome ,email: email,telefone: telefone,est_c: est_c,idade: idade,celula: celula,
      lider: lider,supervisores: supervisores,ne: ne,turma: turma, curso: curso, inscricao: inscricao, reuniao: reuniao})
  }
 
    render() {        
         const{total,curso,inscricao,reuniao,supervisorTotals,selectedDate} = this.state;
        return (
            
          <CRow>          
          <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Relatório de Batismo</strong> 
                  <br />
                  <label htmlFor="filtroData" style={{ marginRight: '10px' }}>Filtrar por Data: </label>
                <input
                  type="date"
                  value={selectedDate}
                  name="selectedDate"
                  onChange={(e) => this.handleChange(e)}
                />
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{fontFamily: "Arial"}}>
                <CTableHead color="dark" >
                  <CTableRow color="dark">                               
                    <CTableHeaderCell >Quantidade Inscritos</CTableHeaderCell>                
                    <CTableHeaderCell >Fizeram o Curso</CTableHeaderCell>
                    <CTableHeaderCell >Fizeram e-inscrição</CTableHeaderCell>
                    <CTableHeaderCell >Participaram Reunião</CTableHeaderCell>                
                                 
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                <CTableRow color="success" style={{color: "green"}}>                     
                      
                                          
                  <CTableDataCell>{total}</CTableDataCell>                      
                  <CTableDataCell>{curso}</CTableDataCell>
                  <CTableDataCell>{inscricao}</CTableDataCell>
                  <CTableDataCell>{reuniao}</CTableDataCell>
                                          
                </CTableRow>
              
                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>    
            
              </CCardBody>
            </CCard> 
           </CCol >



           <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Relatório de Batismo Por Supervisores</strong> 
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{fontFamily: "Arial"}}>
                <CTableHead color="dark" >
                  <CTableRow color="dark">  
                    <CTableHeaderCell >Supervisores</CTableHeaderCell>                               
                    <CTableHeaderCell >Quantidade Inscritos</CTableHeaderCell>                
                    <CTableHeaderCell >Fizeram o Curso</CTableHeaderCell>
                    <CTableHeaderCell >Fizeram e-inscrição</CTableHeaderCell>
                    <CTableHeaderCell >Participaram Reunião</CTableHeaderCell>                
                                 
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                 {/* Totals row */}
                  {Object.keys(supervisorTotals).map((supervisor, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success" style={{color: "green"}}> 
                      <CTableDataCell >{supervisor}</CTableDataCell>
                      <CTableDataCell>{supervisorTotals[supervisor].supcount}</CTableDataCell>
                      <CTableDataCell>{supervisorTotals[supervisor].cursoCount}</CTableDataCell>
                      <CTableDataCell>{supervisorTotals[supervisor].inscricaoCount}</CTableDataCell>
                      <CTableDataCell>{supervisorTotals[supervisor].reuniaoCount}</CTableDataCell>
                    </CTableRow>
                  ))}
                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>    
            
              </CCardBody>
            </CCard> 
           </CCol >
         

                       </CRow>

        )
    }
}
