import React, { Component } from 'react'
import { GetGrupoMembrosDetails } from '../../../../services';
import swal from 'sweetalert';
import Moment from 'moment';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { NotificationManager } from 'react-notifications';

import { getCookie } from '../../../../../function';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CSpinner
  } from '@coreui/react'


  import { CChartLine } from '@coreui/react-chartjs'
  import { getStyle, hexToRgba } from '@coreui/utils'
  import CIcon from '@coreui/icons-react'
  import {
    cilPencil,cilTrash,cilSearch,cilPlus,cilSpreadsheet,
  } from '@coreui/icons'


  let id_lider = getCookie("id_lider");

export default class List extends Component {

  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

    constructor(props) {
        super(props);
        this.state = {
            getList: [],getList2:[],isLoaded:false
        }
    }
   
   async componentDidMount() {
      this.setState({ isLoaded: true })
      this.getGrupo();              
      this.setState({ isLoaded: false })
    }

    async getGrupo() {                    
      let list = await GetGrupoMembrosDetails.getAllGrupoMembrosList();  
      console.log(list)
      list = list.grupos.filter((item) => 
          item.id_lider ==id_lider ||           
          item.id_supervisor == id_lider ||
          item.id_setor == id_lider ||
          item.id_area == id_lider ||
          item.id_distrito == id_lider                                
          )      
          
      if (list) {
          this.setState({ getList: list, isLoaded: false })
      }        

      //this.getGrupoID(list.grupos[0].id);
      //this.setState({ id_celula: list.grupos[0].id })
    }

    async getGrupoID(id_grupo) {      
      let id = id_grupo          
      let list = await GetGrupoMembrosDetails.getAllGrupoMembrosListID(id);          
      if (list) {
          console.log('list')
          console.log(list)
          this.setState({ getList2: list.grupo_membros, isLoaded: false })
      }        
    }

    async handlDeleteById(id) {
      swal({
          title: "Você tem certeza?",
          text: "Você deseja deletar o usuário da lista",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
          .then(async (success) => {
              if (success) {
                  let value = await GetGrupoMembrosDetails.getDeleteGrupoMembros(id);
                  if (value) {
                      NotificationManager.success(value.msg, 'Status');
                      setTimeout(
                          async function () {
                              window.location.reload();
                          },
                          1000
                      );
                  }
              }
          });
  }

    
  handleAddNewUser(id_celula,descricao) {
      this.props.history.push({ pathname: `/admin/grupo_membros/create/`,id_celula:id_celula,descricao:descricao})
  }

  handlEditForm(id_membro,id_celula,nome) {
      this.props.history.push({ pathname: `/admin/pastoreio/create/`, id_membro:id_membro,id_celula:id_celula,nome:nome})
  }

  handleEditUser(row,id_ministerio,descricao){   
      this.props.history.push({ pathname: `/admin/grupo_membros/edit/${row}`,state: row,id_ministerio:id_ministerio,descricao:descricao})

  }


    render() {
        let self = this.state.getList
        let self2 = this.state.getList2
        let isLoaded = this.state.isLoaded
        return (
            
          <CRow>          
          <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Visualização Células sobre seus cuidados</strong> 
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border" hover responsive small bordered>
                <CTableHead color="dark">
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Célula</CTableHeaderCell>                                
                    <CTableHeaderCell >Líder</CTableHeaderCell>                                     
                    <CTableHeaderCell className="text-center">Listar Membros</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Novo Membro</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {self.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success">                     
                      <CTableDataCell>{item.descricao}</CTableDataCell>                      
                      <CTableDataCell>{item.user_lider ? item.user_lider.firstName  : '..'}</CTableDataCell>                        
                      <CTableDataCell className="text-center" onClick={(e) => this.getGrupoID(item.id)}>                        
                      <CIcon  icon={cilSearch} title='Listar Membros'/>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">                        
                      <CIcon icon={cilPlus} title='Criar Membro' onClick={(e) => this.handleAddNewUser(item.id,item.descricao) }/> 
                      </CTableDataCell>
                    </CTableRow>
                  ))}

                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>
              </CCardBody>
            </CCard>             
            </CCol >            
                 
          <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Visualização Membros Célula</strong> 
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border" hover responsive small bordered>
                <CTableHead color="dark">
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Nome</CTableHeaderCell>                
                    <CTableHeaderCell >Telefone</CTableHeaderCell>                                  
                    <CTableHeaderCell className="text-center">Dados Pastoreio</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Alterar Membro</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {self2.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success">                     
                      <CTableDataCell>{item.nome}</CTableDataCell>                      
                      <CTableDataCell>{item.telefone}</CTableDataCell>                      
                      <CTableDataCell className="text-center" onClick={(e) => this.handlEditForm(item.id,item.id_grupo,item.nome) }>                        
                      <CIcon  icon={cilSpreadsheet} title='Listar Membros'/>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">                        
                      <CIcon icon={cilPencil} title='Criar Membro' onClick={(e) => this.handleEditUser(item,item.ministerio.id,item.ministerio.descricao)} /> 
                      </CTableDataCell>
                    </CTableRow>
                  ))}

                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>
              </CCardBody>
            </CCard>             
            </CCol >            
            </CRow>

        )
    }
}
