import React, { Component } from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { GetEncontristaDetails } from '../../../../services';
import swal from 'sweetalert';
import { 
  CCard,
  CCardBody,
  CCardHeader, 
  CRow,
  CSpinner   
} from '@coreui/react';

class CSVReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvData: [],
      isLoaded: false,
    };
  }

  replaceNames = (data) => {
    return data.map((item) => {
      return {
        nome: item["Nome"] || item["nome"],  
        data_inscricao: item["Data da inscrição"] || item["data_inscricao"],
        nome_lider: item["Participa de célula? Colocar nome e telefone do líder:"] || item["nome_lider"]
      };
    });
  };

  handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      swal("Erro", "Por favor, selecione um arquivo.", "error");
      return;
    }

    if (file.type !== "text/csv") {
      swal("Erro", "O arquivo selecionado não é um arquivo CSV.", "error");
      return;
    }

    this.setState({ isLoaded: true });
        
     const reader = new FileReader();

     reader.onload = async (event) => {        
       const csvText = event.target.result;
       const jsonData = this.csvToJSON(csvText);
       this.setState({ csvData: jsonData });       
       let newjson = this.replaceNames(jsonData);
      
       for (const row of newjson) {
         await new Promise(resolve => {
           setTimeout(() => {
             const jsonString = JSON.stringify(row);
             this.handleSubmit(jsonString);
             //console.log('Delay 5');
             resolve();
           }, 1000); 
         });
       }

       this.setState({ isLoaded: false });
       swal("Sucesso", "Arquivo CSV carregado com sucesso.", "success");
     };

     reader.readAsText(file);
    //this.setState({ isLoaded: false });  
  };

  csvToJSON = (csvText) => {
    const lines = csvText.trim().split(/\r?\n/); 
    const headers = lines[0].split(';').map((header) => header.trim());
  
    const jsonData = [];
  
    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(';').map((cell) => cell.trim());
  
      if (data.length === headers.length) {
        const entry = {};
  
        for (let j = 0; j < headers.length; j++) {
          entry[headers[j]] = data[j];
        }
  
        jsonData.push(entry);
      }
    }
    return jsonData;
  };

  handleSubmit = async (data) => { 
    let user = await GetEncontristaDetails.getEncontristaRegister(data);  
    if (!user) {
      // Lógica adicional se necessário
    }
  }

  render() {    
    return (    
      <CRow>              
        <CCard className="mb-4">
          <CCardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              Carregar Arquivo .csv extraído do e-inscrição
            </div>           
            {this.state.isLoaded && <CSpinner color="primary" />}
          </CCardHeader>
          <CCardBody>            
            <input type="file" disabled={this.state.isLoaded}  encoding="UTF-8" onChange={this.handleFileChange} accept=".csv" style={{ marginBottom: '0' }} 
          
            />
          </CCardBody>
        </CCard>            
      </CRow>
    );
  }
}

export default CSVReader;
