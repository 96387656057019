import React, { useState, Component  } from 'react'
import AutoSelect from "../../../../common/autoselect";

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CSpinner
} from '@coreui/react'
import swal from 'sweetalert';

import { NotificationManager } from 'react-notifications';
import { withRouter } from "react-router";
import PropTypes from "prop-types";



import { GetGrupoDetails,GetTp_grupoDetails,GetTp_FerramentaDetails,GetfaixaetariaDetails } from '../../../../services';

const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
      data.map((item, key) => {
          arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
          return null;
      });
  }
  return arrayItem;
};

export default class Create extends Component {

  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

  constructor(props) {
      super(props);
      let userdata = this.props.location.state;
      this.state = {            
        id:userdata.id,descricao:userdata.descricao,status: userdata.status,getList: [],getList2: [] ,getList3: [],
        getList4: [],getList5: [],tp_grupo:userdata.tp_grupo,tp_ferramenta:userdata.tp_ferramenta,lider:userdata.lider,
        supervisor:userdata.supervisor,setor:userdata.setor,area:userdata.area,distrito:userdata.distrito,rede:userdata.rede,
        dia_semana:userdata.dia_semana,faixa_etaria:userdata.faixa_etaria,isLoaded: false,info:userdata.info   
          
      }
  }

  async getAllLider() {
      
    let list = await GetGrupoDetails.getAllLider();       
    this.setState({ getList: list.user })       
    
    var list2 = list.user.map((elemento,indice) =>{
        let item={}
        item.firstName = elemento.firstName + ' - ' + elemento.cargo.descricao
        item.id = elemento.id
        return item
    })
    this.setState({getList2:list2})   

}

async getTp_Grupo() {
    let list = await GetTp_grupoDetails.getTP_GrupoList();
    this.setState({ getList3: list.data })
}

async getfaixaetaria() {
    let list = await GetfaixaetariaDetails.getfaixaetariaList();
    this.setState({ getList5: list.data })
}
async getTP_Ferramenta() {
    let list = await GetTp_FerramentaDetails.getTP_Ferramenta_List();
    this.setState({ getList4: list.data })
}

SelectChangeTP_Grupo = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        tp_grupo: selected,
    });
    this.setState({ changed: true });
};  

SelectChangefaixaetaria = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        faixa_etaria: selected,
    });
    this.setState({ changed: true });
};  

SelectChangeTP_Ferramenta = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        tp_ferramenta: selected,
    });
    this.setState({ changed: true });
};  


SelectChangeLider = (name, selected) => {        
        this.setState({
            list: {
                ...this.state.list,
                [name]: selected.value,
            },
            lider: selected,
        });
        this.setState({ changed: true });
};


SelectChangeSupervisor = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        supervisor: selected,
    });
    this.setState({ changed: true });
};

SelectChangeSetor = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        setor: selected,
    });
    this.setState({ changed: true });
};

SelectChangeArea = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        area: selected,
    });
    this.setState({ changed: true });
};


SelectChangeDistrito = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        distrito: selected,
    });
    this.setState({ changed: true });
};

SelectChangeRede = (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        rede: selected,
    });
    this.setState({ changed: true });
};

  handleChange(e) {
      this.setState({ [e.target.name]: e.target.value })           
  }
  
 
  async componentDidMount() {
    this.getAllLider();
    this.getTp_Grupo();
    this.getTP_Ferramenta();
    this.getfaixaetaria();
  }

  handleSubmit = async event => {
      event.preventDefault();  
   
      swal({
          title: "Deseja alterar o grupo ?",         
          icon: "info",
          buttons: true,
          dangerMode: false,
      })
          .then(async (success) => {
              if (success) {  
                
                
                  this.setState({ isLoaded: true })                                   
                  
                    let { id,descricao,status,tp_ferramenta,lider,supervisor,setor,area,distrito,rede,dia_semana,faixa_etaria,info } = this.state;
                    let data = { id:id,descricao:descricao,
                      status:status?.value || status,                      
                      id_tp_ferramenta:tp_ferramenta?.value || tp_ferramenta,
                      id_lider: lider?.value || lider,                 
                      id_supervisor:supervisor?.value ||  supervisor,
                      id_setor:setor?.value || setor,
                      id_area:area?.value || area,
                      id_distrito:distrito?.value || distrito,
                      id_rede:rede?.value || rede,
                      dia_semana:dia_semana?.value || dia_semana,
                      faixa_etaria:faixa_etaria?.value || faixa_etaria,
                      info:info}            
                    let user = await GetGrupoDetails.getUpdateGrupo(data);
                      if (user) {
                        this.setState({ isLoaded: false })
                        this.props.history.goBack();                                                    
                      } else {
                          NotificationManager.error("Check Input field!", 'Input');
                      }
          
                      }


                  this.setState({ isLoaded: false })
              
          });
  }



  
  render() {

      const {  getList2,getList3,getList4,getList5,isLoaded, descricao, status,tp_ferramenta,lider,supervisor,setor,area,distrito,rede,dia_semana,faixa_etaria,info } = this.state ;
      let self = this.state.getList
      let getList = this.state.getList
      return (


  <CRow>
  <CCol xs={12}>
  <CCard className="mb-4">
    <CCardHeader color="sucess">
          <strong>Alterar Células</strong> 
    </CCardHeader>
  <CCardBody>
         
  <CForm
    className="row g-3 needs-validation"
    Validate
    validated={this.validated}
    onSubmit={this.handleSubmit}
  >
    <CCol md={3}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Nome Grupo"
        required        
        value={descricao}   
        name="descricao"      
        onChange={(e) => this.handleChange(e)} 
      />
    </CCol>    
    <CCol md={1}>
    <CFormSelect label="Status" size="sm" className="mb-3" aria-label="Small select example" defaultValue={status} name="status" onChange={(e) => this.handleChange(e)}>      
      <option value="1">Presencial</option>
      <option value="2">Online</option>
      <option value="3">Presencial e Online</option>
      <option value="0">Inativa</option>    
    </CFormSelect>
    </CCol>

    <CCol md={2}>
    <CFormSelect label="Dia Semana" size="sm" className="mb-3" aria-label="Small select example" defaultValue={dia_semana} name="dia_semana" onChange={(e) => this.handleChange(e)}>      
      <option value="2">Segunda-feira</option>
      <option value="3">Terça-feira</option>
      <option value="4">Quarta-feira</option>
      <option value="5">Quinta-feira</option>
      <option value="6">Sexta-feira</option>
      <option value="7">Sábado</option>
      <option value="1">Domingo</option>
    </CFormSelect>
    </CCol>

    <CCol md={2}>
    <CFormLabel>Perfil*</CFormLabel> 
     <AutoSelect
        className="basic-single"     
        onChange={this.SelectChangefaixaetaria}
        //value={faixa_etaria}
        isSearchable={true}        
        name="faixa_etaria"
        label={this.props.location.faixa_etaria}
        options={Arrays(getList5, "descricao", "id")} 
      />       
    </CCol>                      

    {/* <CCol md={2}>
    <CFormLabel>Tipo Grupo*</CFormLabel> 
     <AutoSelect
        className="basic-single"     
        onChange={this.SelectChangeTP_Grupo}
        //value={tp_grupo}
        isSearchable={true}        
        name="id_tp_grupo"
        label={this.props.location.grupo}
        options={Arrays(getList3, "descricao", "id")} 
      />       
    </CCol>                                     */}
  
    <CCol md={2}>
    <CFormLabel>Tipo Ferramenta*</CFormLabel> 
     <AutoSelect
        className="basic-single"     
        onChange={this.SelectChangeTP_Ferramenta}
        //value={tp_ferramenta}
        isSearchable={true}                
        name="id_tp_ferramenta"
        label={this.props.location.ferramenta}
        options={Arrays(getList4, "descricao", "id")} 
      />       
    </CCol>     

    <CCol md={3}>
    <CFormLabel>Líder*</CFormLabel> 
     <AutoSelect
        className="basic-single"        
        onChange={this.SelectChangeLider}
        //value={lider}
        isSearchable={true}        
        name="id_lider"
        label={this.props.location.lider}
        options={Arrays(getList2, "firstName", "id")} 
      />       
    </CCol>     

    <CCol md={3}>
    <CFormLabel>Supervisor Direto*</CFormLabel> 
     <AutoSelect
        className="basic-single"
        //value={supervisor}
        onChange={this.SelectChangeSupervisor}
        isSearchable={true}        
        name="supervisor"
        label={this.props.location.supervisor}
        options={Arrays(getList2, "firstName", "id")} 
      />       
    </CCol>     

    <CCol md={3}>
    <CFormLabel>Setor*</CFormLabel> 
     <AutoSelect
        className="basic-single"
        //value={setor}
        onChange={this.SelectChangeSetor}
        isSearchable={true}        
        name="id_setor"
        label={this.props.location.setor}
        options={Arrays(getList2, "firstName", "id")} 
      />       
    </CCol>     

    <CCol md={3}>
    <CFormLabel>Área*</CFormLabel> 
     <AutoSelect
        className="basic-single"
        //value={area}
        onChange={this.SelectChangeArea}
        isSearchable={true}        
        name="id_area"
        label={this.props.location.area}
        options={Arrays(getList2, "firstName", "id")} 
      />       
    </CCol>     

    <CCol md={3}>
    <CFormLabel>Distrito*</CFormLabel> 
     <AutoSelect
        className="basic-single"
        //value={distrito}
        onChange={this.SelectChangeDistrito}
        isSearchable={true}        
        name="id_distrito"
        label={this.props.location.distrito}
        options={Arrays(getList2, "firstName", "id")} 
      />       
    </CCol>     

  
    

    <CCol xs={12}>
      <CButton color="success" type="submit">
        Confirmar
      </CButton>
    </CCol>
  </CForm>

  </CCardBody>
  </CCard> 
  </CCol>
    {
       isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
    }
  </CRow>
)
}

}
