import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const AddFormularioList = async (data,config) => {
    try {  
        let result = await api.post(Apis.AddFormularioList,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getUpdateFormulario = async (data,config) => {
    try {
        let result = await api.post(Apis.getUpdateFormulario,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getFormularioById = async (id_membro,id_celula) => {
    try {
        let result = await api.get(Apis.GetFormularioById,{params: {id_celula,id_membro}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getComprometimentosById = async (id_membro,id_grupo) => {
    try {
        let result = await api.get(Apis.GetComprometimentosById,{params: {id_grupo,id_membro}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getUpdateComprometimentos = async (data,config) => {
    try {
        let result = await api.post(Apis.GetUpadateComprometimentos,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default {
    AddFormularioList,
    getUpdateFormulario,  
    getFormularioById,
    getComprometimentosById,
    getUpdateComprometimentos,
};