import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const AddGrupoMembrosList = async (data,config) => {
    try {
        let result = await api.post(Apis.AddGrupoMembrosList,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getAllGrupoMembrosList = async (id_lider) => {
    try {
        let result = await api.get(Apis.getAllGrupoMembrosList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllGrupoMembrosListID = async (id) => {
    try {
        let result = await api.get(Apis.getAllGrupoMembrosListID,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllGrupoMembrosFormula = async (id) => {
    try {
        let result = await api.get(Apis.getAllGrupoMembrosFormula);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



const getAllLider = async () => {
    try {
        let result = await api.get(Apis.getAllLider);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getUpdateGrupoMembros = async (data,config) => {
    try {
        let result = await api.post(Apis.getUpdateGrupoMembros,data,config);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getDeleteGrupoMembros = async (id) => {
    try {
        let result = await api.delete(Apis.GetDeleteGrupoMembros,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getGrupoMembrosById = async (id) => {
    try {
        let result = await api.get(Apis.GetGrupoMembrosById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


export default {
    AddGrupoMembrosList,
    getAllGrupoMembrosList,
    getAllGrupoMembrosListID,
    getAllGrupoMembrosFormula,
    getAllLider,
    getUpdateGrupoMembros,
    getDeleteGrupoMembros,  
    getGrupoMembrosById,
};