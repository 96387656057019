import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getTp_Ferramenta = async (descricao) => {
    try {
        // data.date = moment().format();
        let data = {descricao: descricao}
        let result = await api.post(Apis.GetAllTp_Ferramenta_Create,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getTP_Ferramenta_List = async () => {
    try {
        let result = await api.get(Apis.GetAllTp_Ferramenta_List);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getTP_Ferramenta_DeleteById = async (id) => {
    try {
        let result = await api.delete(Apis.GetTp_Ferramenta_DeleteById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getTP_Ferramenta_Update = async (data) => {
    try {
        let result = await api.post(Apis.GetTp_Ferramenta_Update,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    getTp_Ferramenta,
    getTP_Ferramenta_List,
    getTP_Ferramenta_DeleteById,
    getTP_Ferramenta_Update,    
};