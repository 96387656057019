import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import { GetAgendaDetails,GetGrupoDetails } from '../../../../services';
import Loader from '../../../../loader';
import AutoSelect from "../../../../common/autoselect_a";
import { red } from '@mui/material/colors';
import Moment from 'moment';
import { getCookie } from '../../../../../function';
import Edit from '../edit';
import { purple } from '@mui/material/colors';
import { MuiThemeProvider, createTheme  } from "@material-ui/core/styles";

import {
  Scheduler,
  Toolbar,
  DayView,
  MonthView,
  WeekView,
  ViewSwitcher,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  Resources,
  EditRecurrenceMenu,
  AllDayPanel,
  TodayButton,
  DateNavigator,
  GroupingPanel  
} from '@devexpress/dx-react-scheduler-material-ui';


import { connectProps } from '@devexpress/dx-react-core';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import LocationOn from '@mui/icons-material/LocationOn';
import Notes from '@mui/icons-material/Notes';
import Close from '@mui/icons-material/Close';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Create from '@mui/icons-material/Create';
import GroupsIcon from '@mui/icons-material/Groups';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import PropTypes from "prop-types";
import MenuItem from '@mui/material/MenuItem';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import {  
  CCol, CFormCheck,CFormSelect,CAlert   
 
} from '@coreui/react'



const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
var vcolor = '#1dab2e';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

let id_lider = getCookie("id_lider");


const Appointment = ({
  children, style, ...restProps
}) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: vcolor,
      borderRadius: '8px',
    }}
  >
    {children}
  </Appointments.Appointment>
);

Appointment.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

let selectedGrupo= ''
let nomeGrupo= ''
let visita=0
const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
      data.map((item, key) => {
          arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
          return null;
      });
  }
  return arrayItem;
};



const PREFIX = 'Demo';
// #FOLD_BLOCK
const classes = {
  content: `${PREFIX}-content`,
  header: `${PREFIX}-header`,
  closeButton: `${PREFIX}-closeButton`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  button: `${PREFIX}-button`,
  picker: `${PREFIX}-picker`,
  wrapper: `${PREFIX}-wrapper`,
  icon: `${PREFIX}-icon`,
  textField: `${PREFIX}-textField`,
  addButton: `${PREFIX}-addButton`,
};

// #FOLD_BLOCK
const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${classes.icon}`]: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2),
  },
  [`& .${classes.header}`]: {
    overflow: 'hidden',
    paddingTop: theme.spacing(0.5),
  },
  [`& .${classes.textField}`]: {
    width: '100%',
  },
  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  [`& .${classes.closeButton}`]: {
    float: 'right',
  },
  [`& .${classes.picker}`]: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
    width: '50%',
  },
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),    
  },
  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
  },
  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(2),
  },
}));
const StyledFab = styled(Fab)(({ theme }) => ({
  [`&.${classes.addButton}`]: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
}));


class AppointmentFormContainerBasic extends React.PureComponent {
    
  constructor(props) {
    super(props);   
    this.state = {
      appointmentChanges: {},getList: this.getGrupo(),vi:0
    };
    
    this.getAppointmentData = () => {
      const { appointmentData } = this.props;    
      return appointmentData;
    };
    this.getAppointmentChanges = () => {
      const { appointmentChanges } = this.state;
        return appointmentChanges;
    };

    this.changeAppointment = this.changeAppointment.bind(this);
    this.commitAppointment = this.commitAppointment.bind(this);
  
  
  }

 
  async getGrupo() {
    let list = await GetGrupoDetails.getAllGrupoList();   
     
    list = list.grupos.filter((item) => 
    item.id_lider ==id_lider || 
    item.id_colider ==id_lider || 
    item.id_supervisor == id_lider ||
    item.id_setor == id_lider ||
    item.id_area == id_lider ||
    item.id_distrito == id_lider ||
    item.id_rede == id_lider                                
    )
    if (list) {
        this.setState({ getList: list})

    }
  }

  handleSubmit = async (title,location,id_celula,id_lider,startDate,endDate,note,id,vi) => 
  {        
   
    let data = { title: title,location: location,id_celula: id_celula,id_lider: id_lider,
                 startDate: Moment(startDate).format('YYYY-MM-DD HH:mm'),endDate: Moment(endDate).format('YYYY-MM-DD HH:mm'),note: note,id: id,visita: vi}
    let list = await GetAgendaDetails.AddAgendaList(data);
                if (list) {
                  window.location.reload()                 
                }
  }   
   
  handleUpdate = async (title,location,id_celula,id_lider,startDate,endDate,note,id,vi) => 
  {        
   
    let data = { title: title,location: location,id_celula: id_celula,id_lider: id_lider,
                 startDate: Moment(startDate).format('YYYY-MM-DD HH:mm'),endDate: Moment(endDate).format('YYYY-MM-DD HH:mm'),note: note,id: id,visita: vi}
    let list = await GetAgendaDetails.getAgendaUpdate(data);
                if (list) {
                  window.location.reload()  
                }
  }   
  handleDelete = async (id) => 
  {        
    let list = await GetAgendaDetails.getDeleteAgenda(id);
                if (list) {
                    //this.setState({ getList: list.data })                    
                }
  }   
  
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })      
  }

  changeAppointment({ field, changes }) {
    const nextChanges = {
      ...this.getAppointmentChanges(),
      [field]: changes,
    };
    this.setState({
      appointmentChanges: nextChanges,
    });
  }

  commitAppointment(type) {

    const { commitChanges } = this.props;
    const appointment = {
      ...this.getAppointmentData(),
      ...this.getAppointmentChanges(),
    };  

    if (type === 'deleted') {
      commitChanges({ [type]: appointment.id });
      this.handleDelete(appointment.id)
    } else if (type === 'changed') {
      // if (Moment(appointment.startDate).format('MM/DD/YYYY') < Moment(new Date()).format('MM/DD/YYYY')) 
      // {
      //   alert('Você não pode alterar uma agenda vencida.')
      // }
      // else 
      // {
        commitChanges({ [type]: { [appointment.id]: appointment } });     
        this.handleUpdate(appointment.title,nomeGrupo,selectedGrupo,id_lider,appointment.startDate,appointment.endDate,appointment.note,appointment.id,appointment?.status ?? 0)
        
      // }
    } else {       
      
      // if (Moment(appointment.startDate).format('MM/DD/YYYY') < Moment(new Date()).format('MM/DD/YYYY'))
      //  {
      //   alert('Você não pode marcar uma agenda para uma data passada.')          
      //  } 
      //  else 
      //  {
        commitChanges({ [type]: appointment });  
        this.handleSubmit(appointment.title,nomeGrupo,selectedGrupo,id_lider,appointment.startDate,appointment.endDate,appointment.note,0,appointment?.status ?? 0)
        
      // }
    }

    this.setState({
      appointmentChanges: {},
    });      
  }


  render() {
    
    const {
      visible,
      visibleChange,
      appointmentData,
      cancelAppointment,
      target,
      onHide      
    } = this.props;

    const { appointmentChanges,getList,vi} = this.state;

    const displayAppointmentData = {
      ...appointmentData,
      ...appointmentChanges,
    
    };

    const isNewAppointment = appointmentData.id === undefined;
    let newvisita = appointmentData.status
    visita=newvisita

      const applyChanges =  isNewAppointment
        ? () => this.commitAppointment('added')
        : () => this.commitAppointment('changed');
    
  
    const textEditorProps = field => ({
      variant: 'outlined',
      onChange: ({ target: change }) => this.changeAppointment({
        field: [field], changes: change.value,
      }),
      value: displayAppointmentData[field] || '',
      label: field[0].toUpperCase() + field.slice(1),
      className: classes.textField,     
      fontsize:'14',       
    });


    const handleChangenew = () => {      
       if (newvisita==1) {
        newvisita=0
      }
      else {
        newvisita=1        
      }                 
      visita=newvisita        
      appointmentData.status=newvisita
    }
    
    
    const pickerEditorProps = field => ({
      // keyboard: true,
      value: displayAppointmentData[field],
      onChange: date => this.changeAppointment({
        field: [field], changes: date ? date.toDate() : new Date(displayAppointmentData[field]),
      }),
      ampm: false,      
      inputFormat: 'DD/MM/YYYY HH:mm',
      onError: () => null,
    });
    const startDatePickerProps = pickerEditorProps('startDate');
    const endDatePickerProps = pickerEditorProps('endDate');
    const cancelChanges = () => {
      this.setState({
        appointmentChanges: {},
      });
      visibleChange();
      cancelAppointment();      
    };

    const handleSelectedGrupo = async (name, selected) => {        
      this.setState({
          list: {
              ...this.state.list,
              [name]: selected.value,
          },
          selectedGrupo: selected,
      });
      this.setState({ changed: true });  
      selectedGrupo = selected.value
      nomeGrupo = selected.label
     }

    
   
    return (
      <AppointmentForm.Overlay
        visible={visible}
        target={target}
        fullSize={false}
        onHide={onHide}        
      >
        <StyledDiv>
          <div className={classes.header}>
            <IconButton className={classes.closeButton} onClick={cancelChanges} size="large">
              <Close color="action" />
            </IconButton>
          </div>
          <div className={classes.content}>
            <div className={classes.wrapper}   >
              <Create className={classes.icon} color="action" />
              <TextField
                {...textEditorProps('title')}
                enabled={true}
                label="Título" // Altere o rótulo para "Título"
                title="Título" // Altere o título para "Título"        
                      
              />
            </div>           

            <div className={classes.wrapper}  >
              <CalendarToday className={classes.icon} color="action" />
               <LocalizationProvider dateAdapter={AdapterMoment} > 
             
                <DateTimePicker                  
                  label="Início"
                  renderInput={
                    props => <TextField className={classes.picker} {...props} />
                  }
                  {...startDatePickerProps}
                  
                />
                <DateTimePicker
                  
                  label="Fim"
                  renderInput ={
                    props => <TextField className={classes.picker} {...props} />
                  }
                  {...endDatePickerProps}
                />
              </LocalizationProvider>
          
              </div>
           

            <div className={classes.wrapper} style={{ display: 'none'}}>
            
              <LocationOn className={classes.icon} color="action"/>
              <TextField
                {...textEditorProps('location')}              
                
              />
            
            </div>                     
          

            <div className={classes.wrapper}>                       
            <GroupsIcon className={classes.icon} color="action" />
            <CCol xs={11} style={{ flexDirection: 'row', marginRight: '2%' }} >           
            
                                <AutoSelect
                                    //value={selectedGrupo}
                                    //label="Escolha a Célula..."
                                    //label={selectedGrupo}
                                    label= {!isNewAppointment ? displayAppointmentData['location'] : 'Selecione uma célula ...'}
                                    className="basic-single"                                    
                                    onChange={handleSelectedGrupo}
                                    isSearchable={true}
                                    name="id_selectedGrupo"
                                    options={Arrays(getList, "descricao", "id")}          
                                    disabled={!isNewAppointment}
                                />
            </CCol>            
            </div> 
            
            <div className={classes.wrapper} >

              <EventAvailableIcon className={classes.icon} color="action" /> 
              <CCol style={{ flexDirection: 'row', marginRight: '1%',marginTop:"2%" }} >                         
              
              {newvisita==1 &&
              <CFormSelect label="A Visita a célula aconteceu ?" size="sm" defaultValue='Sim' className="mb-3" aria-label="Small select example" name="visita" onChange={(e) => handleChangenew()}>      
                 <option value="1">Sim</option>
                 <option value="0">Não</option>
              </CFormSelect>
              }
              {!newvisita &&
              <CFormSelect label="A Visita a célula aconteceu ?" size="sm" defaultValue='Não' className="mb-3" aria-label="Small select example" name="visita" onChange={(e) => handleChangenew()}>      
                 <option value="0">Não</option>      
                 <option value="1">Sim</option>
              </CFormSelect>
              }
              </CCol> 
            </div>   
           
            <div className={classes.wrapper}>
              <Notes className={classes.icon} color="action" />            
              <TextField
                {...textEditorProps('note')}
                multiline
                rows="2"    
                label="Insira sua observação ..."                           
              />
            </div>  
           
          </div>             
          <div className={classes.buttonGroup}>
          {!isNewAppointment && (
              <Button
                variant="outlined"
                color="success"
                className={classes.button}
                onClick={() => {
                  visibleChange();
                  this.commitAppointment('deleted');
                }}
              >
               Delete 
              </Button>
              
            )}
            <Button
              variant="outlined"
              color="success"
              className={classes.button}
              onClick={() => {
                visibleChange();
                applyChanges(vi);
              }}
            >
              {isNewAppointment ? 'Criar' : 'Salvar'}
            </Button>
            
          </div>
        </StyledDiv>
      </AppointmentForm.Overlay>
    );
  }
}

AppointmentFormContainerBasic.propTypes = {
  visible: PropTypes.bool.isRequired,
  visibleChange: PropTypes.func.isRequired,
  appointmentData: PropTypes.any.isRequired,
  cancelAppointment: PropTypes.func.isRequired,
  target: PropTypes.any.isRequired,
  onHide: PropTypes.func.isRequired,
  commitChanges: PropTypes.any.isRequired,  
};

/* eslint-disable-next-line react/no-multi-comp */
export default class Demo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      getList:[],
      currentDate: new Date(),
      confirmationVisible: false,
      editingFormVisible: false,
      deletedAppointmentId: undefined,
      editingAppointment: undefined,
      previousAppointment: undefined,
      addedAppointment: {},
      startDayHour: 8,
      endDayHour: 22,
      isNewAppointment: false,
      resources: this.getList,
      grouping:this.getList,
      locale:""
    }

    this.currentDateChange = (currentDate) => {
      this.setState({ currentDate });
    };

    
    this.toggleConfirmationVisible = this.toggleConfirmationVisible.bind(this);
    this.commitDeletedAppointment = this.commitDeletedAppointment.bind(this);
    this.toggleEditingFormVisibility = this.toggleEditingFormVisibility.bind(this);

    this.commitChanges = this.commitChanges.bind(this);
    this.onEditingAppointmentChange = this.onEditingAppointmentChange.bind(this);
    this.onAddedAppointmentChange = this.onAddedAppointmentChange.bind(this);
    this.appointmentForm = connectProps(AppointmentFormContainerBasic, () => {
      const {
        editingFormVisible,
        editingAppointment,
        data,
        addedAppointment,
        isNewAppointment,
        previousAppointment,
      } = this.state;

      const currentAppointment = data
        .filter(appointment => editingAppointment && appointment.id === editingAppointment.id)[0]
        || addedAppointment;
      const cancelAppointment = () => {
        if (isNewAppointment) {
          this.setState({
            editingAppointment: previousAppointment,
            isNewAppointment: false,
          });
        }
      };

      return {
        visible: editingFormVisible,
        appointmentData: currentAppointment,
        commitChanges: this.commitChanges,
        visibleChange: this.toggleEditingFormVisibility,
        onEditingAppointmentChange: this.onEditingAppointmentChange,
        cancelAppointment,
      };
    });
  }


  async componentDidMount() {
    this.getAgenda();            
    this.getGrupo();             
 }  
   
 async getAgenda() {                    
   let list = await GetAgendaDetails.getAllAgendaList();  

   list = list.agenda.filter((item) => 
   item.grupo.id_lider ==id_lider || 
   item.grupo.id_colider ==id_lider || 
   item.grupo.id_supervisor == id_lider ||
   item.grupo.id_setor == id_lider ||
   item.grupo.id_area == id_lider ||
   item.grupo.id_distrito == id_lider ||
   item.grupo.id_rede == id_lider                                             
   )
 
   if (list) {

    list = list.map(item => {
      item.startDate = item.startDate.replace("Z", "");
      item.endDate = item.endDate.replace("Z", "");
      return item;
   });
       
       this.setState({ data: list, isLoaded: false })
   }          
  }

   
  async getGrupo() {
    let list = await GetGrupoDetails.getAllGrupoList();     
    let lider=list.grupos.user_lider
    if (list) {
        this.setState({   resources : [{
            fieldName: 'id_celula',
            title: 'Célula',
            instances: list.grupos
          },
        
        ]})
    }
  
  }

  componentDidUpdate() {
    this.appointmentForm.update();    
  }

  onEditingAppointmentChange(editingAppointment) {   
    this.setState({ editingAppointment });
  }

  onAddedAppointmentChange(addedAppointment) {
    this.setState({ addedAppointment });
    const { editingAppointment } = this.state;
    if (editingAppointment !== undefined) {
      this.setState({
        previousAppointment: editingAppointment,
      });
    }
    this.setState({ editingAppointment: undefined, isNewAppointment: true });
  }

  setDeletedAppointmentId(id) {
    this.setState({ deletedAppointmentId: id });
  }

  toggleEditingFormVisibility() {
    const { editingFormVisible } = this.state;
    this.setState({
      editingFormVisible: !editingFormVisible,
    });
    // Aqui everson
    visita=0   
  }

    toggleConfirmationVisible() {    
    const { confirmationVisible } = this.state;
    this.setState({ confirmationVisible: !confirmationVisible });
  }

  handleDelete = async (id) => 
  {        
 
    let list = await GetAgendaDetails.getDeleteAgenda(id);
                if (list) {
                    //this.setState({ getList: list.data })                    
                }
  } 
  commitDeletedAppointment() {
    
    this.setState((state) => {
      const { data, deletedAppointmentId } = state;  
      const nextData = data.filter(appointment => appointment.id !== deletedAppointmentId);      
      this.handleDelete(deletedAppointmentId)
      
      return { data: nextData, deletedAppointmentId: null };
    });
    this.toggleConfirmationVisible();
  }

   commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
      }
      if (changed) {
        data = data.map(appointment => (
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
      }
      if (deleted !== undefined) {
        this.setDeletedAppointmentId(deleted);
        this.toggleConfirmationVisible();
      }
      return { data, addedAppointment: {} };
    });
  }

  render() {
    const {
      currentDate,
      data,
      confirmationVisible,
      editingFormVisible,
      startDayHour,
      endDayHour,
      resources
    } = this.state;

    return (
      <MuiThemeProvider theme={theme}> 
   
      <Paper>
        <Scheduler
          data={data}
          height={660}
          whidth={400}
          locale='pt-BR'
          timeZone={'UTC'}
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={this.currentDateChange}
          />
          <EditingState
            onCommitChanges={this.commitChanges}
            onEditingAppointmentChange={this.onEditingAppointmentChange}
            onAddedAppointmentChange={this.onAddedAppointmentChange}
          />
         

          <DayView
            startDayHour={startDayHour}
            endDayHour={endDayHour}
            displayName="Dia"                    
           
          />
          
          <WeekView
            startDayHour={startDayHour}
            endDayHour={endDayHour}
            displayName="Semana"
          />

          <MonthView 
          displayName="Mês"      
          />

          <AllDayPanel x="Dia Todo" />

          <EditRecurrenceMenu />
          
          <Appointments 
            //  vcolor='#618833'
             appointmentComponent={Appointment}
             locale='pt-BR'
          />
          <Resources
            data={resources}
            mainResourceName="id_celula" 
            locale='pt-BR'    
          />
        
          <AppointmentTooltip            
            showOpenButton
            showCloseButton
            showDeleteButton            
          />
          <Toolbar />
          <DateNavigator/>
          <TodayButton />
          <ViewSwitcher />
          <AppointmentForm
            overlayComponent={this.appointmentForm}
            visible={editingFormVisible}
            height={'200'}
            whidth={'50'}
            onVisibilityChange={this.toggleEditingFormVisibility}
            locale='pt-BR'
          />
                
        </Scheduler>

        <Dialog
          open={confirmationVisible}
          onClose={this.cancelDelete}
        >
          <DialogTitle>
            Deletar Reunião
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você deseja realmente deletar está reunião?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleConfirmationVisible} color="primary" variant="outlined">
              Cancelar
            </Button>
            <Button onClick={this.commitDeletedAppointment} color="secondary" variant="outlined">
              Deletar
            </Button>
          </DialogActions>
        </Dialog>

              
      </Paper>

      </MuiThemeProvider>
     
    );
  }
}
