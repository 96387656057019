import React, { Component } from 'react'


import { GetTp_FerramentaDetails } from '../../../../services';
import swal from 'sweetalert';
import Moment from 'moment';

import { withRouter } from "react-router";
import PropTypes from "prop-types";

import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CSpinner
  } from '@coreui/react'


  import { CChartLine } from '@coreui/react-chartjs'
  import { getStyle, hexToRgba } from '@coreui/utils'
  import CIcon from '@coreui/icons-react'
  import {
    cilPencil,cilTrash,
  } from '@coreui/icons'

export default class List extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
  };

    constructor(props) {
        super(props);
        this.state = {
            getList: [],isLoaded:false
        }
    }
   
   async componentDidMount() {
    this.getTP_Ferramenta();
    }

    async getTP_Ferramenta() {
        this.setState({ isLoaded: true })
        let list = await GetTp_FerramentaDetails.getTP_Ferramenta_List();
        if (list) {        
        this.setState({ getList: list.data })
        this.setState({ isLoaded: false })
        }
        else{
          this.props.history.push({ pathname: `/auth/login`})           
        }
    }


    async handlDeleteById(id,cargo) {
        swal({
            title: "Excluir o tipo ferramenta " + cargo + " ?",     
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    this.setState({ isLoaded: true })
                    let value = await GetTp_FerramentaDetails.getTP_Ferramenta_DeleteById(id);
                    if (value) {
                      this.getTP_Ferramenta();
                    }
                    this.setState({ isLoaded: false })
                }
            });
    }

    
    handlEditRow(row,id,descricao) {      
      this.props.history.push({ pathname: `/admin/tp_ferramenta/edit/${row}`,state: row,descricao: descricao,id: id})
    }


    render() {
        let self = this.state.getList
        let isLoaded = this.state.isLoaded
        return (
            
          <CRow>          
          <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Visualização de Tipo de Ferramenta</strong> 
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border" hover responsive small bordered>
                <CTableHead color="dark">
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Descrição</CTableHeaderCell>                
                    <CTableHeaderCell className="text-center">Alterar</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Deletar</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {self.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success">                     
                      <CTableDataCell>{item.descricao}</CTableDataCell>                      
                      <CTableDataCell className="text-center" onClick={(e) => this.handlEditRow(item,item.id,item.descricao)}>                        
                      <CIcon  icon={cilPencil} title='Alterar'/>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">                        
                      <CIcon icon={cilTrash} title='Deletar' onClick={(e) => this.handlDeleteById(item.id,item.descricao)}/> 
                      </CTableDataCell>
                    </CTableRow>
                  ))}

                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>
              
              <CButton style={{ flexDirection: 'row', marginTop: 10 }} color="success" width={80} onClick={(e) => this.props.history.push({ pathname: `/admin/tp_ferramenta/create`})}> Novo Tipo Ferramenta</CButton>                          
              
              </CCardBody>
            </CCard> 
            {
                isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
            }
            
            </CCol >
            
            </CRow>

        )
    }
}
