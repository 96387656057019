import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getMinisterio = async (descricao) => {
    try {       
        let data = {descricao: descricao}
        let result = await api.post(Apis.AddMinisterioCreate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getMinisterioList = async () => {
    try {
        let result = await api.get(Apis.GetAllMinisterioList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getMinisterioListID = async () => {
    try {
        let result = await api.get(Apis.GetMinisterioById);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getMinisterioDeleteById = async (id) => {
    try {
        let result = await api.delete(Apis.GetMinisterioDeleteById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getMinisterioUpdate = async (data) => {
    try {
        let result = await api.post(Apis.GetMinisterioUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    getMinisterio,
    getMinisterioList,
    getMinisterioDeleteById,
    getMinisterioUpdate,   
    getMinisterioListID, 
};