import React,{ Component  }  from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CCardGroup,
  CSpinner ,
  CCardImage ,
  CCardTitle,
  CCardFooter,
  CFormLabel,
  CFormSelect
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser,cilAt,cilScreenSmartphone,cilSmile, cilClock,cilHome,cilPeople,cilHeart,cilDeaf,cilGroup} from '@coreui/icons'


import { GetBatismoDetails } from '../../../../services';
import swal from 'sweetalert';

import { NotificationManager } from 'react-notifications';
import PropTypes from "prop-types";

import { getCookie } from '../../../../../function';
let role = getCookie("role");

export default class Create extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

  constructor(props) {
      super(props);
      let userdata = this.props.location.state;
      this.state = {            
        getList:[],isLoaded: false, id: userdata.id,nome: userdata.nome, email: userdata.email, est_c: userdata.est_c, 
        idade: userdata.idade,telefone:userdata.telefone,celula: userdata.celula,lider: userdata.lider,
        supervisores: userdata.supervisores, ne: userdata.ne, turma: userdata.turma,curso: userdata.curso,inscricao: userdata.inscricao,reuniao: userdata.reuniao
          
      }
  }

  
  handleChange(e) {
      this.setState({ [e.target.name]: e.target.value })      
  }
  
  async componentDidMount() {      
    
  }



  handleSubmit = async event => {
      event.preventDefault();     
      swal({
          title: "Deseja alterar batizando?",         
          icon: "info",
          buttons: true,
          //confirmBtnBsStyle:"success",
          dangerMode: false,          
      })
          .then(async (success) => {
              if (success) { 
                  this.setState({ isLoaded: true })                                   
             
                      let {  id, nome, email, est_c, idade,telefone,celula,lider,supervisores, ne, turma, curso,inscricao,reuniao } = this.state; 
                 
                      let data = { id: id, nome: nome,  email: email, est_c: est_c,idade: idade, telefone: telefone, celula:celula,lider:lider,
                        supervisores:supervisores,ne:ne,turma:turma, curso: curso,inscricao: inscricao,reuniao: reuniao}
                      // make API call
                      let user = await GetBatismoDetails.getBatismoUpdate(data);
                      if (user) {                         

                             swal({
                                title: "Registro atualizado com sucesso?",         
                                icon: "info",
                                //buttons: true,
                                confirmBtnBsStyle:"success",
                                dangerMode: false,          
                            })
                            
                            //window.location.reload();                                                                             
                            this.props.history.goBack()
                      } else {
                        swal({
                          title: "Problemas na atualização?",         
                          icon: "info",
                          //buttons: true,
                          confirmBtnBsStyle:"success",
                          dangerMode: false,          
                      })
                      }
          
                  


                  this.setState({ isLoaded: false })
              }
          });
  }



  
  render() {

      const {  nome, email, est_c, idade,telefone,celula,lider,supervisores, ne, turma, curso,inscricao,reuniao} = this.state ;
      let isLoaded = this.state.isLoaded
      let self = this.state.getList
      let getList = this.state.getList
   return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-top">
      <CContainer style={{ marginTop: '1%'}}>
        <CRow className="justify-content-center">
          <CCol md={8} lg={12} xl={8}>
          <CCardGroup>          
            <CCard className="p-4">
            <CCardTitle color="success" className="text-center"><h3>Editar Batizando</h3></CCardTitle>
              <CCardBody className="p-4">
                <CForm
                  className="row g-3 needs-validation"
                  Validate
                  validated={this.validated}
                  onSubmit={this.handleSubmit}                
                   >            

                                       
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput name="nome" placeholder="Nome completo" value={nome} required onChange={(e) => this.handleChange(e)}/>
                  </CInputGroup>           

                  <CInputGroup className="mb-3">
                    <CInputGroupText><CIcon icon={cilAt} /></CInputGroupText>
                    <CFormInput name="email"  placeholder="Email" autoComplete="email" value={email}  required onChange={(e) => this.handleChange(e)}/>
                  </CInputGroup>
                   
                  <CInputGroup className="mb-3">  
                  <CInputGroupText>
                      <CIcon icon={cilSmile} />
                    </CInputGroupText>                
                            <CFormSelect size="sm" defaultValue={est_c} className="mb-" aria-label="Small select example" name="est_c" onChange={(e) => this.handleChange(e)}>      
                                <option value={1} disabled>Estado Civil*</option>
                                <option value={"Solteiro"}>Solteiro (a)</option>   
                                <option value={"Casado (a)"}>Casado (a)</option>
                                <option value={"Separado (a)"}>Separado (a)</option> 
                                <option value={"Divorciado (a)"}>Divorciado (a)</option>
                                <option value={"Viúvo (a)"}>Viúvo (a)</option>      
                                <option value={"União Estável"}>União Estável</option> 
                                <option value={"Moro com meu(minha) namorado(a)"}>Moro com meu(minha) namorado(a)</option>
                                <option value={"Tenho um relacionamento marital, mas não moro com meu namorado(a)"}>Tenho um relacionamento marital, mas não moro com meu namorado(a)</option>                       
                  </CFormSelect>
                  </CInputGroup>

                  <CInputGroup className="mb-3">
                    <CInputGroupText><CIcon icon={cilClock} /></CInputGroupText>                
                    <CFormInput name="idade" placeholder="Idade (apenas número)" value={idade} required onChange={(e) => this.handleChange(e)} />                    
                  </CInputGroup>  

                  

                  <CInputGroup className="mb-3">
                    <CInputGroupText><CIcon icon={cilScreenSmartphone} /></CInputGroupText>                
                    <CFormInput name="telefone" placeholder="Telefone (WhatsApp) com DDD*" value={telefone} autoComplete="phone" required onChange={(e) => this.handleChange(e)} />                    
                  </CInputGroup>  
                  
                  
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilHome} />
                    </CInputGroupText>                
                    <CFormInput
                      type="text"
                      name="celula"
                      placeholder="Nome da sua Célula "                      
                      required
                      onChange={(e) => this.handleChange(e)}
                      value={celula}
                    />                    
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilPeople} />
                    </CInputGroupText>
                    <CFormInput
                      type="text"
                      name="lider" 
                      placeholder="Nome do Líder de Célula"                      
                      required
                      onChange={(e) => this.handleChange(e)}
                      value={lider}
                    />
                  </CInputGroup>  
                  {/* {
                    alert(supervisores)
                  } */}
                  
                  <CInputGroup className="mb-3">  
                  <CInputGroupText>                 
                  <strong><CFormLabel >&nbsp;Supervisores</CFormLabel>  </strong>                       
                    </CInputGroupText>                
                            <CFormSelect size="sm" defaultValue={supervisores} className="mb-" aria-label="Small select example" name="supervisores" onChange={(e) => this.handleChange(e)}>      
                                 <option value={1} >Selecione seus Supervisores</option>
                                <option value={"André & Fernanda"}>André & Fernanda</option>
                                <option value={"Anderson & Laura"}>Anderson & Laura</option>
                                <option value={"Junior & Paula"}>Junior & Paula</option> 
                                <option value={"Marcelo & Gleyce"}>Marcelo & Gleyce</option>
                  </CFormSelect>
                  </CInputGroup>   

                  <CInputGroup className="mb-3">  
                  <CInputGroupText>                     
                      <strong><CFormLabel >É Surdo?</CFormLabel>  </strong> 
                      
                    </CInputGroupText>                
                            <CFormSelect size="sm" defaultValue={ne} className="mb-" aria-label="Small select example" name="ne" onChange={(e) => this.handleChange(e)}>      
                                <option value={3} disabled>Possui alguma necessidade específica? É Surdo? </option>
                                <option value={'Não'}>Não</option>  
                                <option value={'Sim'}>Sim</option>                                 
                  </CFormSelect>
                  </CInputGroup>  

                  <CInputGroup className="mb-3">  
                  <CInputGroupText>
                  <strong><CFormLabel >Turma ? &nbsp;Inicio às 20:30h.</CFormLabel>  </strong> 
                    </CInputGroupText>                
                            <CFormSelect size="sm" defaultValue={turma} className="mb-" aria-label="Small select example" name="turma" onChange={(e) => this.handleChange(e)}>      
                                <option value={1} disabled>Qual turma você participará ? Inicio às 20:30h.</option>
                                <option value={"Turma 1 - Segunda-feira (18 anos em diante)"}>Turma 1 - Segunda-feira (18 anos em diante)</option>
                                <option value={"Turma 2 - Quarta-feira  (18 anos em diante)"}>Turma 2 - Quarta-feira  (18 anos em diante)</option>   
                                <option value={"Turma 3 - Quinta-feira  (de 13 a 17 anos - Base)"}>Turma 3 - Quinta-feira (de 13 a 17 anos - Base)</option>  
                                <option value={"Turma 4 - Sexta-feira   (Surdos)"}>Turma 4 - Sexta-feira (Surdos)</option>  
                                <option value={"Batismo Vida Vitoriosa"}>Batismo Vida Vitoriosa (Março/2024) </option>


                  </CFormSelect>
                  </CInputGroup>                


                  <CInputGroup className="mb-3">  
                  <CInputGroupText>                     
                      <strong><CFormLabel >Fez o curso ?</CFormLabel>  </strong> 
                      
                    </CInputGroupText>                
                            <CFormSelect size="sm" defaultValue={curso} className="mb-" aria-label="Small select example" name="curso" onChange={(e) => this.handleChange(e)}>                                     
                                <option value={'Não'}>Não</option>  
                                <option value={'Sim'}>Sim</option>                                 
                  </CFormSelect>
                  </CInputGroup>  

                  <CInputGroup className="mb-3">  
                  <CInputGroupText>                     
                      <strong><CFormLabel >Fez o e-inscrição ?</CFormLabel>  </strong> 
                      
                    </CInputGroupText>                
                            <CFormSelect size="sm" defaultValue={inscricao} className="mb-" aria-label="Small select example" name="inscricao" onChange={(e) => this.handleChange(e)}>                                     
                                <option value={'Não'}>Não</option>  
                                <option value={'Sim'}>Sim</option>                                 
                  </CFormSelect>
                  </CInputGroup>  

                  <CInputGroup className="mb-3">  
                  <CInputGroupText>                     
                      <strong><CFormLabel >Fez reunião com o pastor de Rede ?</CFormLabel>  </strong> 
                      
                    </CInputGroupText>                
                            <CFormSelect size="sm" defaultValue={reuniao} className="mb-" aria-label="Small select example" name="reuniao" onChange={(e) => this.handleChange(e)}>                                     
                                <option value={'Não'}>Não</option>  
                                <option value={'Sim'}>Sim</option>                                 
                  </CFormSelect>
                  </CInputGroup>  
                  
                  <CCardFooter>
                   {role === 'admin' && 
                  <CButton style={{ flexDirection: 'row', marginTop: 10,width:"30%" }} color="success"  type="submit">Enviar</CButton> 
                   }
                  <CButton style={{ flexDirection: 'row', marginTop: 10,marginLeft:"40%",width:"30%" }} color="success" onClick={(e) =>this.props.history.goBack()}>Voltar</CButton> 
                  {/* </div> */}
                  </CCardFooter>             
                </CForm>            
              </CCardBody>
              
            </CCard>
            </CCardGroup>  
            {
              isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
            }
          </CCol>          
        </CRow>
      </CContainer>
    </div>
)
}

}


