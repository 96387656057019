import React, { Component } from 'react'
import {
    Typography, Button
} from "@material-ui/core";
import { GetGrupoMembrosDetails } from '../../../../services';
import { NotificationManager } from 'react-notifications';
import Loader from '../../../../loader';
import swal from 'sweetalert';

import PropTypes from "prop-types";

import Moment from 'moment';

import {
    CAvatar,
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
  } from '@coreui/react'


let tipo =''
let desc =''

export default class View extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
      };

    constructor(props) {
        super(props);
        this.state = {
            getList: [],getList2: [], isLoaded: false   
        }
    }
    handleBack() {
        this.props.history.goBack();
    }

    

    async componentDidMount() {
        this.setState({ isLoaded: true })
        this.getGrupoID();              
        this.setState({ isLoaded: false })               
    }
    
    async getGrupoID() {                   

        
        let list = await GetGrupoMembrosDetails.getAllGrupoMembrosFormula();   
        if (list) {            
            
            tipo =window.location.href.split('/').pop() || window.location.href.split('/').pop();
            if (tipo == 'Batismo') {
                this.setState({getList2: list.formularios.filter((item) => item.batismo === 0)})    
                desc="Relatório de Membros não Batizados"        
            }
            else if (tipo == 'Membresia') {                
                this.setState({getList2: list.formularios.filter((item) => item.membresia === 0)})            
                desc="Relatório de Membros Sem membresia"
            }   
            else if (tipo == 'Vida') {                
                this.setState({getList2: list.formularios.filter((item) => item.vida === 0 || !item.vida )}) 
                desc="Relatório de Membros não fizeram o Vida Vitoriosa"           
            }  
            else if (tipo == 'Lider') {                
                this.setState({getList2: list.formularios.filter((item) => item.lider_celula === 0 || !item.lider_celula )})   
                desc="Relatório de Membros sem o curso de Líder de Célula"         
            }  
            else if (tipo == 'Superv') {                
                this.setState({getList2: list.formularios.filter((item) => item.disc_superv === 0 || !item.disc_superv )})   
                desc="Relatório de Membros sem o curso de Discipulado e Supervisão"         
            }  
            else if (tipo == 'Kids') {                
                this.setState({getList2: list.formularios.filter((item) => item.kids_incl === 0 || !item.kids_incl )})            
                desc="Relatório de Membros sem o curso de Kids e Inclusão"
            }  
            else if (tipo == 'Biblico') {                
                this.setState({getList2: list.formularios.filter((item) => item.intr_b_nt === 0 || !item.intr_b_nt )})     
                desc="Relatório de Membros sem o curso Introdução Bíblica"       
            }  
            else if (tipo == 'Antigo') {                
                this.setState({getList2: list.formularios.filter((item) => item.ant_test === 0 || !item.ant_test )})          
                desc="Relatório de Membros sem o curso Antigo Testamento"  
            }  
            else if (tipo == 'Batalha') {                
                this.setState({getList2: list.formularios.filter((item) => item.batalha_esp === 0 || !item.batalha_esp )})    
                desc="Relatório de Membros sem o curso de Batalha Espiritual"        
            }  
            else if (tipo == 'Tetelestai') {                
                this.setState({getList2: list.formularios.filter((item) => item.tetelestai === 0 || !item.tetelestai )})            
                desc="Relatório de Membros sem o curso Tetelestai"
            }  
            else {
                this.setState({getList2: list.formularios})               
            }

           console.log("membros")  
           console.log(list)
        }

        
    }

    
  
    render() {
        const { getList, getList2,isLoaded } = this.state;
        return (
            <div className="container-fluid">
                <CRow>          
          <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>{desc}</strong> 
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{fontFamily: "Arial"}}>
                <CTableHead color="dark" >
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Nome</CTableHeaderCell>                
                    <CTableHeaderCell >Telefone</CTableHeaderCell>
                    <CTableHeaderCell >Conversão</CTableHeaderCell>
                    <CTableHeaderCell >Líder</CTableHeaderCell>
                    <CTableHeaderCell >Célula</CTableHeaderCell>
                    <CTableHeaderCell >Área</CTableHeaderCell>
                    <CTableHeaderCell >Setor</CTableHeaderCell>
                    <CTableHeaderCell >Distrito</CTableHeaderCell>                    
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {getList2.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success" style={{color: "green"}}>                     
                      <CTableDataCell ><strong>{item.grupo_membro.nome}</strong></CTableDataCell>                                            
                      <CTableDataCell><strong>{item.grupo_membro.telefone}</strong></CTableDataCell> 
                      <CTableDataCell><strong>{item.grupo_membro.data_conversao ? Moment(item.grupo_membro.data_conversao).format('DD/MM/YYYY') : null}</strong></CTableDataCell> 
                      <CTableDataCell><strong>{item.grupo.descricao}</strong></CTableDataCell>
                      <CTableDataCell><strong>{item.grupo.user_lider.firstName}</strong></CTableDataCell>
                      <CTableDataCell><strong>{item.grupo.user_lider.user_setor ? item.grupo.user_lider.user_setor.descricao : '..'}</strong></CTableDataCell>
                      <CTableDataCell><strong>{item.grupo.user_lider.user_area ? item.grupo.user_lider.user_area.descricao : '..'}</strong></CTableDataCell>
                      <CTableDataCell><strong>{item.grupo.user_lider.user_setor ? item.grupo.user_lider.user_setor.descricao : '..'}</strong></CTableDataCell>                      
                    </CTableRow>
                  ))}                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>
              
              
              
              </CCardBody>
            </CCard> 
            
            </CCol >
            
            </CRow>
            </div>

        )
    }
}
