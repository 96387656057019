import React, { Component } from 'react'
import { GetAgendaDetails,GetGrupoDetails,GetCargoDetails } from '../../../../services';
import swal from 'sweetalert'; 
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import Menu from './menu';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

import { getCookie } from '../../../../../function';
import AutoSelect from "../../../../common/autoselect";

import PropTypes from "prop-types";
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CModalHeader,
    CFormLabel,
    CFormSelect
  } from '@coreui/react'
  import CIcon from '@coreui/icons-react'
  import { cilFilter,cilFilterX,cilSearch } from '@coreui/icons'


  let id_lider = getCookie("id_lider");


const Arrays = (data, fieldName, fieldValue) => {
    let arrayItem = [];
    if (data && Array.isArray(data)) {
        data.map((item, key) => {
            arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
            return null;
        });
    }
    return arrayItem;
  };

 let id_grupo=0  
 let id_cargo = 0
 let id_user = 0
 let c=0


export default class View extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
      };


  constructor(props) {
    super(props);
    this.state = {
        hideSetor:true,hideArea:false,hideDistrito:false,getList3: [],getList4: [],getList5: [],selectedGrupo: '',visible:false,
        getList1: [],getList2: [],selectedGrupo: '',hide1: true,hide2: false,hide3: false,selectLocation:'',visible:false,id_cargo:0,id_user:0
                 
    }
}

handleSelectChange = (name, selected) => {  
           
    if (name === "id_cargo")         {
       
        id_cargo= selected.value
    }  
    else if    (name === "id_user")         
    { 
        id_user= selected.value
    }   
    else if    (name === "id_grupo")         
    { 
       id_grupo = selected.value;
    }   

        this.setState({ changed: true });        
   
}

getGrupo = async () => {
        
    let list = await GetGrupoDetails.getAllGrupoList();      
    list = list.grupos.filter((item) => 
    item.id_lider == id_lider || 
    item.id_colider == id_lider || 
    item.id_supervisor == id_lider ||
    item.id_setor == id_lider ||
    item.id_area == id_lider ||
    item.id_distrito ==  id_lider ||
    item.id_rede == id_lider ) 
    if (list) {
        this.setState({ getList: list, isLoaded: false })
    } 
}

getAllLider = async () => {   
  
    let list = await GetGrupoDetails.getAllLider();       
    this.setState({ getList2: list.user })       
    
    var list2 = list.user.map((elemento,indice) =>{
        let item={}
        item.firstName = elemento.firstName + ' - ' + elemento.cargo.descricao
        item.id = elemento.id
        return item
    })
    this.setState({getList2:list2})   

}


async getSetor() {                    
    let list = await GetAgendaDetails.getVisita_Setor();      
    if (list) {
        this.setState({ getList3: list.visita_setor })                         
    }         
   }

async getArea() {                    
    let list = await GetAgendaDetails.getVisita_Area();      
    if (list) {
        this.setState({ getList4: list.visita_area })                         
    }         
}


async getDistrito() {                    
    let list = await GetAgendaDetails.getVisita_Distrito();      
    if (list) {
        this.setState({ getList5: list.visita_distrito })                         
    }         
}

Refresh = async () => {           
    this.getSetor();
    this.getArea();
    this.getDistrito();
    this.getGrupo();
    this.getCargo();
    this.getAllLider();      
    this.setState({ visible: false })
}   
async componentDidMount() {           
    this.getSetor();
    this.getArea();
    this.getDistrito();
    this.getGrupo();
    this.getCargo();
    this.getAllLider();
    
}

handleSelectedGrupo = async (name, selected) => {        
    this.setState({
        list: {
            ...this.state.list,
            [name]: selected.value,
        },
        selectedGrupo: selected,
    });
    this.setState({ changed: true });

}

 handleClick = (e) => {       
    this.setState({ hideArea: false }) 
    this.setState({ hideSetor: true }) 
    this.setState({ hideDistrito: false })  
  };

  handleClick2 = (e) => {
  
    this.setState({ hideArea: true }) 
    this.setState({ hideSetor: false }) 
    this.setState({ hideDistrito: false }) 

  };

  handleClick3 = (e) => {
    this.setState({ hideArea: false }) 
    this.setState({ hideSetor: false }) 
    this.setState({ hideDistrito: true }) 
  };

  

  handleChange = (arg) => {   
    
    if (arg==0) 
    {
        this.handleClick()
    }
    else if (arg==1) {
        this.handleClick2()
    }
    else if (arg==2) {
        this.handleClick3()
    }
  };
  
 


    render()
  
    
    {
       
        const { getList,getList1,getList2,getList3,getList4,getList5,visible } = this.state;
      return(                   
                       
    <div className="container-fluid" style={{width:"100"}}>
        
       
        <div className="row" >
           <CRow xs={20}>
                <CCol xs={12}>
                    <CCard className="mb-4">
                        <CCardHeader color="sucess">
                            <strong>Agenda Supervisão</strong> 
                        </CCardHeader>
                    <CCardBody>
                    <Menu handleChange={this.handleChange}/>     
                    
                    </CCardBody>
                    </CCard> 
            </CCol>  
            </CRow>                      
        </div>         
        
        <div className="row">
          
        <CRow xs={20}> 
            <>
           <CModal alignment="center" visible={visible} onClose={() => this.setState({ visible: false })}>
            <CModalHeader>
                <CModalTitle>Filtrar Agenda</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CFormLabel>Célula</CFormLabel>  
                <AutoSelect 
                      className="basic-single"
                      //value={selectLocation}
                      onChange={this.handleSelectChange}
                      isSearchable={true}        
                      name="id_grupo"
                      label="Célula"
                      options={Arrays(getList, "descricao", "id")} 
                    />

                <CFormLabel>Cargo</CFormLabel>  
                <AutoSelect 
                      className="basic-single"
                      //value={selectLocation}
                      onChange={this.handleSelectChange}
                      isSearchable={true}        
                      name="id_cargo"
                      label="Cargo"
                      options={Arrays(getList1, "descricao", "id")} 
                    />     

                <CFormLabel>Liderança</CFormLabel>  
                <AutoSelect 
                      className="basic-single"
                      //value={selectLocation}
                      onChange={this.handleSelectChange}
                      isSearchable={true}        
                      name="id_user"
                      label="Liderança"
                      options={Arrays(getList2, "firstName", "id")} 
                    />                                           
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() =>  this.setState({ visible: false })}>
                  Cancelar
                </CButton>
                <CButton color="success" onClick={() =>  this.Refresh()}>Aplicar Filtro</CButton>
            </CModalFooter>
            </CModal>

        </>
        </CRow> 
    </div>
    
    <div className="row justify-content-center" style={this.state.hideSetor ? { display: 'block' } : { display: 'none' }}>
    <CRow xs={20}> 
    <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Visita Supervisor de Setor</strong> 
                  <strong>
                <CFormLabel onClick={() => this.setState({ visible: !visible })} style={{ flexDirection: 'row', marginLeft: '5%'}} > Aplicar Filtros</CFormLabel>  
                </strong>
                <CIcon icon={cilFilter} onClick={() => this.setState({ visible: !visible })} style={{ width:'20'}}/>
                <strong>
                <CFormLabel onClick={() => this.handleLimpar()} style={{ flexDirection: 'row', marginLeft: '5%'}} > Limpar</CFormLabel>  
                </strong>
                <CIcon icon={cilFilterX} onClick={() => this.handleLimpar()} style={{ width:'20'}}/>
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border" hover responsive small>
                <CTableHead color="dark">
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Célula</CTableHeaderCell>                
                    <CTableHeaderCell >Supervisor</CTableHeaderCell>                
                    <CTableHeaderCell >Cargo</CTableHeaderCell>                        
                    <CTableHeaderCell >Jan</CTableHeaderCell>
                    <CTableHeaderCell >Fev</CTableHeaderCell>
                    <CTableHeaderCell >Mar</CTableHeaderCell>
                    <CTableHeaderCell >Abr</CTableHeaderCell>
                    <CTableHeaderCell >Mai</CTableHeaderCell>
                    <CTableHeaderCell >Jun</CTableHeaderCell>
                    <CTableHeaderCell >Jul</CTableHeaderCell>
                    <CTableHeaderCell >Ago</CTableHeaderCell>
                    <CTableHeaderCell >Set</CTableHeaderCell>
                    <CTableHeaderCell >Out</CTableHeaderCell>
                    <CTableHeaderCell >Nov</CTableHeaderCell>
                    <CTableHeaderCell >Dez</CTableHeaderCell>
                    <CTableHeaderCell >Ver</CTableHeaderCell>
                                                                           
                  </CTableRow>
                </CTableHead>
                <CTableBody>                            
                {getList3.map((item, index) => (                                                                                                        
                        <CTableRow v-for="item in tableItems" key={index} color="success">    
                          <CTableDataCell>{item.desc_celula}</CTableDataCell> 
                          <CTableDataCell>{item.desc_nome}</CTableDataCell> 
                          <CTableDataCell>{item.desc_cargo}</CTableDataCell>                           
                          <CTableDataCell><strong>{item.Jan > 0 ? <span className="text-success">{item.Jan}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Fev > 0 ? <span className="text-success">{item.Fev}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Mar > 0 ? <span className="text-success">{item.Mar}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Abr > 0 ? <span className="text-success">{item.Abr}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Mai > 0 ? <span className="text-success">{item.Mai}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Jun > 0 ? <span className="text-success">{item.Jun}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Jul > 0 ? <span className="text-success">{item.Jul}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Ago > 0 ? <span className="text-success">{item.Ago}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Sete > 0 ? <span className="text-success">{item.Sete}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Outu > 0 ? <span className="text-success">{item.Outu}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Nov > 0 ? <span className="text-success">{item.Nov}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><strong>{item.Dez > 0 ? <span className="text-success">{item.Dez}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell><CIcon  icon={cilSearch} title='Ver'/></CTableDataCell>

                        </CTableRow>
                 ))} 
                </CTableBody>         
              </CTable>
               <CRow>
              </CRow>
              </CCardBody>
            </CCard>             
       </CCol >            
      </CRow>                   
                 
    </div>   
              
    <div className="row justify-content-center" style={this.state.hideArea ? { display: 'block' } : { display: 'none' }}>
    <CRow xs={20}> 
    <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Visita Supervisor de Área</strong> 
                  <strong>
                <CFormLabel onClick={() => this.setState({ visible: !visible })} style={{ flexDirection: 'row', marginLeft: '5%'}} > Aplicar Filtros</CFormLabel>  
                </strong>
                <CIcon icon={cilFilter} onClick={() => this.setState({ visible: !visible })} style={{ width:'20'}}/>
                <strong>
                <CFormLabel onClick={() => this.handleLimpar()} style={{ flexDirection: 'row', marginLeft: '5%'}} > Limpar</CFormLabel>  
                </strong>
                <CIcon icon={cilFilterX} onClick={() => this.handleLimpar()} style={{ width:'20'}}/>
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border" hover responsive small>
                <CTableHead color="dark">
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Célula</CTableHeaderCell>                
                    <CTableHeaderCell >Supervisor</CTableHeaderCell>                
                    <CTableHeaderCell >Cargo</CTableHeaderCell>                        
                    <CTableHeaderCell className="text-center">Jan | Fev</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Mar | Abr</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Mai | Jun</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Jul | Ago</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Set | Out</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Nov | Dez</CTableHeaderCell>           
                    <CTableHeaderCell className="text-center">Ver</CTableHeaderCell>  
                  </CTableRow>
                </CTableHead>
                <CTableBody>                            
                {getList4.map((item, index) => (                                                                                                        
                        <CTableRow v-for="item in tableItems" key={index} color="success">    
                          <CTableDataCell>{item.desc_celula}</CTableDataCell> 
                          <CTableDataCell>{item.desc_nome}</CTableDataCell> 
                          <CTableDataCell>{item.desc_cargo}</CTableDataCell>                           
                          <CTableDataCell className="text-center"><strong>{item.JanFev > 0 ? <span className="text-success">{item.JanFev}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.MarAbr > 0 ? <span className="text-success">{item.MarAbr}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.MaiJun > 0 ? <span className="text-success">{item.MaiJun}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.JulAgo > 0 ? <span className="text-success">{item.JulAgo}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.SetOut > 0 ? <span className="text-success">{item.SetOut}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.NovDez > 0 ? <span className="text-success">{item.NovDez}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><CIcon  icon={cilSearch} title='Ver'/></CTableDataCell>
                        </CTableRow>
                 ))} 
                </CTableBody>         
              </CTable>
               <CRow>
              </CRow>
              </CCardBody>
            </CCard>             
       </CCol >            
      </CRow>                   
                 
    </div>  
    
    <div className="row justify-content-center" style={this.state.hideDistrito ? { display: 'block' } : { display: 'none' }}>
    <CRow xs={20}> 
    <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader >           
                  <strong>Visita Supervisor de Distrito</strong> 
                  <strong>
                <CFormLabel onClick={() => this.setState({ visible: !visible })} style={{ flexDirection: 'row', marginLeft: '5%'}} > Aplicar Filtros</CFormLabel>  
                </strong>
                <CIcon icon={cilFilter} onClick={() => this.setState({ visible: !visible })} style={{ width:'20'}}/>
                <strong>
                <CFormLabel onClick={() => this.handleLimpar()} style={{ flexDirection: 'row', marginLeft: '5%'}} > Limpar</CFormLabel>  
                </strong>
                <CIcon icon={cilFilterX} onClick={() => this.handleLimpar()} style={{ width:'20'}}/>
            </CCardHeader>
          <CCardBody>
               <CTable align="middle" className="mb-0 border" hover responsive small>
                <CTableHead color="dark">
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Célula</CTableHeaderCell>                
                    <CTableHeaderCell >Supervisor</CTableHeaderCell>                
                    <CTableHeaderCell >Cargo</CTableHeaderCell>                        
                    <CTableHeaderCell className="text-center">Jan | Fev | Mar</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Abr | Mai | Jun</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Jul | Ago | Set</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Out | Nov | Dez</CTableHeaderCell>                   
                    <CTableHeaderCell className="text-center">Ver</CTableHeaderCell>   
                  </CTableRow>
                </CTableHead>
                <CTableBody>                            
                {getList5.map((item, index) => (                                                                                                        
                        <CTableRow v-for="item in tableItems" key={index} color="success">    


                          <CTableDataCell>{item.desc_celula}</CTableDataCell> 
                          <CTableDataCell>{item.desc_nome}</CTableDataCell> 
                          <CTableDataCell>{item.desc_cargo}</CTableDataCell>                           
                          <CTableDataCell className="text-center"><strong>{item.JanFevMar > 0 ? <span className="text-success">{item.JanFevMar}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.AbrMaiJun > 0 ? <span className="text-success">{item.AbrMaiJun}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.JulAgoSet > 0 ? <span className="text-success">{item.JulAgoSet}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell> 
                          <CTableDataCell className="text-center"><strong>{item.OutNovDez > 0 ? <span className="text-success">{item.OutNovDez}</span> : <span className="text-danger">0</span>}</strong></CTableDataCell>    
                          <CTableDataCell className="text-center"><CIcon  icon={cilSearch} title='Ver'/></CTableDataCell>
                        </CTableRow>
                 ))} 
                </CTableBody>         
              </CTable>
               <CRow>
              </CRow>
              </CCardBody>
            </CCard>             
       </CCol >            
      </CRow>                   
                 
    </div>  

    </div>        
        
        
      )
    }
  }

  