import React, { Component } from "react";
import Demo from "./demo";
import { GetGrupoDetails,GetCargoDetails} from '../../../../services';
import AutoSelect from "../../../../common/autoselect";
import {  
    CCard,
    CCardBody,
    CCardHeader,
    CCol,    
    CRow,    
    CSpinner,
    CModal,
    CButton,    
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CFormLabel,
    CInputGroupText
  } from '@coreui/react'

  import CIcon from '@coreui/icons-react'
  import { cilFilter } from '@coreui/icons'

import { getCookie } from '../../../../../function';


const Arrays = (data, fieldName, fieldValue) => {
    let arrayItem = [];
    if (data && Array.isArray(data)) {
        data.map((item, key) => {
            arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
            return null;
        });
    }
    return arrayItem;
  };

let id_lider = getCookie("id_lider");



export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getList: [],getList1: [],getList2: [],selectedGrupo: '',hide1: true,hide2: false,hide3: false,selectLocation:'',visible:false,id_cargo:0,id_user:0,id_grupo:0
        }
    }
    

    handleSelectChange = (name, selected) => {       

        if (name === "id_cargo")         {
           
            this.setState({list: {...this.state.list,[name]: selected.value,}, id_cargo: selected,});
        }  
        else if    (name === "id_user")         
        { 
            this.setState({list: {...this.state.list,[name]: selected.value,}, id_user: selected,});
        }   
        else if    (name === "id_grupo")         
        { 
            this.setState({list: {...this.state.list,[name]: selected.value,}, id_grupo: selected,});
        }   

            this.setState({ changed: true });
        

    };
   
    async getCargo() {
        let list = await GetCargoDetails.getCargoList();
        this.setState({ getList1: list.data })
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    getGrupo = async () => {
        
        let list = await GetGrupoDetails.getAllGrupoList();      
        list = list.grupos.filter((item) => 
        item.id_lider == id_lider || 
        item.id_colider == id_lider || 
        item.id_supervisor == id_lider ||
        item.id_setor == id_lider ||
        item.id_area == id_lider ||
        item.id_distrito ==  id_lider ||
        item.id_rede == id_lider ) 
        if (list) {
            this.setState({ getList: list, isLoaded: false })
        } 
    }

    getAllLider = async () => {   
      
        let list = await GetGrupoDetails.getAllLider();       
        this.setState({ getList2: list.user })       
        
        var list2 = list.user.map((elemento,indice) =>{
            let item={}
            item.firstName = elemento.firstName + ' - ' + elemento.cargo.descricao
            item.id = elemento.id
            return item
        })

        
        this.setState({getList2:list2})   

    }
    
    async componentDidMount() {       
        this.getGrupo();
        this.getCargo();
        this.getAllLider();
    }

   
render() {
    const { getList,getList1,getList2,selectLocation,visible } = this.state;
    return (       
        <CRow> 
        <>
           <CModal alignment="center" visible={visible} onClose={() => this.setState({ visible: false })}>
            <CModalHeader>
                <CModalTitle>Filtrar Agenda</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CFormLabel>Célula</CFormLabel>  
                <AutoSelect 
                      className="basic-single"
                      //value={selectLocation}
                      onChange={this.handleSelectChange}
                      isSearchable={true}        
                      name="id_grupo"
                      label="Célula"
                      options={Arrays(getList, "descricao", "id")} 
                    />

                <CFormLabel>Cargo</CFormLabel>  
                <AutoSelect 
                      className="basic-single"
                      //value={selectLocation}
                      onChange={this.handleSelectChange}
                      isSearchable={true}        
                      name="id_cargo"
                      label="Cargo"
                      options={Arrays(getList1, "descricao", "id")} 
                    />     

                <CFormLabel>Liderança</CFormLabel>  
                <AutoSelect 
                      className="basic-single"
                      //value={selectLocation}
                      onChange={this.handleSelectChange}
                      isSearchable={true}        
                      name="id_user"
                      label="Liderança"
                      options={Arrays(getList2, "firstName", "id")} 
                    />                                           
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() =>  this.setState({ visible: false })}>
                  Cancelar
                </CButton>
                <CButton color="success">Aplicar Filtro</CButton>
            </CModalFooter>
            </CModal>

        </>

        
        <CCol xs={12} className="">
        <CCard className="mb-4">
        <CCardHeader color="sucess">
          <strong>Agenda de Supervisores</strong>         
          <strong>
          <CFormLabel onClick={() => this.setState({ visible: !visible })} style={{ flexDirection: 'row', marginLeft: '5%'}} > Aplicar Filtros</CFormLabel>  
          </strong>
          <CIcon icon={cilFilter} onClick={() => this.setState({ visible: !visible })} style={{ width:'20'}}/>
          
          
               
        </CCardHeader>
        <CCardBody>
            
        <Demo />

        </CCardBody>
        </CCard> 
        </CCol>     
        </CRow>
    )
}

}
