import React, { Component } from 'react'


import { GetUserLogin } from '../../../../services';
import swal from 'sweetalert';
import Moment from 'moment';

import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { NotificationManager } from 'react-notifications';

import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CSpinner
  } from '@coreui/react'


  import CIcon from '@coreui/icons-react'
  import {
    cilPencil,cilTrash,
  } from '@coreui/icons'

export default class List extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
  };

    constructor(props) {
        super(props);
        this.state = {
            getList: [],isLoaded:false,
            filters: {
              name: "",
              leaderOfCell: "",
              cargo: "",
              distrito: "",
              area: "",
              setor: "",
            },
        }
    }
   
   async componentDidMount() {
      this.getUser();
    }    

    handleFilterChange = (e, header) => {
      const { filters } = this.state;
      filters[header] = e.target.value;
      this.setState({ filters });
    };
    

    async getUser() {
        this.setState({ isLoaded: true })
        let list = await GetUserLogin.getAllUserList();        
        if (list) {        
          const kidsList = list.data.filter(user => user.role === "Kids");

          this.setState({ getList: kidsList });
          this.setState({ isLoaded: false });
        }
        else{
          this.props.history.push({ pathname: `/auth/login`})           
        }
    }


    async handlDeleteById(id,cargo) {
        swal({
            title: "Excluir a Liderança " + cargo + " ?",     
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    this.setState({ isLoaded: true })
                    let value = await GetUserLogin.getDeleteUserList(id);
                    if (value) {
                      NotificationManager.success("Liderança excluída com Sucesso...", 'Exclusão'); 
                      this.getUser();
                    }
                    this.setState({ isLoaded: false })
                }
            });
    }

    
    handlEditRow(row,descricao,id_cargo,distrito,id_distrito,area,id_area,setor,id_setor) {      
      this.props.history.push({ pathname: `/admin/user/edit/${row}`,state: row,descricao: descricao,id_cargo: id_cargo,
                                                                   distrito: distrito,id_distrito: id_distrito,
                                                                   area: area,id_area: id_area,setor: setor,id_setor: id_setor })
    }


    render() {        
        let self = this.state.getList
        let isLoaded = this.state.isLoaded

        const { filters } = this.state;
        const headerStyles = { display: "flex", justifyContent: "space-between" };
        const filterStyles = { display: "flex", alignItems: "left",marginLeft:"100px" };
        const filteredData = self.filter(item => {
          return (
            item.firstName.toLowerCase().includes(filters.name.toLowerCase()) &&
            item.lider_celula.toLowerCase().includes(filters.leaderOfCell.toLowerCase()) &&
            (item.cargo ? item.cargo.descricao : '..').toLowerCase().includes(filters.cargo.toLowerCase()) &&
            (item.user_distrito ? item.user_distrito.descricao : '..').toLowerCase().includes(filters.distrito.toLowerCase()) &&
            (item.user_area ? item.user_area.descricao : '..').toLowerCase().includes(filters.area.toLowerCase()) &&
            (item.user_setor ? item.user_setor.descricao : '..').toLowerCase().includes(filters.setor.toLowerCase())
          );
        });


        return (
            
          <CRow>          
          <CCol xs={12}>
          <CCard className="mb-4" >
            <CCardHeader style={headerStyles} >           
                  <strong>Lideranças Kids</strong> 
            
            <div style={filterStyles}>
              <input
                type="text"
                className="form-control"
                placeholder="Filtrar por Nome..."
                value={filters.name}
                onChange={(e) => this.handleFilterChange(e, "name")}
              />
              {/* Add similar input fields for other headers */}
            </div>
            
            </CCardHeader>
          <CCardBody>
         
               <CTable align="middle" className="mb-0 border card-body-table table" hover responsive small bordered style={{fontFamily: "Arial"}}>
                <CTableHead color="dark" >
                  <CTableRow color="dark">                   
                    <CTableHeaderCell >Nome</CTableHeaderCell>               
                 
                    <CTableHeaderCell >E-mail</CTableHeaderCell> 
                    <CTableHeaderCell >Telefone</CTableHeaderCell>                   
                    <CTableHeaderCell className="text-center">Alterar</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Deletar</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {filteredData.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index} color="success" style={{color: "green"}}>                     
                      <CTableDataCell >{item.firstName}</CTableDataCell>                      
                      <CTableDataCell>{item.email}</CTableDataCell> 
                      <CTableDataCell>{item.phone}</CTableDataCell>                       
                      <CTableDataCell className="text-center" onClick={(e) => this.handlEditRow(item,
                                                                                                item.cargo?.descricao || null,
                                                                                                item.cargo?.id || null,
                                                                                                item.user_distrito?.descricao || null,
                                                                                                item.user_distrito?.id || null,
                                                                                                item.user_area?.descricao || null,
                                                                                                item.user_area?.id || null,
                                                                                                item.user_setor?.descricao || null,
                                                                                                item.user_setor?.id || null
                                                                                                )}>                        
                      <CIcon  icon={cilPencil} title='Alterar'/>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">                        
                      <CIcon icon={cilTrash} title='Deletar' onClick={(e) => this.handlDeleteById(item.id,item.firstName)}/> 
                      </CTableDataCell>
                    </CTableRow>
                  ))}

                      
                  
                </CTableBody>               
              </CTable>
               <CRow>
              </CRow>
              
              <CButton style={{ flexDirection: 'row', marginTop: 10 }} color="success" width={80} onClick={(e) => this.props.history.push({ pathname: `/admin/user/create`})}> Nova Liderança</CButton>                          
              
              </CCardBody>
            </CCard> 
            {
                isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
            }
            
            </CCol >
            
            </CRow>

        )
    }
}
