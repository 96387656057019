import * as React from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';


import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import PropTypes from "prop-types";

export default function SimpleBottomNavigation({ handleChange }) {
  const [value, setValue] = React.useState(0);
  
  const handleSentValue = (val) => {    
    handleChange(val);    
  };

  return (
   
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);   
          handleSentValue(newValue);  
       
        }}
      >
        <BottomNavigationAction label="Supervisor Setor" icon={<LooksOneIcon />} />
        <BottomNavigationAction label="Supervisor Área" icon={<LooksTwoIcon />} />
        <BottomNavigationAction label="Supervisor Distrito" icon={<Looks3Icon />} />        
      </BottomNavigation>

      
   
  );
}

SimpleBottomNavigation.propTypes = {
  handleChange: PropTypes.any,    
};
