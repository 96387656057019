import React, { Component } from 'react';
import { Switch, Route ,useRouteMatch,Redirect } from 'react-router-dom'
import Home from './home';
import Cargo from './view/cargo';
import Ministerios from './view/ministerios';
import Tp_grupo from './view/tp_grupo';
import Tp_ferramenta from './view/tp_ferramenta';
import Perfil from './view/perfil';
import User from './view/user';
import Agenda from './view/agenda';
import Grupos from './view/grupos';
import Grupo_membros from './view/grupo_membros';
import Pastoreio from './view/pastoreio';
import Areas from './view/areas';
import { getCookie } from '../../function';

import UnauthorizedPage from './view/naoautorizado';

import Rel_Formulario from './view/rel_formulario';
import Batismo from './view/batismo';

import Vida from './view/vida';
import List from '../admin/view/vida/list';
import Cartas from '../admin/view/vida/cartas';
import Create from '../admin/view/vida/create';
import Relatorio from '../admin/view/vida/relatorio';

import { AppSidebar, AppFooter, AppHeader,AppContent,AppBreadcrumb } from '../index'

let role = getCookie("role");

export default class rootRoutes extends Component {
  render() {
    return (
      <Users />
    );
  }
}


function Users()
{

  let match = useRouteMatch();
  return (
  <main>
   <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
            <div className="body flex-grow-1 px-3">
              <Switch>          
                    <Route exact path={[`${match.path}/home`, `${match.path}`]} component={Home}/>
                    <Route path={`${match.path}/naoautorizado`}  component={UnauthorizedPage} />                      
                    <Route path={`${match.path}/agenda`} component={Agenda} />
                    <Route path={`${match.path}/user`} component={User} />                    
                    <Route path={`${match.path}/grupo_membros`} component={Grupo_membros} />
                    <Route path={`${match.path}/pastoreio`} component={Pastoreio} />
                    <Route path={`${match.path}/rel_formulario`} component={Rel_Formulario} />
                    <Route path={`${match.path}/batismo`} component={Batismo} />
                    <Route path={`${match.path}/vida`} component={Vida} /> 
                    <Route exact path={`${match.path}/list`} component={List} />
                    <Route path={`${match.path}/cartas/:id`} component={Cartas} />
                    <Route path={`${match.path}/relatorio`} component={Relatorio} />
                    <Route path={`${match.path}/create`} component={Create} />
                    {role === 'admin' ? <Route path={`${match.path}/cargo`} component={Cargo} /> : <Redirect to={`${match.path}/naoautorizado`} />}   
                    <Route path={`${match.path}/grupos`} component={Grupos} />
                    <Route path={`${match.path}/ministerios`} component={Ministerios} />
                    <Route path={`${match.path}/tp_grupo`} component={Tp_grupo} />
                    <Route path={`${match.path}/tp_ferramenta`} component={Tp_ferramenta} />
                    <Route path={`${match.path}/perfil`} component={Perfil} />                                
                    <Route path={`${match.path}/areas`} component={Areas} />                  
                </Switch>
              </div>
          <AppFooter />
        </div>
      </div>  
  </main>
  )
} 


