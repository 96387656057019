import React, { Component } from 'react'
import { GetFormularioDetails} from '../../../../services';
import swal from 'sweetalert'; 
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import Menu from './menu';
import Menu1 from './menu1';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

import PropTypes from "prop-types";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCardFooter,
    CCardText,
    CCol,
    CTable,
    CTableBody,
    CTableRow,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CRow,
    CAlert,
    CSpinner,
    CFormSelect,
    CFormLabel,
  } from '@coreui/react'
import { AlignVerticalCenterOutlined } from '@mui/icons-material';

let nome = ''
let id_celula=0
let id_membro=0
let id_grupo=0
//let startDate2=Moment(new Date()).format('MM/YYYY')
let startDate2=new Date()


export default class View extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
      };


  constructor(props) {
    super(props);
    this.state = {
        hideintegraco: false,hidevisao: false,hideestudos: false,hideavivalismo: false,hidenivel: false,
        hide1: true,hide2:false,activintegracao:'none',activvisao:'none',activestudos:'none',activavivalismo:'none',activnivel:'none',
        activecelula:'active',hidecelula:true,activecelebracao:'none',hidecelebracao:false,
        activetsd:'none',hidetsd:false,activevision:'none',hidevision:false,
        membresia: 0,data_membresia:null,batismo:0,data_batismo:null,lider_celula:0,data_lider_celula:null,
        disc_superv:0,data_disc_superv:null,kids_incl:0,data_kids_incl:null,intr_b_nt:0,data_intr_b_nt:null,
        ant_test:0,data_ant_test:null,batalha_esp:0,data_batalha_esp:null,tetelestai:0, data_tetelestai:null,
        vida:0,data_vida:null,tsd:0,discipulado:0,data_discipulado:null,getList: [],getList2: [],particiou:[0,1],getList3: [],getList4: [] ,getList5: [],getList6: [],
        startDate:new Date()
                 
    }
}





async getFormularioID() {          
    let list = await GetFormularioDetails.getFormularioById(id_membro,id_celula); 
    console.log('formulário')
    console.log(list.data)         
    if (list) {
        this.setState({ getList: list.data, isLoaded: false })    

        this.setState({membresia:list.data[0].membresia})        
        let dt = list.data[0].data_membresia    
        this.setState({data_membresia:Moment(dt).format('MM/DD/YYYY')})
        this.setState({batismo:list.data[0].batismo})
        dt = list.data[0].data_batismo 
        this.setState({data_batismo:Moment(dt).format('MM/DD/YYYY')})
        this.setState({lider_celula:list.data[0].lider_celula})
        dt = list.data[0].data_lider_celula 
        this.setState({data_lider_celula:Moment(dt).format('MM/DD/YYYY')})

        this.setState({disc_superv:list.data[0].disc_superv}) 
        dt = list.data[0].data_disc_superv 
        this.setState({data_disc_superv:Moment(dt).format('MM/DD/YYYY')})

        this.setState({kids_incl:list.data[0].kids_incl}) 
        dt = list.data[0].data_kids_incl 
        this.setState({data_kids_incl:Moment(dt).format('MM/DD/YYYY')})

        this.setState({intr_b_nt:list.data[0].intr_b_nt}) 
        dt = list.data[0].data_intr_b_nt 
        this.setState({data_intr_b_nt:Moment(dt).format('MM/DD/YYYY')})

        this.setState({ant_test:list.data[0].ant_test}) 
        dt = list.data[0].data_ant_test 
        this.setState({data_ant_test:Moment(dt).format('MM/DD/YYYY')})

        this.setState({batalha_esp:list.data[0].batalha_esp}) 
        dt = list.data[0].data_batalha_esp 
        this.setState({data_batalha_esp:Moment(dt).format('MM/DD/YYYY')})


        this.setState({tetelestai:list.data[0].tetelestai}) 
        dt = list.data[0].data_tetelestai 
        this.setState({data_tetelestai:Moment(dt).format('MM/DD/YYYY')})

        this.setState({vida:list.data[0].vida}) 
        dt = list.data[0].data_vida 
        this.setState({data_vida:Moment(dt).format('MM/DD/YYYY')})

        this.setState({tsd:list.data[0].tsd}) 
        this.setState({discipulado:list.data[0].discipulado}) 
        dt = list.data[0].data_discipulado 
        this.setState({data_discipulado:Moment(dt).format('MM/DD/YYYY')})


    }
    
}


async getComprometimentosMes() {     
    
    var date = new Date();
    var ano = date.getFullYear()
    var mes = date.getMonth()
    
    let list = await GetFormularioDetails.getComprometimentosById(id_membro,id_celula);        
    if (list) {
        this.setState({getList2: list.comprometimentos, isLoaded: false })            
        this.setState({getList3: list.comprometimentos.filter((item) => item.evento =='Celebração' && Moment(item.data).format('MM/YYYY') == Moment(startDate2).format('MM/YYYY') ) })
        this.setState({getList4: list.comprometimentos.filter((item) => item.evento =='Célula' && Moment(item.data).format('MM/YYYY') == Moment(startDate2).format('MM/YYYY') )})
        this.setState({getList5: list.comprometimentos.filter((item) => item.evento =='Atitude Vision' && Moment(item.data).format('MM/YYYY') == Moment(startDate2).format('MM/YYYY'))})
    
    }
}

async getComprometimentosID() {         
   
    let list = await GetFormularioDetails.getComprometimentosById(id_membro,id_celula); 
    console.log(list)         
    if (list) {
        this.setState({getList2: list.comprometimentos, isLoaded: false })            
        this.setState({getList3: list.comprometimentos.filter((item) => item.evento =='Celebração')})
        this.setState({getList4: list.comprometimentos.filter((item) => item.evento =='Célula' && Moment(item.data).format('MM/YYYY') == Moment(startDate2).format('MM/YYYY') )})
        this.setState({getList5: list.comprometimentos.filter((item) => item.evento =='Atitude Vision')})
        this.setState({getList6: list.comprometimentos.filter((item) => item.evento =='TSD')})        
       }
}
 


componentDidMount() {
    nome=this.props.location.nome
    this.setState({nome: this.props.location.nome})
    id_celula = this.props.location.id_celula
    id_grupo = this.props.location.id_celula
    id_membro = this.props.location.id_membro
    this.getFormularioID();
    this.getComprometimentosID();     
}   


handleChange1(e) {
    this.setState({ [e.target.name]: e.target.value })  
}

handleChange2(e,row) {    
    this.handleUpdate(row.evento,row.data,e.target.value)
}

handleSetStartdate(date){

    this.setState({startDate:date})
    startDate2 =date
    this.getComprometimentosMes()
}

handleUpdate = async (evento,data,participou) => {
    let dados = {id_grupo:id_grupo,id_membro:id_membro,data:new Date(data),evento:evento,participou:participou}
 
    let user = await GetFormularioDetails.getUpdateComprometimentos(dados);    
    if (user) {
        this.setState({ isLoaded: false })
        // this.props.history.goBack();
        NotificationManager.success("Create successfull", 'Message');
    } else {
        NotificationManager.error("Check Input field!", 'Input');
    }
}    

 handleClick = (e) => {   
    this.setState({activintegracao: 'active'})  
    this.setState({ hideintegraco: true })
    this.setState({chek: true })
    this.setState({activvisao: 'none'}) 
    this.setState({activestudos: 'none'})
    this.setState({activavivalismo: 'none'}) 
    this.setState({activnivel: 'none'})

    

    this.setState({ hidevisao: false })
    this.setState({ hideestudos: false })
    this.setState({ hideavivalismo: false })
    this.setState({ hidenivel: false })


    this.setState({activecelula: 'none'})
    this.setState({activecelebracao: 'none'})
    this.setState({activetsd: 'none'})
    this.setState({activevision: 'none'})

    this.setState({ hidecelula: false })
    this.setState({ hidecelebracao: false })
    this.setState({ hidetsd: false })
    this.setState({ hidevision: false })
   
  };

  handleClick2 = (e) => {
    this.setState({activvisao: 'active'})  
    this.setState({ hidevisao: true })

    this.setState({activintegracao: 'none'}) 
    this.setState({activestudos: 'none'})
    this.setState({activavivalismo: 'none'}) 
    this.setState({activnivel: 'none'})

    this.setState({ hideintegraco: false })
    this.setState({ hideestudos: false })
    this.setState({ hideavivalismo: false })
    this.setState({ hidenivel: false })


    this.setState({activecelula: 'none'})
    this.setState({activecelebracao: 'none'})
    this.setState({activetsd: 'none'})
    this.setState({activevision: 'none'})


    this.setState({ hidecelula: false })
    this.setState({ hidecelebracao: false })
    this.setState({ hidetsd: false })
    this.setState({ hidevision: false })



  };

  handleClick3 = (e) => {
    this.setState({activestudos: 'active'})  
    this.setState({ hideestudos: true })

    this.setState({activintegracao: 'none'}) 
    this.setState({activvisao: 'none'})
    this.setState({activavivalismo: 'none'}) 
    this.setState({activnivel: 'none'})

    this.setState({ hideintegraco: false })
    this.setState({ hidevisao: false })
    this.setState({ hideavivalismo: false })
    this.setState({ hidenivel: false })

    this.setState({activecelula: 'none'})
    this.setState({activecelebracao: 'none'})
    this.setState({activetsd: 'none'})
    this.setState({activevision: 'none'})


    this.setState({ hidecelula: false })
    this.setState({ hidecelebracao: false })
    this.setState({ hidetsd: false })
    this.setState({ hidevision: false })


  };

  handleClick4 = (e) => {
    this.setState({activavivalismo: 'active'})  
    this.setState({ hideavivalismo: true })

    this.setState({activintegracao: 'none'}) 
    this.setState({activvisao: 'none'})
    this.setState({activestudos: 'none'}) 
    this.setState({activnivel: 'none'})

    this.setState({ hideintegraco: false })
    this.setState({ hidevisao: false })
    this.setState({ hideestudos: false })
    this.setState({ hidenivel: false })

    this.setState({activecelula: 'none'})
    this.setState({activecelebracao: 'none'})
    this.setState({activetsd: 'none'})
    this.setState({activevision: 'none'})


    this.setState({ hidecelula: false })
    this.setState({ hidecelebracao: false })
    this.setState({ hidetsd: false })
    this.setState({ hidevision: false })

  };

  handleClick5 = (e) => {
    this.setState({activnivel: 'active'})  
    this.setState({ hidenivel: true })

    this.setState({activintegracao: 'none'}) 
    this.setState({activvisao: 'none'})
    this.setState({activestudos: 'none'}) 
    this.setState({activavivalismo: 'none'})

    this.setState({ hideintegraco: false })
    this.setState({ hidevisao: false })
    this.setState({ hideestudos: false })
    this.setState({ hideavivalismo: false })

    this.setState({activecelula: 'active'})
    this.setState({activecelebracao: 'none'})
    this.setState({activetsd: 'none'})
    this.setState({activevision: 'none'})


    this.setState({ hidecelula: true })
    this.setState({ hidecelebracao: false })
    this.setState({ hidetsd: false })
    this.setState({ hidevision: false })


  };


  handleClick6 = (e) => {
    this.setState({activnivel: 'active'})  
    this.setState({ hidenivel: true })

    this.setState({activintegracao: 'none'}) 
    this.setState({activvisao: 'none'})
    this.setState({activestudos: 'none'}) 
    this.setState({activavivalismo: 'none'})

    this.setState({ hideintegraco: false })
    this.setState({ hidevisao: false })
    this.setState({ hideestudos: false })
    this.setState({ hideavivalismo: false })

    this.setState({activecelula: 'none'})
    this.setState({activecelebracao: 'active'})
    this.setState({activetsd: 'none'})
    this.setState({activevision: 'none'})


    this.setState({ hidecelula: false })
    this.setState({ hidecelebracao: true })
    this.setState({ hidetsd: false })
    this.setState({ hidevision: false })


  };

  handleClick7 = (e) => {
    this.setState({activnivel: 'active'})  
    this.setState({ hidenivel: true })

    this.setState({activintegracao: 'none'}) 
    this.setState({activvisao: 'none'})
    this.setState({activestudos: 'none'}) 
    this.setState({activavivalismo: 'none'})

    this.setState({ hideintegraco: false })
    this.setState({ hidevisao: false })
    this.setState({ hideestudos: false })
    this.setState({ hideavivalismo: false })

    this.setState({activecelula: 'none'})
    this.setState({activecelebracao: 'none'})
    this.setState({activetsd: 'active'})
    this.setState({activevision: 'activevision'})


    this.setState({ hidecelula: false })
    this.setState({ hidecelebracao: false })
    this.setState({ hidetsd: true })
    this.setState({ hidevision: false })


  };

  handleClick8 = (e) => {
    this.setState({activnivel: 'active'})  
    this.setState({ hidenivel: true })

    this.setState({activintegracao: 'none'}) 
    this.setState({activvisao: 'none'})
    this.setState({activestudos: 'none'}) 
    this.setState({activavivalismo: 'none'})

    this.setState({ hideintegraco: false })
    this.setState({ hidevisao: false })
    this.setState({ hideestudos: false })
    this.setState({ hideavivalismo: false })

    this.setState({activecelula: 'none'})
    this.setState({activecelebracao: 'none'})
    this.setState({activetsd: 'none'})
    this.setState({activevision: 'activevision'})


    this.setState({ hidecelula: false })
    this.setState({ hidecelebracao: false })
    this.setState({ hidetsd: false })
    this.setState({ hidevision: true })


  };

  handleChange = (arg) => {   
    if (arg==0) 
    {
        this.handleClick()
    }
    else if (arg==1) {
        this.handleClick2()

    }
    else if (arg==2) {
        this.handleClick3()

    }
    else if (arg==3) {
        this.handleClick4()

    }
    else if (arg==10) {
        this.handleClick5()

    }
    else if (arg==11) {
        this.handleClick6()

    }
    else if (arg==12) {
        this.handleClick7()

    }
    else if (arg==13) {
        this.handleClick8()

    }
    else if (arg==14) {
        this.setState({ hide2: true })
        this.setState({ hide1: false })
        this.handleClick()
    }
    else if (arg==4) {
        this.setState({ hide2: false })
        this.setState({ hide1: true })       
        this.handleClick5()
    }

  };
  
  handleSubmit = async () => {


    this.setState({ isLoaded: true })
    swal({
        title: "Atualizar Formulário",
        text: "Você deseja atualizar o Formulário?",
        icon: "warning",
        buttons: true,
        dangerMode: false,
    })
    .then(async (success) => {
      if (success) {     
        let {  membresia,data_membresia,batismo,data_batismo,lider_celula,data_lider_celula,
            disc_superv,data_disc_superv,kids_incl,data_kids_incl,intr_b_nt,data_intr_b_nt,
            ant_test,data_ant_test,batalha_esp,data_batalha_esp,tetelestai,data_tetelestai,vida,data_vida,tsd,discipulado,data_discipulado} = this.state;
        
        let data = {id_celula:id_celula,id_membro:id_membro, membresia:membresia,data_membresia: data_membresia,batismo:batismo,
            data_batismo: data_batismo,lider_celula:lider_celula,data_lider_celula: data_lider_celula,
            disc_superv: disc_superv,data_disc_superv: data_disc_superv ,kids_incl: kids_incl ,
            data_kids_incl:data_kids_incl,intr_b_nt: intr_b_nt,data_intr_b_nt: data_intr_b_nt,
            ant_test: ant_test,data_ant_test: data_ant_test,batallha_esp: batalha_esp,
            data_batalha_esp: data_batalha_esp,tetelestai: tetelestai,data_tetelestai: data_tetelestai,
            vida:vida,data_vida:data_vida,tsd:tsd,discipulado:discipulado,data_discipulado:data_discipulado}
        // make API call 
        let user = await GetFormularioDetails.AddFormularioList(data);    
        if (user) {
            this.setState({ isLoaded: false })
            //this.props.history.goBack();
            NotificationManager.success("Create successfull", 'Message');
        } else {
            NotificationManager.error("Check Input field!", 'Input');
        }
    }
  });

  
}


    render()
  
    
    {
       
        const{getList3,getList4,getList5, activintegracao,activvisao,activestudos,activavivalismo,activnivel,
            membresia,data_membresia,batismo,data_batismo,lider_celula,data_lider_celula,activecelula,
            disc_superv,data_disc_superv,kids_incl,data_kids_incl,intr_b_nt,data_intr_b_nt,activecelebracao,activetsd,activevision,
            ant_test,data_ant_test,batalha_esp,data_batalha_esp,tetelestai,data_tetelestai,vida,data_vida,tsd,discipulado,data_discipulado } = this.state;
      return(                   
                       
    <div className="container-fluid" style={{width:"100"}}>
        
       
       
          <div className="row" style={this.state.hide1 ? { display: 'block' } : { display: 'none' }}>
            <CRow>
                <CCol xs={12}>
                    <CCard className="mb-4">
                        <CCardHeader color="sucess">
                            <strong>Nível Comprometimento - {nome}</strong> 
                        </CCardHeader>
                    <CCardBody>
                    <Menu1 handleChange={this.handleChange}/>     
                    
                    </CCardBody>
                    </CCard> 
            </CCol>  
            </CRow>    
        </div>           

        <div className="row" style={this.state.hide2 ? { display: 'block' } : { display: 'none' }}>
            <CRow>
                <CCol xs={12}>
                    <CCard className="mb-4">
                        <CCardHeader color="sucess">
                            <strong>Cursos Visão - {nome}</strong> 
                        </CCardHeader>
                    <CCardBody>
                    <Menu handleChange={this.handleChange}/>     
                    
                    </CCardBody>
                    </CCard> 
            </CCol>  
            </CRow>    
        </div>         
        <div className="row justify-content-center" style={this.state.hidecelula ? { display: 'block' } : { display: 'none' }}>
            <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>Célula Mês/Ano: </CFormLabel>                             
                            <div  style={{ width: '200px',marginRight:'20px' }} >
                              <DatePicker
                                                selected={startDate2}
                                                className="form-control text-center"
                                                onChange={(date)=>this.handleSetStartdate(date)}
                                                dateFormat="MM/yyyy"
                                                placeholderText="Mês/Ano"
                                                showMonthYearPicker
                                                showFullMonthYearPicker
                                                showTwoColumnMonthYearPicker                                            
                                               
                                                />
                                                
                           </div>                     
                        </CCardHeader>
                  <CCardBody >
                  
                    <CTable align="center" className="mb-0 border" hover responsive small bordered>
                        <CTableHead color="success">
                        <CTableRow color="success" >                             
                            <CTableHeaderCell className="w-10 r-5">Participou</CTableHeaderCell>              
                               
                            <CTableHeaderCell className="w-10">Data</CTableHeaderCell>                                                                     
                            <CTableHeaderCell className="w-10">Evento</CTableHeaderCell>                             
                            
                         
                        </CTableRow>
                        </CTableHead>
                        <CTableBody>
                        {getList4.map((row, index) => (
                            <CTableRow v-for="item in tableItems" key={index} color="success" >  
                            
                            <CTableDataCell className="w-10" style={{ height: '10px',align:"center" }}>
                            {row.participou === 0 ? 
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" defaultValue={row.participou} name="participou" onChange={(e) => this.handleChange2(e,row)}>      
                                <option value={0}>Não</option>
                                <option value={1}>Sim</option>                                
                             </CFormSelect>
                            :                            
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" defaultValue={row.participou} name="participou" onChange={(e) => this.handleChange2(e,row)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>
                            }
                            </CTableDataCell>   
                            <CTableDataCell className="w-10" >
                              {Moment(row.data).format('DD/MM/YYYY')}
                            </CTableDataCell>                            
                            
                            <CTableDataCell className="w-10 r-10" >
                              {row.evento}
                            </CTableDataCell>
                            
                            </CTableRow>
                        ))}                            
                        
                        </CTableBody>               
                     </CTable> 

                 </CCardBody>
               </CCard> 
                           
             </CCol>  
            </div>        
            </CRow>                      
                 
        </div>    


          <div className="row" style={this.state.hidecelebracao ? { display: 'block' } : { display: 'none' }}>
          <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>Celebração Mês/Ano: </CFormLabel>                             
                            <div  style={{ width: '200px',marginRight:'20px' }} >
                              <DatePicker
                                                selected={startDate2}
                                                className="form-control text-center"
                                                onChange={(date)=>this.handleSetStartdate(date)}
                                                dateFormat="MM/yyyy"
                                                placeholderText="Mês/Ano"
                                                showMonthYearPicker
                                                showFullMonthYearPicker
                                                showTwoColumnMonthYearPicker                                            
                                               
                                                />
                                                
                           </div>                     
                        </CCardHeader>
                  <CCardBody >
                  
                    <CTable align="center" className="mb-0 border" hover responsive small bordered>
                        <CTableHead color="success">
                        <CTableRow color="success" >                             
                            <CTableHeaderCell className="w-10 r-5">Participou</CTableHeaderCell>              
                               
                            <CTableHeaderCell className="w-10">Data</CTableHeaderCell>                                                                     
                            <CTableHeaderCell className="w-10">Evento</CTableHeaderCell>                             
                            
                         
                        </CTableRow>
                        </CTableHead>
                        <CTableBody>
                        {getList3.map((row, index) => (
                            <CTableRow v-for="item in tableItems" key={index} color="success" >  
                            
                            <CTableDataCell className="w-10" style={{ height: '10px',align:"center" }}>
                            {row.participou === 0 ? 
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" defaultValue={row.participou} name="participou" onChange={(e) => this.handleChange2(e,row)}>      
                                <option value={0}>Não</option>
                                <option value={1}>Sim</option>                                
                             </CFormSelect>
                            :                            
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" defaultValue={row.participou} name="participou" onChange={(e) => this.handleChange2(e,row)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>
                            }
                            </CTableDataCell>   
                            <CTableDataCell className="w-10" >
                              {Moment(row.data).format('DD/MM/YYYY')}
                            </CTableDataCell>                            
                            
                            <CTableDataCell className="w-10 r-10" >
                              {row.evento}
                            </CTableDataCell>
                            
                            </CTableRow>
                        ))}                            
                        
                        </CTableBody>               
                     </CTable> 

                 </CCardBody>
               </CCard> 
                           
             </CCol>  
            </div>        
            </CRow>  
                 
        </div>    

        <div className="row " style={this.state.hidetsd ? { display: 'block' } : { display: 'none' }}>

          <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>TSD e Discipulado    -    {nome} </CFormLabel>                                                               
                        </CCardHeader>
                  <CCardBody >
                  
                            <CFormLabel>Tempo a sós com Deus</CFormLabel>
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={tsd} name="tsd" onChange={(e) => this.handleChange1(e)}>      
                                <option value={0}>Não</option>
                                <option value={1}>Sim</option>                                
                             </CFormSelect>

                            <CFormLabel>Discipulado</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={discipulado} name="discipulado" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label">Data último Discipulado</label>
                                              <input type="date" className="form-control" placeholder="Data Discipulado" name="data_discipulado" value={Moment(data_discipulado).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                  
                 </CCardBody>
                 <CCardFooter>
                 <div className="col-lg-8 col-md-3">
                 <CButton color="success" type="submit" onClick={this.handleSubmit}>
                    Atualizar TSD e Discipulado
                 </CButton>
                 </div>       
                 </CCardFooter>        
               </CCard> 
               
                           
             </CCol>  

             
            

            </div>      

            
            </CRow> 

        </div>



          <div className="row justify-content-center" style={this.state.hidevision ? { display: 'block' } : { display: 'none' }}>
            <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>Atitude Vision Mês/Ano: </CFormLabel>                             
                            <div  style={{ width: '200px',marginRight:'20px' }} >
                              <DatePicker
                                                selected={startDate2}
                                                className="form-control text-center"
                                                onChange={(date)=>this.handleSetStartdate(date)}
                                                dateFormat="MM/yyyy"
                                                placeholderText="Mês/Ano"
                                                showMonthYearPicker
                                                showFullMonthYearPicker
                                                showTwoColumnMonthYearPicker                                            
                                               
                                                />
                                                
                           </div>                     
                        </CCardHeader>
                  <CCardBody >
                  
                    <CTable align="center" className="mb-0 border" hover responsive small bordered>
                        <CTableHead color="success">
                        <CTableRow color="success" >                             
                            <CTableHeaderCell className="w-10 r-5">Participou</CTableHeaderCell>              
                               
                            <CTableHeaderCell className="w-10">Data</CTableHeaderCell>                                                                     
                            <CTableHeaderCell className="w-10">Evento</CTableHeaderCell>                             
                            
                         
                        </CTableRow>
                        </CTableHead>
                        <CTableBody>
                        {getList5.map((row, index) => (
                            <CTableRow v-for="item in tableItems" key={index} color="success" >  
                            
                            <CTableDataCell className="w-10" style={{ height: '10px',align:"center" }}>
                            {row.participou === 0 ? 
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" defaultValue={row.participou} name="participou" onChange={(e) => this.handleChange2(e,row)}>      
                                <option value={0}>Não</option>
                                <option value={1}>Sim</option>                                
                             </CFormSelect>
                            :                            
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" defaultValue={row.participou} name="participou" onChange={(e) => this.handleChange2(e,row)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>
                            }
                            </CTableDataCell>   
                            <CTableDataCell className="w-10" >
                              {Moment(row.data).format('DD/MM/YYYY')}
                            </CTableDataCell>                            
                            
                            <CTableDataCell className="w-10 r-10" >
                              {row.evento}
                            </CTableDataCell>
                            
                            </CTableRow>
                        ))}                            
                        
                        </CTableBody>               
                     </CTable> 

                 </CCardBody>
               </CCard> 
                           
             </CCol>  
            </div>        
            </CRow>                      
                 
        </div> 



             <div className="row " style={this.state.hideintegraco? { display: 'block' } : { display: 'none' }}>

          <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>Integração   -    {nome} </CFormLabel>                                                               
                        </CCardHeader>
                  <CCardBody >                  
                           

                            <CFormLabel>Membresia</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={membresia} name="membresia" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label">Data Membresia</label>
                                              <input type="date" className="form-control" placeholder="Data Discipulado" name="data_membresia" value={Moment(data_membresia).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                            
                            <CFormLabel>Batismo</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={batismo} name="batismo" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label">Data Batismo</label>
                                              <input type="date" className="form-control" placeholder="Data Discipulado" name="data_batismo" value={Moment(data_batismo).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                  
                             <CFormLabel>Vida Vitoriosa</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={vida} name="vida" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label">Data Vida Vitoriosa</label>
                                              <input type="date" className="form-control" placeholder="Data Vida Vitoriosa" name="data_vida" value={Moment(data_vida).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                 </CCardBody>
                 <CCardFooter>
                 <div className="col-lg-8 col-md-3">
                 <CButton color="success" type="submit" onClick={this.handleSubmit}>
                    Atualizar Integração
                 </CButton>
                 </div>       
                 </CCardFooter>        
               </CCard>    
             </CCol>  
            </div>                
            </CRow> 
        </div>

        
        <div className="row " style={this.state.hidevisao ? { display: 'block' } : { display: 'none' }}>

          <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>Visão   -    {nome} </CFormLabel>                                                               
                        </CCardHeader>
                  <CCardBody >                  
                           

                            <CFormLabel>Líder Célula</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={lider_celula} name="lider_celula" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label">Data Líder de Célula</label>
                                              <input type="date" className="form-control" placeholder="Data Líder Célula" name="data_lider_celula" value={Moment(data_lider_celula).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                            
                            <CFormLabel>Discipulado e Supervisão</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={disc_superv} name="disc_superv" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-8 col-md-3">
                             <label className="form-label">Data Discipulado e Supervisão</label>
                             </div>
                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                             
                                              <input type="date" className="form-control" placeholder="Data Discipulado" name="data_disc_superv" value={Moment(data_disc_superv).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                  
                             <CFormLabel>Líder Kids e Inclusão</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={kids_incl} name="kids_incl" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label">Data Kids e Inclusão</label>
                                              <input type="date" className="form-control" placeholder="Data Vida Vitoriosa" name="data_kids_incl" value={Moment(data_kids_incl).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                 </CCardBody>
                 <CCardFooter>
                 <div className="col-lg-8 col-md-3">
                 <CButton color="success" type="submit" onClick={this.handleSubmit}>
                    Atualizar Visão
                 </CButton>
                 </div>       
                 </CCardFooter>        
               </CCard>    
             </CCol>  
            </div>                
            </CRow> 
        </div>
                    
        <div className="row " style={this.state.hideestudos ? { display: 'block' } : { display: 'none' }}>

          <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>Estudos Bíblicos   -    {nome} </CFormLabel>                                                               
                        </CCardHeader>
                  <CCardBody >                  
                           

                            <CFormLabel>Intr. Bíblica e Novo Testamento</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={intr_b_nt} name="intr_b_nt" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-8 col-md-3">
                             <label className="form-label">Data Introdução Bíblica e Novo Testamento</label>
                             </div>
                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                             
                                              <input type="date" className="form-control" name="data_intr_b_nt" value={Moment(data_intr_b_nt).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                             
                            <CFormLabel>Antigo Testamento</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={ant_test} name="ant_test" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-8 col-md-3">
                             <label className="form-label">Data Antigo Testamento</label>
                             </div>
                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                             
                                              <input type="date" className="form-control" name="data_ant_test" value={Moment(data_ant_test).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                  
                            
                 </CCardBody>
                 <CCardFooter>
                 <div className="col-lg-8 col-md-3">
                 <CButton color="success" type="submit" onClick={this.handleSubmit}>
                 Atualizar Estudos Bíblicos
                 </CButton>
                 </div>       
                 </CCardFooter>        
               </CCard>    
             </CCol>  
            </div>                
            </CRow> 
        </div>     


         <div className="row " style={this.state.hideavivalismo ? { display: 'block' } : { display: 'none' }}>

          <CRow>
            <div className="row justify-content-center">
                <CCol sm={3}>                               
                <CCard className="mb-1">
                        <CCardHeader color="sucess">
                            
                            <CFormLabel>Avivalismo   -    {nome} </CFormLabel>                                                               
                        </CCardHeader>
                  <CCardBody >                  
                           

                            <CFormLabel>Batalha Espiritual</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={batalha_esp} name="batalha_esp" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-8 col-md-3">
                             <label className="form-label">Data Batalha Espiritual</label>
                             </div>
                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                             
                                              <input type="date" className="form-control" name="data_batalha_esp" value={Moment(data_batalha_esp).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                             
                            <CFormLabel>Tetelestai</CFormLabel>             
                            <CFormSelect size="sm" className="mb-2" aria-label="Small select example" value={tetelestai} name="tetelestai" onChange={(e) => this.handleChange1(e)}>      
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>                                
                             </CFormSelect>

                             <div className="col-lg-8 col-md-3">
                             <label className="form-label">Data Tetelestai</label>
                             </div>
                             <div className="col-lg-6 col-md-3">
                                          <div className="form-group">
                                             
                                              <input type="date" className="form-control" name="data_tetelestai" value={Moment(data_tetelestai).format('YYYY-MM-DD')} onChange={(e) => this.handleChange1(e)} />
                                          </div>
                             </div> 
                  
                            
                 </CCardBody>
                 <CCardFooter>
                 <div className="col-lg-8 col-md-3">
                 <CButton color="success" type="submit" onClick={this.handleSubmit}>
                 Atualizar Avivalismo
                 </CButton>
                 </div>       
                 </CCardFooter>        
               </CCard>    
             </CCol>  
            </div>                
            </CRow> 
        </div>       

                          
              
              
               

    </div>


        
        
        
      )
    }
  }

  