import React, { Component, Suspense } from 'react'
import { Switch,HashRouter, Route, Routes,BrowserRouter,Redirect } from 'react-router-dom'
import './scss/style.scss'

import rootRoutes from './components/admin/rootRoutes';
import Auth from './components/auth';
import NoMatch from './components/nomatch';

import { getCookie } from './function';

import { NotificationContainer } from "react-notifications";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// // Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// // Pages
// const Login = React.lazy(() => import('./components/auth/login/Login'))
// const Register = React.lazy(() => import('./components/auth/login/register/Register'))
// const Page404 = React.lazy(() => import('./components/auth/login/page404/Page404'))
const forgot = React.lazy(() => import('./components/auth/forgot-password'))
const reset = React.lazy(() => import('./components/auth/reset-password'))
const register = React.lazy(() => import('./components/auth/register'))
const vida = React.lazy(() => import('./components/auth/vida'))
const cadastrobatismo = React.lazy(() => import('./components/auth/cadastrobatismo'))
const batismo = React.lazy(() => import('./components/auth/batismo'))
const einscricao = React.lazy(() => import('./components/auth/e-inscricao'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <NotificationContainer />
        <Switch>
        <Route path='/auth' component={Auth} />
        <Route path='/forgot-password' component={forgot} />
        <Route path='/reset-password' component={reset} />
        <Route path='/register' component={register} />
        <Route path='/vidavitoriosa' component={vida} />
        <Route path='/cadastrobatismo' component={cadastrobatismo} />
        <Route path='/e-inscricao' component={einscricao} />
        <Route path='/batismo' component={batismo} />        
          {getCookie('token') ? <Route path='/admin' component={rootRoutes} /> : <Redirect to={"/auth/login"} />}
          {getCookie('token') ? <Redirect to={"/admin"} /> : <Redirect to={"/auth/login"} />}
          <Route component={NoMatch} />          
        </Switch>        
      </BrowserRouter>
    )
  }
}

export default App
  
