import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getCargo = async (descricao) => {
    try {
        // data.date = moment().format();
        // let data = {descricao: descricao,sup:sup,periodo:periodo}
        let data = {descricao: descricao}
        let result = await api.post(Apis.GetAllCargoCreate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getCargoList = async () => {
    try {
        let result = await api.get(Apis.GetAllCargoList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCargoListID = async () => {
    try {
        let result = await api.get(Apis.GetCargoListId);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getCargoDeleteById = async (id) => {
    try {
        let result = await api.delete(Apis.GetCargoDeleteById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCargoUpdate = async (data) => {
    try {
        let result = await api.post(Apis.GetCargoUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    getCargo,
    getCargoList,
    getCargoDeleteById,
    getCargoUpdate,   
    getCargoListID, 
};