import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getfaixaetaria = async (descricao) => {
    try {
        // data.date = moment().format();
        let data = {descricao: descricao}
        let result = await api.post(Apis.GetAllfaixaetariaCreate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getfaixaetariaList = async () => {
    try {
        let result = await api.get(Apis.GetAllfaixaetariaList);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getfaixaetariaListID = async () => {
    try {
        let result = await api.get(Apis.GetfaixaetariaListId);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getfaixaetariaDeleteById = async (id) => {
    try {
        let result = await api.delete(Apis.GetfaixaetariaDeleteById,{params: {id}});
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getfaixaetariaUpdate = async (data) => {
    try {
        let result = await api.post(Apis.GetfaixaetariaUpdate,data);
        if (result.data.error) {
            NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export default {
    getfaixaetaria,
    getfaixaetariaList,
    getfaixaetariaDeleteById,
    getfaixaetariaUpdate,   
    getfaixaetariaListID, 
};