import React, { useState, Component  } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CSpinner
} from '@coreui/react'
import swal from 'sweetalert';

import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { GetTp_FerramentaDetails } from '../../../../services';

export default class Create extends Component {

  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    state: PropTypes.object,
  };

  constructor(props) {
      super(props);
      this.state = {            
              descricao:'',isLoaded:false
          
      }
  }

  handleChange(e) {
      this.setState({ [e.target.name]: e.target.value })
  }
  
  handleSubmit = async event => {
    event.preventDefault();
      const { descricao} = this.state;
   
      swal({
          title: "Deseja Adicionar um novo Tipo Ferramenta ?",         
          icon: "info",
          buttons: true,
          dangerMode: false,
      })
          .then(async (success) => {
              if (success) {     
                  this.setState({ isLoaded: true })
                  let list = await GetTp_FerramentaDetails.getTp_Ferramenta(descricao);
                  if (list) {                   
                    this.props.history.push({ pathname: `/admin/tp_ferramenta/list`}) 
                  }
                  this.setState({ isLoaded: false })
              }
          });
  }



  
  render() {

      const { descricao } = this.state;  
      let isLoaded = this.state.isLoaded
      return (


  <CRow>
  <CCol xs={12}>
  <CCard className="mb-4">
    <CCardHeader color="sucess">
          <strong>Cadastro de Tipo Ferramenta</strong> 
    </CCardHeader>
  <CCardBody>
         
  <CForm
    className="row g-3 needs-validation"
    Validate
    validated={this.validated}
    onSubmit={this.handleSubmit}
  >
    <CCol md={4}>
      <CFormInput
        type="text"        
        feedbackValid="Looks good!"
        id="validationCustom01"
        label="Descrição"
        required        
        value={descricao}   
        name="descricao"      
        onChange={(e) => this.handleChange(e)} 
      />
    

    </CCol>   
    <CCol xs={12}>
      <CButton color="success" type="submit">
        Confirmar
      </CButton>
    </CCol>
  </CForm>

  </CCardBody>
  </CCard> 
  </CCol>
  {
                            isLoaded ? <CSpinner color="success" style={{ flexDirection: 'row', marginLeft: '50%',alignItems:"center" }}  /> : ''
            }
  </CRow>
)
}

}
