import React, { Component } from 'react'
import {
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
} from '@coreui/react'
import { getStyle } from '@coreui/utils'
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilOptions } from '@coreui/icons'

import { GetOrderDetails, GetDashboardDetails } from '../../services';


import { getCookie } from '../../../function';

let id_lider = getCookie("id_lider");
let role = getCookie("role");
export default class Home extends Component {
constructor(props) {
    super(props);
    this.state = {
        getList: [],getList2: [], isloaded: false, status: null, statusList: null,groupList: null,membrosList:null,
        formulariosList:null,offset: 0,total_membros:0,total_celulas:0,
        total_batismo:0,total_membresia:0,total_lider_celula:0,total_disc_superv:0,total_kids_incl:0,
        total_intr_b_nt:0,total_ant_test:0,total_batalha_esp:0,total_tetelestai:0,
        total_vida:0,total_tsd:0,total_discipulado:0
       
    }
}

async getFormulariosList() {
  this.setState({ isloaded: true })
  let list = await GetDashboardDetails.getAllFormularios();
  console.log("Formularios")
  console.log(list.data)
  if (list) {         

      list = list.data.filter((item) => 
      item.grupo.id_lider ==id_lider || 
      item.grupo.id_colider ==id_lider || 
      item.grupo.id_supervisor == id_lider ||
      item.grupo.id_setor == id_lider ||
      item.grupo.id_area == id_lider ||
      item.grupo.id_distrito == id_lider ||  role === 'admin'
      )

      //this.setState({total_batismo:list.reduce((total, item) => total + item.batismo,0)}) ,
      this.setState({ total_batismo: list.reduce((total, item) => total + item.batismo, 0) });

      
      this.setState({ total_membresia: list.reduce((total, item) => total + item.batismo, 0) }) ;          
      this.setState({ total_lider_celula: list.reduce((total, item) => total + item.lider_celula, 0) });
      this.setState({ total_disc_superv: list.reduce((total, item) => total + item.disc_superv, 0) }) ;
      this.setState({ total_kids_incl: list.reduce((total, item) => total + item.kids_incl, 0) }) ;
      this.setState({ total_intr_b_nt: list.reduce((total, item) => total + item.intr_b_nt, 0) }) ;
      this.setState({ total_ant_test: list.reduce((total, item) => total + item.ant_test, 0) });
      this.setState({ total_batalha_esp: list.reduce((total, item) => total + item.batalha_esp, 0) });
      this.setState({ total_tetelestai: list.reduce((total, item) => total + item.tetelestai, 0) });
      this.setState({ total_vida: list.reduce((total, item) => total + item.vida, 0) });
      this.setState({ total_tsd: list.reduce((total, item) => total + item.tsd, 0) });
      this.setState({ total_discipulado: list.reduce((total, item) => total + item.discipulado, 0) });
      this.setState({ formulariosList: list.data, isloaded: false });
  } else {
      this.setState({ isloaded: true })
  }        
}
async getGrupoList() {
  this.setState({ isloaded: true })
  let list = await GetDashboardDetails.getAllGrupo();
  console.log("list") 
  console.log(list)
  list = list.data.filter((item) => 
  item.id_lider ==id_lider || 
  item.id_colider ==id_lider || 
  item.id_supervisor == id_lider ||
  item.id_setor == id_lider ||
  item.id_area == id_lider ||
  item.id_distrito == id_lider ||  role === 'admin' 
                           
  )
  console.log(list)
  if (list) {
      this.setState({ total_celulas: list.length, isloaded: false })
  } else {
      this.setState({ isloaded: true })
  }

}

async getMembrosList() {
  this.setState({ isloaded: true })
  let list = await GetDashboardDetails.getAllMembros();
  
  list = list.data.filter((item) => 
      item.grupo.id_lider ==id_lider || 
      item.grupo.id_colider ==id_lider || 
      item.grupo.id_supervisor == id_lider ||
      item.grupo.id_setor == id_lider ||
      item.grupo.id_area == id_lider ||
      item.grupo.id_distrito == id_lider ||
      item.grupo.id_rede == id_lider    || role === 'admin'                           
      )
         
  if (list) {
      this.setState({total_membros:list.length})
      //this.setState({ membrosList: list.data, isloaded: false })
  } else {
      this.setState({ isloaded: true })
  }        
}

componentDidMount() {
  this.getGrupoList();
  this.getFormulariosList()
  this.getMembrosList()

}

  render() {
    const { getList, isloaded, status, statusList,total_celulas,membrosList,total_membros,
      total_batismo,total_membresia,total_lider_celula,total_disc_superv,total_kids_incl,
      total_intr_b_nt,total_ant_test,total_batalha_esp,total_tetelestai,
      total_vida,total_tsd,total_discipulado } = this.state;
  return (
    <CRow >
      <CCol sm={6} lg={3} >
        <CWidgetStatsA
          className="mb-4"
          color="success"          
          value={
            <>
              {total_celulas}         
            </>
          }
          title="Células"        
          
        />
      </CCol>
      <CCol sm={6} lg={3} >
        <CWidgetStatsA
          className="mb-4"
          color="info"          
          value={
            <>
              {total_membros}         
            </>
          }
          title="Membros"        
          
        />
      </CCol>
      <CCol sm={6} lg={3} >
        <CWidgetStatsA
          className="mb-4"
          color="success"          
          value={
            <>
              {total_lider_celula}         
            </>
          }
          title="Líder de Célula"        
          
        />
      </CCol>      
      <CCol sm={6} lg={3} >
        <CWidgetStatsA
          className="mb-4"
          color="info"          
          value={
            <>
              {total_lider_celula}         
            </>
          }
          title="Auxiliares"        
          
        />
      </CCol>      
    </CRow>
  )
}
}

