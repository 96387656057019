import * as React from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PropTypes from "prop-types";

import Diversity2Icon from '@mui/icons-material/Diversity2';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupIcon from '@mui/icons-material/Group';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';

export default function SimpleBottomNavigation({ handleChange }) {
  const [value, setValue] = React.useState(0);
  
  const handleSentValue = (val) => {    
    handleChange(val);
  };


  return (
   
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);   
          handleSentValue(newValue + 10);  
       
        }}
      >
        <BottomNavigationAction label="Célula" icon={<Diversity2Icon />} />
        <BottomNavigationAction label="Celebração" icon={<GroupsIcon />} />
        <BottomNavigationAction label="TSD e Discipulado" icon={<GroupIcon />} />        
        <BottomNavigationAction label="Atitude Vision" icon={<ConnectWithoutContactIcon />} />        
        <BottomNavigationAction label="Cursos Visão" icon={<FollowTheSignsIcon />} />
      </BottomNavigation>

      
   
  );
}

SimpleBottomNavigation.propTypes = {
  handleChange: PropTypes.any,    
};